import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 20, height = 16, className, ...other }) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 20 15"
    xmlns="http://www.w3.org/2000/svg"
    fill="#137BC6"
    {...other}
  >
    <path
      d="M6.17428 12.1843L2.03223 8.0451C1.80921 7.82174 1.50644 7.69621 1.19069 7.69621C0.874942 7.69621 0.572165 7.82174 0.34915 8.0451C-0.116383 8.51031 -0.116383 9.26181 0.34915 9.72701L5.3387 14.7131C5.80424 15.1783 6.55625 15.1783 7.02179 14.7131L19.6509 2.09281C20.1164 1.6276 20.1164 0.876111 19.6509 0.410901C19.4278 0.187538 19.1251 0.0620117 18.8093 0.0620117C18.4936 0.0620117 18.1908 0.187538 17.9678 0.410901L6.17428 12.1843Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
