import { SvgIconType } from './svg.interface';

const CloseRoundedIcon: SvgIconType = ({
  width = 15,
  height = 15,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#F74141" />
    <path
      d="M7.95807 7.50004L9.90497 5.55304C10.0317 5.42639 10.0317 5.22163 9.90497 5.09498C9.77833 4.96834 9.57357 4.96834 9.44693 5.09498L7.49997 7.04198L5.55307 5.09498C5.42637 4.96834 5.22167 4.96834 5.09503 5.09498C4.96832 5.22163 4.96832 5.42639 5.09503 5.55304L7.04193 7.50004L5.09503 9.44704C4.96832 9.57368 4.96832 9.77845 5.09503 9.90509C5.15814 9.96826 5.24112 10 5.32405 10C5.40697 10 5.4899 9.96826 5.55307 9.90509L7.49997 7.95809L9.44693 9.90509C9.5101 9.96826 9.59303 10 9.67595 10C9.75888 10 9.8418 9.96826 9.90497 9.90509C10.0317 9.77845 10.0317 9.57368 9.90497 9.44704L7.95807 7.50004Z"
      fill="white"
    />
  </svg>
);

export default CloseRoundedIcon;
