import { SvgIconType } from './svg.interface';

const SortDescIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13 6L10 3L7 6L7.707 6.707L9.5 4.914L9.5 17H10.5L10.5 4.914L12.293 6.707L13 6Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default SortDescIcon;
