import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.38436 15C7.26264 14.9006 7.15043 14.7902 7.04918 14.67C6.16439 13.4081 5.2729 12.1504 4.41203 10.8722C3.68476 9.82606 3.09307 8.6919 2.65111 7.49687C2.37025 6.74606 2.22726 5.9507 2.22902 5.14908C2.25754 4.02894 2.64163 2.94691 3.32572 2.05948C4.00982 1.17204 4.95846 0.525209 6.03445 0.212527C6.35661 0.122876 6.69349 0.08619 7.02345 0.0244387C7.05624 0.0183019 7.0883 0.00825 7.12069 0H7.88241C8.16957 0.0492975 8.46028 0.0835425 8.74333 0.150294C9.77111 0.401265 10.6999 0.954879 11.4096 1.73951C12.1193 2.52415 12.5773 3.5037 12.7242 4.55143C12.8751 5.66278 12.6532 6.715 12.2603 7.74356C11.7732 8.95749 11.146 10.1104 10.3916 11.1789C9.56593 12.3928 8.71624 13.5903 7.87141 14.7909C7.79681 14.8715 7.71185 14.9418 7.61874 15H7.38436ZM7.50295 13.7662C7.52337 13.7423 7.54 13.7258 7.55319 13.7069C8.29352 12.6469 9.04661 11.5954 9.76988 10.5238C10.4793 9.51238 11.0612 8.41725 11.5023 7.26325C11.8283 6.45378 11.9511 5.57677 11.86 4.70888C11.7294 3.70777 11.2582 2.78212 10.5255 2.08749C9.79289 1.39286 8.84347 0.9716 7.83683 0.894498C6.83019 0.817396 5.82769 1.08915 4.99781 1.6641C4.16792 2.23905 3.56125 3.08214 3.27973 4.05169C3.01913 5.03003 3.06642 6.06491 3.41516 7.01541C3.76539 8.05195 4.25327 9.03675 4.86571 9.94338C5.70983 11.2155 6.59306 12.4617 7.45984 13.7188C7.47323 13.7355 7.48763 13.7513 7.50295 13.7662Z"
      fill="#137BC6"
    />
    <path
      d="M10.1382 5.28036C10.1368 5.80184 9.98082 6.31119 9.68996 6.74402C9.3991 7.17685 8.98643 7.5137 8.50412 7.71199C8.02181 7.91027 7.49153 7.96108 6.98035 7.85799C6.46916 7.7549 6.00003 7.50254 5.63227 7.13282C5.26452 6.7631 5.01466 6.29262 4.91429 5.7809C4.81392 5.26917 4.86755 4.73917 5.0684 4.25793C5.26925 3.77668 5.6083 3.36581 6.04267 3.07726C6.47704 2.78871 6.98722 2.63544 7.5087 2.63685C8.20755 2.63998 8.87667 2.91994 9.36952 3.41542C9.86237 3.9109 10.1388 4.58151 10.1382 5.28036ZM9.25926 5.27167C9.25892 4.9242 9.15559 4.58463 8.96234 4.29585C8.76909 4.00708 8.49458 3.78206 8.1735 3.64923C7.85241 3.5164 7.49917 3.48172 7.15838 3.54957C6.8176 3.61742 6.50457 3.78475 6.25884 4.03042C6.01312 4.27609 5.84572 4.58909 5.7778 4.92986C5.70988 5.27063 5.74449 5.62388 5.87725 5.94499C6.01001 6.2661 6.23497 6.54066 6.5237 6.73397C6.81244 6.92729 7.15199 7.03069 7.49946 7.03111C7.96582 7.03012 8.41281 6.84445 8.74261 6.51471C9.07241 6.18498 9.25818 5.73804 9.25926 5.27167Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
