import { createApi } from '@reduxjs/toolkit/query/react';
import { ISingleElement, PageDto } from '../../interfaces/common.interface';
import {
  IWish,
  IGetWishesRequestParams,
  IUploadSingleWishRequest,
  ISingleWishRequest,
  IUpdateSingleWishRequest,
  IDeleteSingleWishResponse,
  IUpdateSingleWishReactionRequest,
  IUpdateSingleWishReactionResponse,
  IUpdateSingleWishStatusRequest,
} from '../../interfaces/wish.interface';

import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import { onQueryStartedExtended } from '../../utils/query';

export const wishlistApi = createApi({
  reducerPath: 'wishlistApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Wishlist'],
  endpoints: (builder) => ({
    uploadSingleWish: builder.mutation<
      ISingleElement<IWish>,
      IUploadSingleWishRequest
    >({
      query: (data) => ({
        url: '/wish-list',
        method: 'POST',
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Successfully added new wish',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Error adding new wish',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (result) => [
        { type: 'Wishlist', id: result?.data.id },
        { type: 'Wishlist', id: 'PARTIAL-LIST' },
      ],
    }),

    getWishes: builder.query<PageDto<IWish>, IGetWishesRequestParams>({
      query: (params) => ({
        url: '/wish-list',
        params,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Wishlist' as const,
                id,
              })),
              { type: 'Wishlist', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Wishlist', id: 'PARTIAL-LIST' }],
    }),

    getSingleWish: builder.query<ISingleElement<IWish>, ISingleWishRequest>({
      query: ({ id }) => ({
        url: `/wish-list/${id}`,
        method: 'GET',
      }),

      providesTags: (result) =>
        result
          ? [
              { type: 'Wishlist', id: result.data.id },
              { type: 'Wishlist', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Wishlist', id: 'PARTIAL-LIST' }],
    }),

    updateSingleWish: builder.mutation<
      ISingleElement<IWish>,
      IUpdateSingleWishRequest
    >({
      query: (data) => ({
        url: `/wish-list/${data.id}`,
        method: 'PATCH',
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Successfully updated wish',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Error updating wish',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (result) => [
        { type: 'Wishlist', id: result?.data.id },
        { type: 'Wishlist', id: 'PARTIAL-LIST' },
      ],
    }),

    deleteSingleWish: builder.mutation<
      IDeleteSingleWishResponse,
      ISingleWishRequest
    >({
      query: ({ id }) => ({
        url: `/wish-list/${id}`,
        method: 'DELETE',
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            addNotification({
              title: data.message,
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Error deleting wish',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (_, __, { id }) => [
        { type: 'Wishlist', id },
        { type: 'Wishlist', id: 'PARTIAL-LIST' },
      ],
    }),

    updateSingleWishReaction: builder.mutation<
      ISingleElement<IUpdateSingleWishReactionResponse>,
      IUpdateSingleWishReactionRequest
    >({
      query: (data) => ({
        url: `/wish-list/${data.id}/reaction`,
        method: 'PATCH',
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Error updating wish reaction',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (_, __, { id }) => [
        { type: 'Wishlist', id },
        { type: 'Wishlist', id: 'PARTIAL-LIST' },
      ],
    }),

    updateSingleWishStatus: builder.mutation<
      ISingleElement<IWish>,
      IUpdateSingleWishStatusRequest
    >({
      query: (data) => ({
        url: `/wish-list/${data.id}/status`,
        method: 'PATCH',
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Wish updated successfully',
          errorMessage: 'Wish updating error',
        });
      },

      invalidatesTags: (_, __, { id }) => [
        { type: 'Wishlist', id },
        { type: 'Wishlist', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
});

export const {
  useUploadSingleWishMutation,
  useGetWishesQuery,
  useGetSingleWishQuery,
  useUpdateSingleWishMutation,
  useDeleteSingleWishMutation,
  useUpdateSingleWishReactionMutation,
  useUpdateSingleWishStatusMutation,
} = wishlistApi;
