import { SvgIconType } from './svg.interface';

const ImageAddIcon: SvgIconType = ({ width = 18, height = 18, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_104)">
      <path
        d="M15.75 11.25V13.5H18V15H15.75V17.25H14.25V15H12V13.5H14.25V11.25H15.75ZM15.756 2.25C16.167 2.25 16.5 2.58375 16.5 2.99475V9.75H15V3.75H3V14.2493L10.5 6.75L12.75 9V11.1218L10.5 8.87175L5.12025 14.25H10.5V15.75H2.244C2.04661 15.7498 1.85737 15.6712 1.71787 15.5316C1.57836 15.392 1.5 15.2026 1.5 15.0052V2.99475C1.50137 2.79778 1.58018 2.60926 1.71938 2.46991C1.85859 2.33056 2.04704 2.25157 2.244 2.25H15.756ZM6 5.25C6.39782 5.25 6.77936 5.40804 7.06066 5.68934C7.34196 5.97064 7.5 6.35218 7.5 6.75C7.5 7.14782 7.34196 7.52936 7.06066 7.81066C6.77936 8.09196 6.39782 8.25 6 8.25C5.60218 8.25 5.22064 8.09196 4.93934 7.81066C4.65804 7.52936 4.5 7.14782 4.5 6.75C4.5 6.35218 4.65804 5.97064 4.93934 5.68934C5.22064 5.40804 5.60218 5.25 6 5.25V5.25Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_104">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ImageAddIcon;
