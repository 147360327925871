import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 16, height = 16, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.33333 11.3337H6C7.10457 11.3337 8 12.2291 8 13.3337V6.66699C8 4.78137 8 3.83857 7.41421 3.25278C6.82843 2.66699 5.88562 2.66699 4 2.66699H3.33333C2.70479 2.66699 2.39052 2.66699 2.19526 2.86225C2 3.05752 2 3.37179 2 4.00033V10.0003C2 10.6289 2 10.9431 2.19526 11.1384C2.39052 11.3337 2.70479 11.3337 3.33333 11.3337Z"
      stroke="white"
      strokeWidth="0.666667"
    />
    <path
      d="M12.6667 11.3337H10C8.89543 11.3337 8 12.2291 8 13.3337V6.66699C8 4.78137 8 3.83857 8.58579 3.25278C9.17157 2.66699 10.1144 2.66699 12 2.66699H12.6667C13.2952 2.66699 13.6095 2.66699 13.8047 2.86225C14 3.05752 14 3.37179 14 4.00033V10.0003C14 10.6289 14 10.9431 13.8047 11.1384C13.6095 11.3337 13.2952 11.3337 12.6667 11.3337Z"
      stroke="white"
      strokeWidth="0.666667"
    />
  </svg>
);

export default Svg;
