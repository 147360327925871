import { FC, PropsWithChildren } from 'react';

import classes from './style.module.scss';

export const SimpleLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={classes.layout}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
