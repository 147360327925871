import { SvgIconType } from './svg.interface';

const FilterIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.6364 17L8.36364 14.375V11.3883L4 6.13833V4.75C4 4.28587 4.1724 3.84075 4.47928 3.51256C4.78616 3.18437 5.20237 3 5.63636 3H14.3636C14.7976 3 15.2138 3.18437 15.5207 3.51256C15.8276 3.84075 16 4.28587 16 4.75V6.13833L11.6364 11.3883V17ZM9.45455 13.7917L10.5455 14.6667V10.945L14.9091 5.695V4.75C14.9091 4.59529 14.8516 4.44692 14.7493 4.33752C14.647 4.22812 14.5083 4.16667 14.3636 4.16667H5.63636C5.4917 4.16667 5.35296 4.22812 5.25067 4.33752C5.14838 4.44692 5.09091 4.59529 5.09091 4.75V5.695L9.45455 10.945V13.7917Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default FilterIcon;
