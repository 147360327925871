import { SvgIconType } from './svg.interface';

const ExpandIcon: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M7.5 9.375L7.14645 9.72855L7.5 10.0821L7.85355 9.72855L7.5 9.375ZM3.39645 5.97855L7.14645 9.72855L7.85355 9.02145L4.10355 5.27145L3.39645 5.97855ZM7.85355 9.72855L11.6036 5.97855L10.8964 5.27145L7.14645 9.02145L7.85355 9.72855Z"
      fill="#137BC6"
    />
  </svg>
);

export default ExpandIcon;
