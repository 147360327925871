import { SvgIconType } from './svg.interface';

const FormatClearIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_101)">
      <path
        d="M9.48825 10.5488L8.70375 15H7.1805L8.193 9.25425L2.6355 3.69675L3.6975 2.63625L15.3637 14.3025L14.3032 15.363L9.48825 10.548V10.5488ZM8.82975 5.6475L9.03225 4.5H7.68225L6.18225 3H15V4.5H10.5547L10.125 6.94275L8.82975 5.6475V5.6475Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_101">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FormatClearIcon;
