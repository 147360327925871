import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 100, height = 100, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    className={className}
  >
    <g id="surface365404111">
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(54.901963%,61.960787%,100%)"
        d="M 50 100 C 22.386719 100 0 77.613281 0 50 C 0 22.386719 22.386719 0 50 0 C 77.613281 0 100 22.386719 100 50 C 100 77.613281 77.613281 100 50 100 Z M 50 100 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        d="M 74.167969 31.875 C 74.167969 31.875 67.242188 26.457031 59.0625 25.832031 L 58.324219 27.308594 C 65.71875 29.117188 69.113281 31.710938 72.65625 34.894531 C 66.546875 31.777344 60.515625 28.855469 50 28.855469 C 39.484375 28.855469 33.453125 31.777344 27.34375 34.894531 C 30.886719 31.710938 34.921875 28.832031 41.675781 27.308594 L 40.9375 25.832031 C 32.355469 26.644531 25.832031 31.875 25.832031 31.875 C 25.832031 31.875 18.097656 43.089844 16.769531 65.105469 C 24.566406 74.09375 36.40625 74.167969 36.40625 74.167969 L 38.882812 70.867188 C 34.679688 69.40625 29.933594 66.796875 25.832031 62.082031 C 30.722656 65.785156 38.105469 69.636719 50 69.636719 C 61.894531 69.636719 69.277344 65.785156 74.167969 62.082031 C 70.066406 66.796875 65.320312 69.40625 61.117188 70.867188 L 63.59375 74.167969 C 63.59375 74.167969 75.433594 74.09375 83.230469 65.105469 C 81.902344 43.089844 74.167969 31.875 74.167969 31.875 Z M 40.183594 59.0625 C 37.261719 59.0625 34.894531 56.355469 34.894531 53.019531 C 34.894531 49.683594 37.261719 46.980469 40.183594 46.980469 C 43.101562 46.980469 45.46875 49.683594 45.46875 53.019531 C 45.46875 56.355469 43.101562 59.0625 40.183594 59.0625 Z M 59.816406 59.0625 C 56.898438 59.0625 54.53125 56.355469 54.53125 53.019531 C 54.53125 49.683594 56.898438 46.980469 59.816406 46.980469 C 62.738281 46.980469 65.105469 49.683594 65.105469 53.019531 C 65.105469 56.355469 62.738281 59.0625 59.816406 59.0625 Z M 59.816406 59.0625 "
      />
    </g>
  </svg>
);

export default Svg;
