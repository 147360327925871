import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 5, height = 9, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.03894 8.97713C4.1473 9.09063 4.28522 9.15254 4.44776 9.15254C4.77285 9.15254 5.0339 8.88426 5.0339 8.54375C5.0339 8.37349 4.96494 8.21871 4.85165 8.10005L1.40378 4.57111L4.85165 1.05249C4.96494 0.933827 5.0339 0.77389 5.0339 0.608794C5.0339 0.268282 4.77285 0 4.44776 0C4.28522 0 4.1473 0.0619112 4.03894 0.175415L0.206873 4.09646C0.0689575 4.2306 0.00492554 4.3957 0 4.57627C0 4.75684 0.0689575 4.91162 0.206873 5.05092L4.03894 8.97713Z"
      fill="white"
    />
  </svg>
);

export default Svg;
