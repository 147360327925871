import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import {
  CreateGoogleTaskDto,
  GmailItem,
  GoogleTaskItem,
  SendEmailDto,
  ServiceItem,
} from '../../interfaces/services.interface';
import {
  CreateCalendarEventDto,
  IDeleteEventRequest,
  IGetAllEventsResponse,
} from '../../interfaces/calendar.interface';
import { IResponseMessage } from '../../interfaces/common.interface';
import { usersApi } from '../user/api';
import { timelineApi } from '../timeline/api';
import { onQueryStartedExtended } from '../../utils/query';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Services', 'Events', 'UserEvents'],
  endpoints: (builder) => ({
    getUserServices: builder.query<ServiceItem[], unknown>({
      query: () => ({
        url: '/services',
      }),
      providesTags: (result) =>
        result
          ? result.map((e) => ({
              type: 'Services',
              id: e.id,
            }))
          : [],
      transformResponse: (rawResult: { data: ServiceItem[] }) => {
        return rawResult.data;
      },
    }),

    removeUserService: builder.mutation<{ message: string }, { id: number }>({
      query: (params) => ({
        url: `/services/${params.id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Service was removed successfully',
        });
      },
      invalidatesTags: ['Services'],
    }),

    getOrganizationEvents: builder.query<IGetAllEventsResponse[], unknown>({
      query: () => ({
        url: '/services/calendar/organization-event',
      }),
      providesTags: ['Events'],
      transformResponse: (rawResult: { data: IGetAllEventsResponse[] }) => {
        return rawResult.data;
      },
    }),

    getUserEvents: builder.query<IGetAllEventsResponse[], unknown>({
      query: () => ({
        url: '/services/calendar/event',
      }),
      providesTags: ['UserEvents'],
      transformResponse: (rawResult: { data: IGetAllEventsResponse[] }) => {
        return rawResult.data;
      },
    }),

    createOrganizationCalendarEvent: builder.mutation<
      IGetAllEventsResponse,
      CreateCalendarEventDto
    >({
      query: (data) => ({
        url: '/services/calendar/organization-event',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Event created',
          errorMessage: 'Error creating event',
        });
      },
      invalidatesTags: ['Events'],
      transformResponse: (rawResult: { data: IGetAllEventsResponse }) => {
        return rawResult.data;
      },
    }),

    createUserCalendarEvent: builder.mutation<
      IGetAllEventsResponse,
      CreateCalendarEventDto
    >({
      query: (data) => ({
        url: '/services/calendar/event',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Event created',
          errorMessage: 'Error creating event',
        });

        if (result) {
          dispatch({
            type: `${timelineApi.reducerPath}/invalidateTags`,
            payload: [{ type: 'Timeline', id: 'LIST' }],
          });
        }
      },
      transformResponse: (rawResult: { data: IGetAllEventsResponse }) => {
        return rawResult.data;
      },
    }),

    updateCalendarEvent: builder.mutation<
      IResponseMessage,
      Partial<CreateCalendarEventDto> & { eventId: string }
    >({
      query: ({ eventId, ...rest }) => ({
        url: `/services/calendar/organization-event/${eventId}`,
        method: 'PATCH',
        data: rest,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Event updated',
          errorMessage: 'Error updating event',
        });

        if (result) {
          dispatch(
            usersApi.util.invalidateTags([{ type: 'User', id: 'currentUser' }]),
          );
        }
      },
      invalidatesTags: ['Events'],
    }),

    deleteCalendarEvent: builder.mutation<
      IResponseMessage,
      IDeleteEventRequest
    >({
      query: ({ eventId, calendarId }) => {
        return {
          url: `/services/calendar/organization-event/${eventId}`,
          method: 'DELETE',
          data: { calendarId },
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Event deleted',
          errorMessage: 'Error deleting event',
        });

        if (result) {
          dispatch(
            usersApi.util.invalidateTags([{ type: 'User', id: 'currentUser' }]),
          );
        }
      },
      invalidatesTags: ['Events'],
    }),

    createGoogleTask: builder.mutation<GoogleTaskItem, CreateGoogleTaskDto>({
      query: (data) => ({
        url: '/services/tasks',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Task created',
          errorMessage: 'Error creating a task',
        });

        if (result) {
          dispatch({
            type: `${timelineApi.reducerPath}/invalidateTags`,
            payload: [{ type: 'Timeline', id: 'LIST' }],
          });
        }
      },
      transformResponse: (rawResult: { data: GoogleTaskItem }) => {
        return rawResult.data;
      },
    }),

    sendEmail: builder.mutation<GmailItem, SendEmailDto>({
      query: (data) => ({
        url: '/services/gmail',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Email sent',
          errorMessage: 'Error while send email',
        });

        if (result) {
          dispatch({
            type: `${timelineApi.reducerPath}/invalidateTags`,
            payload: [{ type: 'Timeline', id: 'LIST' }],
          });
        }
      },
      transformResponse: (rawResult: { data: GmailItem }) => {
        return rawResult.data;
      },
    }),
  }),
});

export const {
  useGetUserServicesQuery,
  useGetOrganizationEventsQuery,
  useGetUserEventsQuery,
  useCreateOrganizationCalendarEventMutation,
  useCreateUserCalendarEventMutation,
  useSendEmailMutation,
  useCreateGoogleTaskMutation,
  useRemoveUserServiceMutation,
  useDeleteCalendarEventMutation,
  useUpdateCalendarEventMutation,
} = servicesApi;
