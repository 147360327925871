import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 20, height = 26, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.6667 8.76278H15.4762V6.38182C15.4762 3.04849 12.8571 0.429443 9.52381 0.429443C6.19048 0.429443 3.57143 3.04849 3.57143 6.38182V8.76278H2.38095C1.07143 8.76278 0 9.83421 0 11.1437V23.0485C0 24.358 1.07143 25.4294 2.38095 25.4294H16.6667C17.9762 25.4294 19.0476 24.358 19.0476 23.0485V11.1437C19.0476 9.83421 17.9762 8.76278 16.6667 8.76278ZM9.52381 19.4771C8.21429 19.4771 7.14286 18.4056 7.14286 17.0961C7.14286 15.7866 8.21429 14.7152 9.52381 14.7152C10.8333 14.7152 11.9048 15.7866 11.9048 17.0961C11.9048 18.4056 10.8333 19.4771 9.52381 19.4771ZM13.2143 8.76278H5.83333V6.38182C5.83333 4.35801 7.5 2.69135 9.52381 2.69135C11.5476 2.69135 13.2143 4.35801 13.2143 6.38182V8.76278Z"
      fill="#45494E"
    />
  </svg>
);

export default Svg;
