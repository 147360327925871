import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 9, height = 9, className, ...other }) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 9 9"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...other}
  >
    <path
      d="M5.32453 4.50007L8.82895 0.995468C9.05702 0.767509 9.05702 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995528 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767509 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995528 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05702 8.6012 9.05702 8.23263 8.82895 8.00467L5.32453 4.50007Z"
      fill="white"
    />
  </svg>
);

export default Svg;
