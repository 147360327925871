import { useCallback, useLayoutEffect, useRef, useState, memo } from 'react';
import ReactDOM from 'react-dom';

import useEventListener from '../hooks/useEventListener';
import { classNames } from '../../utils/classNames';
import { FixedTooltipProps } from './types';
import classes from './tooltip.module.scss';

const getNumber = (number: number | undefined) => number || 0;

const middleOfTooltipToMiddleOfContainer = (
  container: DOMRect,
  element: DOMRect,
) => {
  return `${
    getNumber(container.left) +
    getNumber(container.width) / 2 -
    getNumber(element.width) / 2
  }px`;
};

const getTopVertical = (
  container: DOMRect,
  element: DOMRect,
  shiftY: number,
) => {
  return `${getNumber(container.top) - getNumber(element.height) - shiftY}px`;
};

const getBottomVertical = (container: DOMRect, shiftY: number) => {
  return `${getNumber(container.bottom) + shiftY}px`;
};

const getLeftHorizontal = (container: DOMRect) => {
  return `${getNumber(container.left)}px`;
};

const calculateCoordinates = (
  event: MouseEvent,
  tooltipRef: HTMLDivElement,
  iconContainerRef: HTMLDivElement,
) => {
  const isHoveredTooltip = tooltipRef?.contains(event.target as Node);
  const isHoveredContainer = iconContainerRef?.contains(event.target as Node);
  const tooltipElement: DOMRect = tooltipRef?.getBoundingClientRect();
  const containerElement: DOMRect = iconContainerRef?.getBoundingClientRect();
  const { clientY, clientX } = event;

  const containerTop = clientY < containerElement?.top;
  const tooltipTop = clientY > tooltipElement?.top;
  const tooltipLeft = clientX > tooltipElement?.left;
  const tooltipRight = clientX < tooltipElement?.right;

  const coordinates =
    isHoveredTooltip ||
    isHoveredContainer ||
    (containerTop && tooltipTop && tooltipLeft && tooltipRight);

  return coordinates;
};

export const FixedTooltip = memo(
  ({
    content,
    color = 'dark',
    shiftX = 8,
    position = 'auto',
    children,
    childContainerClassName = '',
    disabled = false,
    tooltipClassName = '',
    shiftY = 8,
    allowHoverContent = false,
  }: FixedTooltipProps) => {
    const iconContainerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const calculatePosition = useCallback(() => {
      const container = iconContainerRef.current?.getBoundingClientRect();
      const increasedWidthByHover = 8;

      if (container && tooltipRef.current) {
        const element = tooltipRef.current?.getBoundingClientRect();
        const halfHeightOfTooltip = element.height / 2;
        const halfHeightOfContainer = getNumber(container.height) / 2;

        tooltipRef.current.style.top = `${
          getNumber(container.top) - halfHeightOfTooltip + halfHeightOfContainer
        }px`;

        switch (position) {
          case 'left': {
            tooltipRef.current.style.left = `${
              getNumber(container.x) -
              getNumber(element.width) -
              increasedWidthByHover -
              shiftX
            }px`;

            tooltipRef.current.classList.add(classes.tooltip__left);
            break;
          }

          case 'right': {
            tooltipRef.current.style.left = `${
              getNumber(container.x) +
              getNumber(container.width) +
              increasedWidthByHover +
              shiftX
            }px`;

            tooltipRef.current.classList.add(classes.tooltip__right);
            break;
          }

          case 'top': {
            tooltipRef.current.style.left = middleOfTooltipToMiddleOfContainer(
              container,
              element,
            );
            tooltipRef.current.style.top = getTopVertical(
              container,
              element,
              shiftY,
            );
            tooltipRef.current.classList.add(classes.tooltip__top);
            break;
          }

          case 'top-left': {
            tooltipRef.current.style.top = getTopVertical(
              container,
              element,
              shiftY,
            );
            tooltipRef.current.style.left = getLeftHorizontal(container);
            tooltipRef.current.classList.add(classes['tooltip__top-left']);
            break;
          }

          case 'bottom': {
            tooltipRef.current.style.top = getBottomVertical(container, shiftY);
            tooltipRef.current.style.left = middleOfTooltipToMiddleOfContainer(
              container,
              element,
            );
            tooltipRef.current.classList.add(classes.tooltip__bottom);

            break;
          }

          case 'bottom-right': {
            tooltipRef.current.style.top = getBottomVertical(container, shiftY);
            tooltipRef.current.style.left = `${
              getNumber(container.right) - getNumber(element.width)
            }px`;
            tooltipRef.current.classList.add(classes['tooltip__bottom-right']);
            break;
          }

          case 'bottom-left': {
            tooltipRef.current.style.top = getBottomVertical(container, shiftY);
            tooltipRef.current.style.left = getLeftHorizontal(container);
            tooltipRef.current.classList.add(classes['tooltip__bottom-left']);
            break;
          }

          case 'auto': {
            const screenWidth = window.innerWidth;
            const availableSpaceAtRight =
              screenWidth - (container.x + container.width);
            const spaceWitchAreNeeded =
              element.width + increasedWidthByHover + shiftX;

            if (availableSpaceAtRight > spaceWitchAreNeeded) {
              tooltipRef.current.style.left = `${
                getNumber(container.x) +
                getNumber(container.width) +
                increasedWidthByHover +
                shiftX
              }px`;

              tooltipRef.current.classList.add(classes.tooltip__right);
            } else {
              tooltipRef.current.style.left = `${
                getNumber(container.x) -
                getNumber(element.width) -
                increasedWidthByHover -
                shiftX
              }px`;

              tooltipRef.current.classList.add(classes.tooltip__left);
            }
            break;
          }
        }
      }
    }, [position, shiftX, shiftY]);

    const onMouseLeave = useCallback(() => {
      setShowTooltip(false);
    }, []);

    const onMouseEnter = useCallback(() => {
      setShowTooltip(true);
    }, []);

    const mousemoveCallback = useCallback(
      (event: MouseEvent) => {
        const hovered = iconContainerRef.current?.matches(':hover');

        if (allowHoverContent) {
          const coordinates = calculateCoordinates(
            event,
            tooltipRef.current!,
            iconContainerRef.current!,
          );

          if (coordinates) {
            onMouseEnter();
          } else {
            onMouseLeave();
          }
        } else if (hovered) {
          onMouseEnter();
        } else {
          onMouseLeave();
        }
      },
      [allowHoverContent, onMouseEnter, onMouseLeave],
    );

    useLayoutEffect(() => {
      calculatePosition();
    }, [calculatePosition, showTooltip, content]);

    useEventListener('scroll', () => calculatePosition());
    useEventListener('mousemove', mousemoveCallback);

    return (
      <>
        <div
          className={classNames(
            classes.container__fixed,
            childContainerClassName,
          )}
          ref={iconContainerRef}
        >
          {children}
        </div>

        {showTooltip &&
          !disabled &&
          ReactDOM.createPortal(
            <div
              ref={tooltipRef}
              className={classNames(
                classes.tooltip,
                classes[`tooltip--${color}`],
                tooltipClassName,
              )}
            >
              {content}
            </div>,
            document.body,
          )}
      </>
    );
  },
);
