import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 14, height = 16, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.928 9.28184L3.52902 14.3862C2.5294 14.9937 1.25 14.2741 1.25 13.1044L1.25 2.8956C1.25 1.72586 2.52941 1.00625 3.52902 1.61376L11.9281 6.71816C12.8894 7.30238 12.8894 8.69762 11.928 9.28184Z"
      stroke="#137BC6"
    />
  </svg>
);

export default Svg;
