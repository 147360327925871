export const ACCESS_TOKEN_NAME = 'access_token';
export const REDIRECT_URL = 'redirect_url';

export const setTokenToLocalStorage = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_NAME, token);
};

export const getTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN_NAME);
};

export const removeTokenFromLocalStorage = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
};
