import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 10, height = 9, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.875 3.87452H2.625L4.68125 1.81827C4.73983 1.76017 4.78633 1.69104 4.81806 1.61488C4.84979 1.53872 4.86612 1.45703 4.86612 1.37452C4.86612 1.29201 4.84979 1.21032 4.81806 1.13416C4.78633 1.058 4.73983 0.988871 4.68125 0.930769C4.56415 0.814362 4.40574 0.749023 4.24063 0.749023C4.07551 0.749023 3.9171 0.814362 3.8 0.930769L1.11875 3.61827C0.884008 3.85162 0.751395 4.16854 0.75 4.49952C0.753042 4.82834 0.885528 5.14271 1.11875 5.37452L3.8 8.06203C3.85827 8.11989 3.92737 8.16571 4.00336 8.19687C4.07934 8.22803 4.16071 8.24391 4.24283 8.24362C4.32496 8.24333 4.40622 8.22687 4.48198 8.19518C4.55774 8.16348 4.62651 8.11717 4.68438 8.0589C4.74224 8.00063 4.78806 7.93153 4.81922 7.85554C4.85038 7.77956 4.86626 7.69819 4.86597 7.61607C4.86568 7.53394 4.84922 7.45268 4.81753 7.37692C4.78583 7.30116 4.73952 7.23239 4.68125 7.17453L2.625 5.12452H8.875C9.04076 5.12452 9.19973 5.05867 9.31694 4.94146C9.43415 4.82425 9.5 4.66528 9.5 4.49952C9.5 4.33376 9.43415 4.17479 9.31694 4.05758C9.19973 3.94037 9.04076 3.87452 8.875 3.87452Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
