import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import {
  IPosition,
  ICreatePositionRequest,
  IGetPositionsRequest,
  IUpdatePositionRequest,
  IGetSinglePositionRequest,
} from '../../interfaces/position.interface';
import { IListOfElementsResponse } from '../../interfaces/common.interface';

export const positionApi = createApi({
  reducerPath: 'positionApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Position'],
  endpoints: (builder) => ({
    getPositions: builder.query<
      IListOfElementsResponse<IPosition>,
      IGetPositionsRequest
    >({
      query: (params: IGetPositionsRequest) => ({
        url: '/position',
        params,
      }),
      providesTags: (result) =>
        result
          ? result.rows.map((el) => ({
              type: 'Position',
              id: el.id,
            }))
          : [],
    }),

    getSinglePosition: builder.query<IPosition, IGetSinglePositionRequest>({
      query: ({ id }) => ({
        url: `/position/${id}`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Position', id: result.id }] : [],
    }),

    createPosition: builder.mutation<
      { data: IPosition },
      ICreatePositionRequest
    >({
      query: (data) => ({
        url: '/position',
        method: 'POST',
        data,
      }),
      invalidatesTags: () => ['Position'],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
      ) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Position was created successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Position creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    deletePosition: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => ({
        url: `/position/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Position', id: data.id },
      ],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
      ) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Position removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Position removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updatePosition: builder.mutation<IPosition, IUpdatePositionRequest>({
      query: (data) => {
        return {
          url: `/position/${data.id}`,
          method: 'PATCH',
          data: data.value,
        };
      },
      invalidatesTags: () => ['Position'],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
      ) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Position was updated successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetPositionsQuery,
  useGetSinglePositionQuery,
  useCreatePositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
} = positionApi;
