import { SvgIconType } from './svg.interface';

const PlusIcon: SvgIconType = ({ width = 11, height = 11, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.49928 0C4.99302 0 4.58262 0.410405 4.58262 0.916666V4.58369H0.916666C0.410406 4.58369 0 4.9941 0 5.50036C0 6.00662 0.410405 6.41702 0.916666 6.41702H4.58262V10.0833C4.58262 10.5896 4.99302 11 5.49928 11C6.00554 11 6.41595 10.5896 6.41595 10.0833V6.41702H10.0833C10.5896 6.41702 11 6.00662 11 5.50036C11 4.9941 10.5896 4.58369 10.0833 4.58369H6.41595V0.916666C6.41595 0.410406 6.00554 0 5.49928 0Z"
      fill="#137BC6"
    />
  </svg>
);

export default PlusIcon;
