import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 16, height = 16, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10 6H14V12C14 12.6285 14 12.9428 13.8047 13.1381C13.6095 13.3333 13.2952 13.3333 12.6667 13.3333H10V6Z"
      stroke="#137BC6"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <path
      d="M2 6H6V13.3333H3.33333C2.70479 13.3333 2.39052 13.3333 2.19526 13.1381C2 12.9428 2 12.6285 2 12V6Z"
      stroke="#137BC6"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <rect
      x="6"
      y="6"
      width="4"
      height="7.33333"
      stroke="#137BC6"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <path
      d="M2 4.00032C2 3.37179 2 3.05752 2.19526 2.86225C2.39052 2.66699 2.70479 2.66699 3.33333 2.66699H12.6667C13.2952 2.66699 13.6095 2.66699 13.8047 2.86225C14 3.05752 14 3.37179 14 4.00033V6.00033H2V4.00032Z"
      stroke="#137BC6"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
  </svg>
);

export default Svg;
