import { SvgIconType } from './svg.interface';

const ExpandArrowUp: SvgIconType = ({ width = 16, height = 16, className }) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.306651 10.8979C0.108228 11.1045 -1.29036e-06 11.3675 -1.26696e-06 11.6775C-1.22016e-06 12.2975 0.468996 12.7953 1.06426 12.7953C1.36189 12.7953 1.63247 12.6638 1.83991 12.4478L8.00902 5.87242L14.1601 12.4478C14.3675 12.6638 14.6471 12.7953 14.9357 12.7953C15.531 12.7953 16 12.2975 16 11.6775C16 11.3675 15.8918 11.1045 15.6933 10.8979L8.83878 3.58983C8.60428 3.32682 8.31567 3.20471 8 3.19531C7.68433 3.19531 7.41375 3.32682 7.17024 3.58983L0.306651 10.8979Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default ExpandArrowUp;
