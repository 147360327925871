import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 250, height = 250, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M130.922 58.8973C134.012 54.7233 137.422 50.7957 141.121 47.1501C143.604 44.7177 151.461 39.489 154.392 38.5067C156.684 37.7383 161.028 49.6067 160.992 57.0177C160.959 63.9909 158.443 84.5625 158.443 84.5625L130.922 58.8973Z"
      fill="#137BC6"
    />
    <path
      opacity="0.11"
      d="M136.415 66.0415C141.377 65.372 148.505 55.9955 153.192 51.1972C157.879 46.399 158.436 53.8126 157.588 63.274C157.17 67.9381 156.131 73.4685 154.641 76.0767C154.508 76.2751 154.331 76.4393 154.122 76.5558C153.914 76.6723 153.681 76.7378 153.443 76.7471C153.204 76.7563 152.967 76.709 152.75 76.6089C152.534 76.5089 152.344 76.359 152.196 76.1714C148.016 71.3514 142.538 67.8351 136.415 66.0415Z"
      fill="#D6DEE3"
    />
    <path
      d="M164.494 193.297C162.639 194.521 160.766 195.681 158.856 196.756C141.238 206.782 121.019 211.296 100.81 209.715C80.6001 208.133 61.3292 200.528 45.4858 187.883C49.8067 180.502 53.191 174.568 54.6652 171.211C60.7663 157.266 63.9281 155.3 64.3453 143.153C64.7533 131.007 59.0417 117.043 57.5581 104.637C67.4515 99.9643 152.922 128.244 152.922 128.244C144.679 145.212 158.374 158.267 161.629 165.973C165.514 175.189 165.495 184.294 164.494 193.297Z"
      fill="#137BC6"
    />
    <path
      d="M168.59 104.405C169.849 105.215 170.865 106.351 171.53 107.691C171.53 107.691 171.364 99.0099 166.666 92.1702C161.82 85.1157 158.181 82.9146 153.814 74.4407C149.341 65.7608 139.051 55.2647 125.003 54.1772C110.956 53.0902 77.3683 51.2626 65.1929 72.7483C61.9579 78.3238 59.635 84.3806 58.3121 90.6895C53.8619 97.9326 50.5267 111.282 50.7119 115.172C52.2796 113.321 54.1056 111.705 56.1339 110.375C56.1339 110.375 54.199 122.399 54.7503 128.718C57.0312 125.983 59.8488 123.744 63.029 122.141C68.8522 132.465 89.7855 147.469 119.165 144.218C143.119 141.567 153.929 137.84 158.874 129.936C160.698 126.938 162.326 123.824 163.747 120.615C165.623 122.456 167.05 124.703 167.919 127.183C168.656 122.929 168.543 118.571 167.587 114.361C167.587 114.361 169.95 116.737 171.132 118.058C171.155 112.594 170.745 109.121 168.59 104.405Z"
      fill="#137BC6"
    />
    <path
      opacity="0.3"
      d="M100.38 81.9556C103.632 77.7545 110.051 74.5798 115.702 79.8395C117.554 81.5632 114.497 95.7011 100.38 81.9556Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M153.943 80.5092C151.251 76.7351 146.352 74.1673 141.904 78.8758C139.799 81.1046 145.521 93.3154 153.943 80.5092Z"
      fill="black"
    />
    <path
      d="M140.696 89.4176C132.733 89.0869 128.361 89.2569 129.103 93.6815C129.721 97.3691 137.954 101.522 140.827 99.9027C145.138 97.4731 147.483 89.6995 140.696 89.4176Z"
      fill="white"
    />
    <path
      opacity="0.11"
      d="M137.633 99.0639C129.731 96.8131 114.303 94.5769 109.522 103.151C105.954 109.55 107.81 116.127 113.232 120.842C119.054 125.902 128.261 130.804 137.417 131.606C150.223 132.728 155.524 128.019 158.902 122.882C163.947 115.213 166.287 102.525 156.496 98.8915C150.973 96.8418 143.15 97.9389 137.633 99.0639Z"
      fill="#D6DEE3"
    />
    <path
      opacity="0.11"
      d="M158.857 196.756C140.312 207.32 118.903 211.753 97.6887 209.422C94.0047 198.137 93.6794 186.025 96.7522 174.559C102.241 153.557 120.192 158.36 129.399 163.497C135.797 160.539 144.884 157.563 151.328 162.31C158.579 167.67 160.164 182.116 158.857 196.756Z"
      fill="#D6DEE3"
    />
    <path
      opacity="0.2"
      d="M129.812 112.509C127.403 112.457 125.019 112.009 122.755 111.183C122.681 111.158 122.612 111.118 122.553 111.067C122.494 111.015 122.446 110.952 122.412 110.882C122.377 110.811 122.357 110.735 122.352 110.657C122.347 110.579 122.358 110.5 122.383 110.426C122.409 110.352 122.448 110.284 122.5 110.225C122.552 110.166 122.615 110.118 122.686 110.084C122.756 110.05 122.832 110.029 122.911 110.025C122.989 110.02 123.067 110.031 123.141 110.056C127.995 111.718 131.95 111.738 134.898 110.117C136.925 108.977 138.431 107.096 139.103 104.87C139.131 104.755 139.192 104.651 139.279 104.571C139.366 104.49 139.475 104.438 139.592 104.42C139.709 104.402 139.829 104.419 139.936 104.47C140.043 104.52 140.132 104.601 140.193 104.703C145.22 113.084 150.695 110.33 150.75 110.3C150.819 110.264 150.895 110.241 150.973 110.234C151.051 110.227 151.129 110.235 151.204 110.258C151.279 110.281 151.349 110.319 151.409 110.369C151.469 110.419 151.519 110.48 151.555 110.55C151.592 110.619 151.614 110.695 151.621 110.773C151.628 110.851 151.62 110.93 151.597 111.004C151.573 111.079 151.536 111.149 151.485 111.209C151.435 111.269 151.374 111.319 151.304 111.355C151.242 111.387 145.265 114.401 139.831 106.354C138.945 108.391 137.413 110.08 135.472 111.161C133.731 112.085 131.783 112.549 129.812 112.509Z"
      fill="black"
    />
    <path
      d="M86.6466 59.1784C81.2527 57.2629 76.9977 53.4932 70.9289 47.6461C66.3262 43.2115 62.7061 36.838 58.5475 36.5435C55.2942 36.3131 52.6553 47.6415 54.4852 58.6194C55.9002 67.1084 64.9359 88.1616 64.9359 88.1616L86.6466 59.1784Z"
      fill="#137BC6"
    />
    <path
      opacity="0.11"
      d="M77.3758 61.3874C77.6581 61.2113 77.8902 60.9653 78.0497 60.6732C78.2091 60.3811 78.2905 60.0529 78.2859 59.7201C78.2814 59.3874 78.191 59.0614 78.0236 58.7738C77.8562 58.4862 77.6174 58.2467 77.3303 58.0784C72.9287 55.427 67.5409 49.7699 64.3215 45.9413C60.0821 40.8997 58.6835 49.5916 60.4948 58.8891C61.2243 62.0757 62.4462 65.129 64.1163 67.9391C64.3808 68.4058 64.7565 68.8 65.21 69.0865C65.6635 69.373 66.1808 69.543 66.7158 69.5815C67.2509 69.6199 67.7871 69.5256 68.2769 69.3069C68.7667 69.0881 69.1949 68.7518 69.5234 68.3277C71.7127 65.5708 74.3709 63.2215 77.3758 61.3874Z"
      fill="#D6DEE3"
    />
    <path
      d="M160.137 73.5823L86.7186 75.117C86.5202 75.1204 86.3283 75.0461 86.1839 74.9099C86.0395 74.7737 85.9541 74.5864 85.946 74.3881C85.9419 74.291 85.9572 74.1939 85.9912 74.1028C86.0251 74.0116 86.077 73.9281 86.1437 73.8573C86.2103 73.7865 86.2905 73.7297 86.3795 73.6904C86.4685 73.651 86.5644 73.6298 86.6617 73.6281L160.08 72.0934C160.279 72.09 160.47 72.1644 160.615 72.3006C160.759 72.4367 160.845 72.624 160.853 72.8223V72.8223C160.857 72.9195 160.842 73.0166 160.808 73.1077C160.774 73.1989 160.722 73.2823 160.655 73.3532C160.588 73.424 160.508 73.4807 160.419 73.5201C160.33 73.5594 160.234 73.5806 160.137 73.5823Z"
      fill="white"
    />
    <path
      d="M96.1062 90.8484L77.0542 91.2469L76.3892 73.842L95.4411 73.4443L96.1062 90.8484Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M88.2479 82.3455C88.2479 82.7408 88.1307 83.1272 87.9111 83.4558C87.6915 83.7845 87.3794 84.0406 87.0143 84.1919C86.6491 84.3432 86.2473 84.3828 85.8596 84.3057C85.4719 84.2286 85.1158 84.0382 84.8363 83.7588C84.5568 83.4793 84.3665 83.1232 84.2894 82.7355C84.2123 82.3479 84.2518 81.946 84.4031 81.5809C84.5543 81.2157 84.8105 80.9036 85.1391 80.684C85.4678 80.4644 85.8542 80.3472 86.2494 80.3472C86.7794 80.3472 87.2877 80.5577 87.6625 80.9325C88.0373 81.3072 88.2479 81.8155 88.2479 82.3455Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M96.0686 89.8088L120.935 89.2891C121.411 89.2806 121.881 89.1769 122.317 88.9841C122.753 88.7913 123.146 88.5132 123.472 88.1663C123.799 87.8194 124.053 87.4106 124.219 86.964C124.386 86.5174 124.461 86.0421 124.441 85.5659L123.983 73.5928L95.4717 74.1888L96.0686 89.8088Z"
      fill="white"
    />
    <path
      d="M59.6 91.611L77.0543 91.2462L76.3892 73.8418L59.0272 74.2047C58.673 74.2121 58.3239 74.29 58.0002 74.4338C57.6764 74.5775 57.3845 74.7843 57.1416 75.0421C56.8986 75.2999 56.7094 75.6035 56.585 75.9351C56.4606 76.2668 56.4035 76.6199 56.417 76.9739L56.8783 89.0456C56.905 89.7447 57.2052 90.4053 57.7143 90.8851C58.2234 91.3649 58.9006 91.6256 59.6 91.611Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M59.6 91.611L77.0543 91.2462L76.3892 73.8418L59.0272 74.2047C58.673 74.2121 58.3239 74.29 58.0002 74.4338C57.6764 74.5775 57.3845 74.7843 57.1416 75.0421C56.8986 75.2999 56.7094 75.6035 56.585 75.9351C56.4606 76.2668 56.4035 76.6199 56.417 76.9739L56.8783 89.0456C56.905 89.7447 57.2052 90.4053 57.7143 90.8851C58.2234 91.3649 58.9006 91.6256 59.6 91.611Z"
      fill="#407BFF"
    />
    <path
      d="M249.724 232.057C249.388 230.314 248.626 228.681 247.508 227.302C245.261 224.903 242.864 222.652 240.504 220.365C238.127 218.094 235.729 215.845 233.278 213.65C230.856 211.424 228.366 209.267 225.877 207.109C220.86 202.831 215.722 198.674 210.396 194.707C207.965 192.906 205.497 191.142 202.992 189.415C203.584 187.806 203.704 186.06 203.336 184.385C202.968 182.71 202.128 181.175 200.916 179.962L197.258 176.303C196.619 175.663 195.887 175.123 195.087 174.702C211.831 152.785 219.618 125.323 216.871 97.8788C214.124 70.435 201.047 45.0615 180.291 26.8987C159.535 8.73592 132.651 -0.858082 105.085 0.060342C77.5191 0.978766 51.3331 12.3409 31.8318 31.845C12.3304 51.3491 0.97194 77.5367 0.0573922 105.103C-0.857156 132.668 8.74062 159.551 26.9063 180.305C45.0721 201.059 70.4474 214.132 97.8916 216.875C125.336 219.619 152.797 211.828 174.711 195.08C175.133 195.88 175.672 196.611 176.312 197.249L179.97 200.908C181.183 202.12 182.718 202.96 184.393 203.327C186.069 203.695 187.814 203.576 189.424 202.983C191.144 205.495 192.908 207.963 194.715 210.388C198.683 215.714 202.84 220.851 207.117 225.868C209.276 228.357 211.432 230.848 213.658 233.269C215.854 235.72 218.103 238.119 220.373 240.496C222.661 242.855 224.911 245.252 227.311 247.499C228.69 248.618 230.323 249.379 232.066 249.716C234.47 250.172 236.947 250.078 239.311 249.441C241.767 248.841 244.011 247.578 245.799 245.79C247.586 244.002 248.849 241.758 249.45 239.302C250.087 236.939 250.18 234.462 249.724 232.057ZM37.0546 180.347C22.882 166.177 13.2298 148.122 9.31871 128.465C5.40766 108.809 7.41343 88.4342 15.0823 69.9178C22.7513 51.4014 35.7389 35.575 52.4027 24.4402C69.0666 13.3053 88.6582 7.36205 108.7 7.36205C128.742 7.36205 148.333 13.3053 164.997 24.4402C181.661 35.575 194.648 51.4014 202.317 69.9178C209.986 88.4342 211.992 108.809 208.081 128.465C204.17 148.122 194.518 166.177 180.345 180.347C161.343 199.347 135.571 210.021 108.7 210.021C81.8283 210.021 56.0571 199.347 37.0546 180.347Z"
      fill="#0471C0"
    />
    <path
      opacity="0.3"
      d="M189.423 202.983L202.991 189.415C202.991 189.415 199.387 199.746 189.423 202.983Z"
      fill="black"
    />
    <path
      d="M210.296 63.9776C209 61.0172 207.56 58.0856 206.016 55.2643L206.829 54.8193C208.387 57.6643 209.838 60.6205 211.146 63.6059L210.296 63.9776ZM202.253 48.9015C197.935 42.1355 192.894 35.8593 187.218 30.1838L187.874 29.5283C193.597 35.2515 198.681 41.5803 203.035 48.4031L202.253 48.9015Z"
      fill="#0471C0"
    />
    <path
      opacity="0.2"
      d="M210.296 63.9776C209 61.0172 207.56 58.0856 206.016 55.2643L206.829 54.8193C208.387 57.6643 209.838 60.6205 211.146 63.6059L210.296 63.9776ZM202.253 48.9015C197.935 42.1355 192.894 35.8593 187.218 30.1838L187.874 29.5283C193.597 35.2515 198.681 41.5803 203.035 48.4031L202.253 48.9015Z"
      fill="#0471C0"
    />
  </svg>
);

export default Svg;
