import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import {
  GetPermissionsRequestParams,
  GetPermissionsResponse,
  IUpdatePermissionsRequest,
} from '../../interfaces/permission.interface';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Permissions'],
  endpoints: (builder) => ({
    getPermissions: builder.query<
      GetPermissionsResponse,
      GetPermissionsRequestParams
    >({
      query: (params) => ({
        url: `/permissions`,
        params,
      }),
      providesTags: () => ['Permissions'],
      transformResponse: (rawResult: { data: GetPermissionsResponse }) => {
        return rawResult.data;
      },
    }),

    updatePermissions: builder.mutation<
      { data: GetPermissionsResponse },
      IUpdatePermissionsRequest
    >({
      query: (data) => ({
        url: `/role/${data.id}/permissions`,
        method: 'PATCH',
        data: data.value,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Permissions is successfully updated',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
      invalidatesTags: () => ['Permissions'],
    }),
  }),
});

export const {
  useUpdatePermissionsMutation,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
} = permissionsApi;
