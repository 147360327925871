import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import {
  ICreateEquipment,
  IEquipment,
  IEquipmentHistory,
  IEquipmentParams,
  IUpdateEquipment,
} from '../../interfaces/equipment.interface';
import { ISingleElement, PageDto } from '../../interfaces/common.interface';
import { addNotification } from '../app/reducer';

export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Equipment'],
  endpoints: (builder) => ({
    createEquipment: builder.mutation<
      ISingleElement<IEquipment>,
      ICreateEquipment
    >({
      query: (data) => {
        return {
          url: '/equipment',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Equipment', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Equipment created successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Equipment creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getEquipmentList: builder.query<PageDto<IEquipment>, IEquipmentParams>({
      query: (params: IEquipmentParams) => ({
        url: '/equipment',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: { id: number }) => ({
                type: 'Equipment' as const,
                id,
              })),
              { type: 'Equipment', id: 'LIST' },
            ]
          : [{ type: 'Equipment', id: 'LIST' }],
    }),

    deleteEquipment: builder.mutation<{ message: string }, { id: number }>({
      query: (data) => {
        return {
          url: `/equipment/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, data) => [{ type: 'Equipment', id: data.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Equipment removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Equipment removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getEquipment: builder.query<IEquipment, { id: string }>({
      query: ({ id }) => ({
        url: `/equipment/${id}`,
      }),
      providesTags: (result) => [{ type: 'Equipment', id: result?.id }],
      transformResponse: (response: { data: IEquipment }) => response.data,
    }),

    updateEquipment: builder.mutation<
      { data: { message: string } },
      IUpdateEquipment
    >({
      query: (data) => {
        return {
          url: `/equipment/${data.id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Equipment' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Equipment data updated successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Equipment data updating error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getEquipmentHistory: builder.query<
      ISingleElement<IEquipmentHistory[]>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/equipment/${id}/history`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: { id: number }) => ({
                type: 'Equipment' as const,
                id,
              })),
              { type: 'Equipment', id: 'LIST' },
            ]
          : [{ type: 'Equipment', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateEquipmentMutation,
  useGetEquipmentListQuery,
  useDeleteEquipmentMutation,
  useGetEquipmentQuery,
  useUpdateEquipmentMutation,
  useGetEquipmentHistoryQuery,
} = equipmentApi;
