import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({
  width = 18,
  height = 20,
  className,
  color = '#000000',
  isFilled = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    width={width}
    height={height}
    className={className}
  >
    <g
      stroke={isFilled ? 'none' : color}
      strokeWidth="2"
      fill={isFilled ? color : 'none'}
      fillRule="evenodd"
    >
      <g>
        <path
          d="M39.4418525,19.945683 L41.5368613,33.9124087 C44.8731757,34.6460584 47,35.7567964 47,37 C47,39.209139 40.2842712,41 32,41 C23.7157288,41 17,39.209139 17,37 C17,35.7567964 19.1268243,34.6460584 22.4631387,33.9124087 L24.5581475,19.945683 C22.3708176,19.2145917 21,18.1655089 21,17 C21,14.790861 25.9248678,13 32,13 C38.0751322,13 43,14.790861 43,17 C43,18.1655089 41.6291824,19.2145917 39.4418525,19.945683 Z M31,51.9993209 L31,42.0006851 L34,42.0006851 L34,51.9993209 L32.5,53.9993209 L31,51.9993209 Z"
          transform="rotate(45 40 10)"
        />
      </g>
    </g>
  </svg>
);
export default Svg;
