import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.5 0C3.35495 0 0 3.35438 0 7.5C0 8.8155 0.343094 10.1003 0.994606 11.2335L0.0282669 14.2344C-0.0391388 14.4436 0.0162506 14.6729 0.171661 14.8283C0.325584 14.9823 0.554352 15.0397 0.76561 14.9717L3.76648 14.0054C4.89967 14.6569 6.1845 15 7.5 15C11.6451 15 15 11.6456 15 7.5C15 3.35495 11.6456 0 7.5 0ZM7.5 13.8281C6.31176 13.8281 5.1535 13.4966 4.15043 12.8693C4.00612 12.7792 3.8269 12.7548 3.66016 12.8085L1.49391 13.5061L2.19154 11.3398C2.24442 11.1755 2.2221 10.9961 2.13055 10.8496C1.50341 9.8465 1.17188 8.68824 1.17188 7.5C1.17188 4.0107 4.0107 1.17188 7.5 1.17188C10.9893 1.17188 13.8281 4.0107 13.8281 7.5C13.8281 10.9893 10.9893 13.8281 7.5 13.8281ZM8.23242 7.5C8.23242 7.90443 7.90455 8.23242 7.5 8.23242C7.09545 8.23242 6.76758 7.90443 6.76758 7.5C6.76758 7.09545 7.09545 6.76758 7.5 6.76758C7.90455 6.76758 8.23242 7.09545 8.23242 7.5ZM11.1621 7.5C11.1621 7.90443 10.8342 8.23242 10.4297 8.23242C10.0251 8.23242 9.69727 7.90443 9.69727 7.5C9.69727 7.09545 10.0251 6.76758 10.4297 6.76758C10.8342 6.76758 11.1621 7.09545 11.1621 7.5ZM5.30273 7.5C5.30273 7.90443 4.97486 8.23242 4.57031 8.23242C4.16588 8.23242 3.83789 7.90443 3.83789 7.5C3.83789 7.09545 4.16588 6.76758 4.57031 6.76758C4.97486 6.76758 5.30273 7.09545 5.30273 7.5Z"
      fill="#45494E"
    />
  </svg>
);

export default Svg;
