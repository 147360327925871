import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import initialState from '../initial-store';

const initState = initialState.dashboard;

const dashboardSlice = createSlice({
  name: 'post',
  initialState: { ...initState },
  reducers: {
    changeDashboardPage(
      state,
      { payload }: PayloadAction<Partial<typeof initState>>,
    ) {
      return { ...state, ...payload };
    },
  },
});

export const { changeDashboardPage } = dashboardSlice.actions;

export default dashboardSlice.reducer;

const selectSelf = (state: RootState) => state;

export const getDashboardPageParams = createSelector(
  selectSelf,
  (state: RootState) => state.dashboard,
);
