import { SvgIconType } from './svg.interface';

const FontColorIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
  color = '#45494E',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_83)">
      <path
        d="M11.4345 10.5H6.5655L5.3655 13.5H3.75L8.25 2.25H9.75L14.25 13.5H12.6345L11.4345 10.5ZM10.8345 9L9 4.41375L7.1655 9H10.8345V9ZM2.25 15H15.75V16.5H2.25V15Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_6_83">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FontColorIcon;
