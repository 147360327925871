import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C4.52381 20 0 15.4762 0 10C0 4.52381 4.51613 0 9.99232 0C15.4685 0 20 4.52381 20 10C20 15.4762 15.4762 20 10 20ZM8.77108 15.023C9.05526 15.023 9.30104 14.8924 9.48537 14.6082L14.6467 6.52068C14.7619 6.35171 14.8617 6.14433 14.8617 5.96C14.8617 5.55294 14.5007 5.29948 14.1244 5.29948C13.9017 5.29948 13.6789 5.43005 13.5099 5.69118L8.73268 13.2872L6.19044 10.0998C5.99075 9.84633 5.78337 9.75416 5.5376 9.75416C5.16125 9.75416 4.84635 10.0537 4.84635 10.4608C4.84635 10.6528 4.92316 10.8602 5.05373 11.0214L8.0184 14.6159C8.25649 14.9078 8.48691 15.023 8.77108 15.023Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
