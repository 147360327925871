import { SvgIconType } from './svg.interface';

const BoldIcon: SvgIconType = ({ width = 18, height = 18, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_56)">
      <path
        d="M6 8.25H9.375C9.87228 8.25 10.3492 8.05246 10.7008 7.70083C11.0525 7.34919 11.25 6.87228 11.25 6.375C11.25 5.87772 11.0525 5.40081 10.7008 5.04917C10.3492 4.69754 9.87228 4.5 9.375 4.5H6V8.25ZM13.5 11.625C13.5 12.0682 13.4127 12.5071 13.2431 12.9166C13.0735 13.326 12.8249 13.6981 12.5115 14.0115C12.1981 14.3249 11.826 14.5735 11.4166 14.7431C11.0071 14.9127 10.5682 15 10.125 15H4.5V3H9.375C10.0357 3.00003 10.6819 3.19399 11.2334 3.55783C11.7849 3.92168 12.2175 4.4394 12.4775 5.0468C12.7375 5.6542 12.8136 6.32457 12.6961 6.97477C12.5787 7.62497 12.273 8.22641 11.817 8.7045C12.329 9.00097 12.754 9.42688 13.0494 9.93948C13.3448 10.4521 13.5002 11.0334 13.5 11.625ZM6 9.75V13.5H10.125C10.6223 13.5 11.0992 13.3025 11.4508 12.9508C11.8025 12.5992 12 12.1223 12 11.625C12 11.1277 11.8025 10.6508 11.4508 10.2992C11.0992 9.94754 10.6223 9.75 10.125 9.75H6Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_56">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BoldIcon;
