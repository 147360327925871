import { SvgIconType } from './svg.interface';

const LineHeightIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_80)">
      <path
        d="M8.25 3H15.75V4.5H8.25V3ZM4.5 5.25V8.25H3V5.25H0.75L3.75 2.25L6.75 5.25H4.5ZM4.5 12.75H6.75L3.75 15.75L0.75 12.75H3V9.75H4.5V12.75ZM8.25 13.5H15.75V15H8.25V13.5ZM6.75 8.25H15.75V9.75H6.75V8.25Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_80">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LineHeightIcon;
