import { SvgIconType } from './svg.interface';

const AccountingIcon: SvgIconType = ({
  width = 23,
  height = 23,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.7001 14.1992C6.5001 14.1992 6.3001 14.0992 6.2001 13.9992C5.9001 13.6992 5.9001 13.1992 6.2001 12.8992L10.3001 8.79922C11.0001 8.09922 12.1001 8.09922 12.8001 8.79922L14.3001 10.2992C14.4001 10.3992 14.6001 10.3992 14.7001 10.2992L19.7001 5.29922C20.0001 4.99922 20.5001 4.99922 20.8001 5.29922C21.1001 5.59922 21.1001 6.09922 20.8001 6.39922L15.8001 11.3992C15.1001 12.0992 14.0001 12.0992 13.3001 11.3992L11.8001 9.89922C11.7001 9.79922 11.5001 9.79922 11.4001 9.89922L7.3001 13.9992C7.1001 14.0992 6.9001 14.1992 6.7001 14.1992Z"
      fill="white"
    />
    <path
      d="M20.1002 20.8992H6.1002C4.9002 20.8992 4.2002 20.8992 3.7002 20.5992C3.2002 20.2992 2.8002 19.8992 2.5002 19.3992C2.2002 18.7992 2.2002 18.1992 2.2002 16.9992V2.99922C2.2002 2.59922 2.5002 2.19922 3.0002 2.19922C3.5002 2.19922 3.8002 2.49922 3.8002 2.99922V16.9992C3.8002 17.9992 3.8002 18.4992 3.9002 18.7992C4.0002 18.9992 4.2002 19.1992 4.4002 19.2992C4.7002 19.3992 5.2002 19.3992 6.2002 19.3992H20.2002C20.6002 19.3992 21.0002 19.6992 21.0002 20.1992C21.0002 20.6992 20.5002 20.8992 20.1002 20.8992Z"
      fill="white"
    />
  </svg>
);

export default AccountingIcon;
