import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#0077B5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.86711 4.46371C5.86711 4.8528 5.57085 5.16769 5.10755 5.16769C4.6623 5.16769 4.36604 4.8528 4.37521 4.46371C4.36604 4.05571 4.66229 3.75 5.11642 3.75C5.57084 3.75 5.85823 4.05571 5.86711 4.46371ZM4.41246 10.256V5.72382H5.82098V10.2557H4.41246V10.256Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94985 7.17019C6.94985 6.60489 6.93122 6.12297 6.9126 5.72442H8.13603L8.20106 6.34531H8.22886C8.41424 6.05793 8.87754 5.62271 9.62822 5.62271C10.5548 5.62271 11.2499 6.23443 11.2499 7.56845V10.2566H9.84138V7.74495C9.84138 7.16074 9.63767 6.76248 9.12796 6.76248C8.73858 6.76248 8.50707 7.03123 8.41453 7.29053C8.37728 7.38336 8.35894 7.51287 8.35894 7.64294V10.2566H6.95041V7.17019H6.94985Z"
      fill="white"
    />
  </svg>
);

export default Svg;
