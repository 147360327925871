import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5519 14.2833C10.8224 17.0114 6.38107 17.0121 3.65161 14.2833C0.922848 11.5539 0.922848 7.11256 3.65161 4.38309C6.38107 1.65503 10.8224 1.65503 13.5519 4.38309C16.2806 7.11256 16.2806 11.5539 13.5519 14.2833ZM20.997 20.7382L15.0111 14.753C17.8022 11.4559 17.6489 6.5016 14.5415 3.39351C11.2662 0.118854 5.9374 0.118154 2.66205 3.39351C-0.612609 6.66887 -0.612609 11.9983 2.66205 15.273C5.75264 18.3636 10.6979 18.5567 14.0215 15.7426L20.0067 21.7285C20.2804 22.0015 20.7234 22.0015 20.997 21.7285C21.27 21.4549 21.27 21.0118 20.997 20.7382Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default Svg;
