import { SvgIconType } from './svg.interface';

const ListOrderedIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_65)">
      <path
        d="M6 3H15.75V4.5H6V3ZM3.75 2.25V4.5H4.5V5.25H2.25V4.5H3V3H2.25V2.25H3.75ZM2.25 10.5V8.625H3.75V8.25H2.25V7.5H4.5V9.375H3V9.75H4.5V10.5H2.25ZM3.75 14.625H2.25V13.875H3.75V13.5H2.25V12.75H4.5V15.75H2.25V15H3.75V14.625ZM6 8.25H15.75V9.75H6V8.25ZM6 13.5H15.75V15H6V13.5Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_65">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ListOrderedIcon;
