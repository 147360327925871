import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#3B5998" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16095 5.29931C8.96615 5.26035 8.70307 5.23125 8.53761 5.23125C8.08959 5.23125 8.06049 5.42604 8.06049 5.73771V6.29252H9.18043L9.0828 7.44179H8.06049V10.9375H6.65823V7.44179H5.9375V6.29252H6.65823V5.58165C6.65823 4.60792 7.11587 4.0625 8.26491 4.0625C8.66412 4.0625 8.9563 4.12094 9.33603 4.19885L9.16095 5.29931Z"
      fill="white"
    />
  </svg>
);

export default Svg;
