import { FC, PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NotificationContainer } from '../../notifications/container';
import { getSidebarState } from '../../../store/app/reducer';
import { SideBar } from '../../sidebar';
import { classNames } from '../../../utils/classNames';

import classes from './style.module.scss';

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const check = useSelector(getSidebarState);

  const styleClasses = useMemo(() => {
    return classNames({
      [classes.content]: true,
      [classes.contentMenuOpen]: check,
      [classes.contentMenuClose]: !check,
    });
  }, [check]);

  return (
    <div className={classes.layout}>
      <nav className={classes.sidebar}>
        <SideBar />
      </nav>
      <main className={styleClasses}>{children}</main>
      <NotificationContainer position="right-top" />
    </div>
  );
};
