import { SvgIconType } from './svg.interface';

const DoubleExpandIcon: SvgIconType = ({
  width = 23,
  height = 23,
  className,
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1667 6.70768L14.3751 10.541L9.58341 6.70768"
      stroke="#B6B7B8"
    />
    <path
      d="M13.4167 16.2923L8.62508 12.459L3.83341 16.2923"
      stroke="#B6B7B8"
    />
  </svg>
);

export default DoubleExpandIcon;
