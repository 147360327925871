import { SvgIconType } from './svg.interface';

const SearchBtn: SvgIconType = ({ width = 24, height = 24, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="11" cy="11" r="7" stroke="#137BC6" strokeWidth="2" />
    <path
      d="M20 20L17 17"
      stroke="#137BC6"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SearchBtn;
