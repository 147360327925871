import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { URLS } from '../../constants/urls';
import Logo from '../../assets/svg/logo';
import LogoTitle from '../../assets/svg/logoTitle';
import { removeTokenFromLocalStorage } from '../../utils/local-storage-actions';
import {
  AccountingIcon,
  AddRoleIcon,
  ArrowLeft,
  ArrowRight,
  CalendarIcon,
  DashboardIcon,
  Employees,
  GoalsIcon,
  KnowledgeBase,
  LogOutIcon,
  ProjectSpaceIcon,
  SalesPageIcon,
  UserIcon,
  Wishlist,
  ComputerIcon,
} from '../../assets/svg/icons';
import { classNames } from '../../utils/classNames';
import { Text } from '../typography/typography';
import { changeSidebarState, getSidebarState } from '../../store/app/reducer';
import { getCurrentUserSelector, usersApi } from '../../store/user/api';
import { PERMISSION_AREA } from '../../interfaces/permission.interface';
import { useGetPermissionsQuery } from '../../store/permissions/api';
import { FixedTooltip } from '../tooltip/fixed';

import classes from './style.module.scss';

export const SideBar = () => {
  const check = useSelector(getSidebarState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToMain = () => {
    navigate(URLS.dashboard);
  };

  const goTo = (link: string) => {
    navigate(link);
  };

  const logOutHandler = () => {
    removeTokenFromLocalStorage();
    dispatch(usersApi.util.resetApiState()); // reset only users api
    navigate(URLS.auth);
  };

  const user = useSelector(getCurrentUserSelector);
  const navItems = useMemo(
    () => [
      {
        id: 1,
        icon: DashboardIcon,
        link: URLS.dashboard,
        title: 'Dashboard',
        page: PERMISSION_AREA.DashboardPage,
        subLinks: [URLS.post],
      },
      {
        id: 2,
        icon: CalendarIcon,
        link: URLS.calendar,
        title: 'Calendar',
        page: PERMISSION_AREA.CalendarPage,
      },
      {
        id: 3,
        icon: KnowledgeBase,
        link: URLS.knowledgeBase,
        title: 'Knowledge base',
        page: PERMISSION_AREA.KnowledgeBasePage,
        subLinks: [URLS.knowledgeBaseCategory],
      },
      {
        id: 4,
        icon: Wishlist,
        link: URLS.wishlist,
        title: 'Wish List',
        page: PERMISSION_AREA.WishListPage,
      },
      {
        id: 5,
        icon: Employees,
        link: URLS.employees,
        title: 'Employees',
        page: PERMISSION_AREA.EmployeePage,
        subLinks: [URLS.profile],
        subLinksToExclude: [`/profile/${user?.id}`],
      },
      // {
      //   id: 6,
      //   icon: EvaluationIcon,
      //   link: URLS.evaluation,
      //   title: 'Achievements',
      // },
      {
        id: 7,
        icon: UserIcon,
        link: `/profile/${user?.id}`,
        title: 'Account',
        page: '',
      },
      {
        id: 8,
        icon: GoalsIcon,
        link: URLS.faq,
        title: 'FAQ',
        page: PERMISSION_AREA.FAQPage,
      },
      {
        id: 9,
        icon: ProjectSpaceIcon,
        link: URLS.projects,
        title: 'Project space',
        page: PERMISSION_AREA.ProjectSpacePage,
        subLinks: [URLS.project],
      },
      {
        id: 10,
        icon: AccountingIcon,
        link: URLS.accounting,
        title: 'Accounting',
        page: PERMISSION_AREA.AccountingPage,
      },
      {
        id: 11,
        icon: SalesPageIcon,
        link: URLS.sales,
        title: 'Sales',
        page: PERMISSION_AREA.SalesPage,
        subLinks: [
          URLS.contacts,
          URLS.roster,
          URLS.rosterEmployee,
          URLS.contact,
        ],
      },
      {
        id: 12,
        icon: ComputerIcon,
        link: URLS.equipment,
        title: 'Equipment',
        page: PERMISSION_AREA.EquipmentPage,
        subLinks: [URLS.equipmentItem],
      },
      {
        id: 13,
        icon: AddRoleIcon,
        link: URLS.displayRoles,
        title: 'Display Roles',
        page: PERMISSION_AREA.RolePage,
      },
    ],
    [user],
  );

  const { data: permissionsData } = useGetPermissionsQuery({ page: true });

  const navItemsByPermissions = useMemo(
    () =>
      navItems.filter((item) => {
        if (item.page) {
          if (!permissionsData) return false;
          return permissionsData[item.page as PERMISSION_AREA].read;
        }
        return item;
      }),
    [permissionsData, navItems],
  );

  const isMatchedToSubLinks = (
    pathname: string,
    subLinks: string[] | undefined,
    subLinksToExclude: string[] | undefined,
  ) =>
    subLinks &&
    !!subLinks.find((subLink: string) => matchPath(subLink, pathname)) &&
    !subLinksToExclude?.find((subLink: string) => matchPath(subLink, pathname));

  const isMatchedLink = useCallback(
    (navItem: (typeof navItemsByPermissions)[number]) =>
      location.pathname === navItem.link ||
      isMatchedToSubLinks(
        location.pathname,
        navItem.subLinks,
        navItem.subLinksToExclude,
      ),
    [location],
  );

  return (
    <div className={classNames(classes.wrapper__sidebar)}>
      <button
        onClick={() => dispatch(changeSidebarState())}
        className={classNames(classes['round-btn'], {
          [classes['btn-open']]: check,
        })}
      >
        {!check ? <ArrowRight /> : <ArrowLeft />}
      </button>
      <div
        className={classNames(classes.container, classes.container__closed, {
          [classes.container__open]: check,
        })}
      >
        <button
          onClick={goToMain}
          type="button"
          className={classNames(classes.container__logo, classes.btn)}
        >
          <div
            className={classNames(classes.small, { [classes.right]: check })}
          >
            <Logo />
          </div>
          <div
            className={classNames(classes.wide, {
              [classes.wide__none]: !check,
            })}
          >
            <LogoTitle />
          </div>
        </button>
        <ul className={classes.nav}>
          {navItemsByPermissions.map((el) => (
            <li
              key={el.id}
              className={classNames(classes.nav__item, {
                [classes.hover]: check,
              })}
            >
              <div
                className={classNames(classes['side-line'], {
                  [classes['side-line__blue']]: check && isMatchedLink(el),
                })}
              />

              <FixedTooltip
                content={el.title}
                shiftX={-18}
                disabled={check}
                color="grey"
              >
                <div className={classes.small}>
                  <div
                    onClick={() => goTo(el.link)}
                    className={classNames(
                      classes.nav__item__container,
                      classes.nav__item__container__closed,
                      {
                        [classes.nav__item__container__open]: check,
                        [classes.nav__item__container__selected]:
                          isMatchedLink(el),
                        [classes.nav__item__container__sales_icon]:
                          el.link === URLS.sales,
                        [classes.nav__item__container__roles_icon]:
                          el.link === URLS.displayRoles,
                        [classes.nav__item__container__computer_icon]:
                          el.link === URLS.equipment,
                      },
                    )}
                  >
                    <el.icon />
                  </div>
                </div>
              </FixedTooltip>
              <div
                className={classNames(classes.wide, {
                  [classes.wide__none]: !check,
                })}
              >
                <div
                  onClick={() => (check ? goTo(el.link) : null)}
                  className={classNames(classes.titles, {
                    [classes.line]: isMatchedLink(el) && check,
                    [classes.pointer]: check,
                  })}
                >
                  <Text
                    className={classNames(classes.titles__item, {
                      [classes.hover]: check,
                      [classes.selected]: isMatchedLink(el) && check,
                    })}
                    type="text"
                  >
                    {el.title}
                  </Text>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div
          className={classNames(classes['container__log-out'], {
            [classes.hover]: check,
          })}
        >
          <div className={classes['side-line']} />
          <div className={classes.small}>
            <div
              onClick={logOutHandler}
              className={classNames(
                classes.nav__item__container,
                classes.nav__item__container__closed,
                { [classes.nav__item__container__open]: check },
              )}
            >
              <LogOutIcon />
            </div>
          </div>
          <div
            className={classNames(classes.wide, {
              [classes.wide__none]: !check,
            })}
          >
            <div
              className={classNames(classes.titles, {
                [classes.pointer]: check,
              })}
              onClick={check ? logOutHandler : undefined}
            >
              <Text type="text" className={classes.titles__item}>
                Logout
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
