import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 23, height = 23, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.875 9.75C2.875 7.86438 2.875 6.92157 3.46079 6.33579C4.04657 5.75 4.98938 5.75 6.875 5.75H16.125C18.0106 5.75 18.9534 5.75 19.5392 6.33579C20.125 6.92157 20.125 7.86438 20.125 9.75V14.2083C20.125 16.094 20.125 17.0368 19.5392 17.6225C18.9534 18.2083 18.0106 18.2083 16.125 18.2083H6.875C4.98938 18.2083 4.04657 18.2083 3.46079 17.6225C2.875 17.0368 2.875 16.094 2.875 14.2083V9.75Z"
      stroke="#45494E"
      strokeWidth="1.3"
    />
    <path d="M6.70837 14.375H6.71796" stroke="#45494E" strokeLinecap="round" />
    <path
      d="M2.875 10.541L20.125 10.541"
      stroke="#45494E"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);

export default Svg;
