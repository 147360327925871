import { SvgIconType } from './icons/svg.interface';

const Logo: SvgIconType = ({ width = 41, height = 34, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 89 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.7962 14.1681H0.432007V0.399902H64.0577V14.1681H40.6935V73.413H23.7962V14.1681Z"
      fill="#137BC6"
    />
    <path
      d="M88.022 59.6448V73.413H24.1878V62.461L64.2406 14.1681H25.0222V0.399902H86.4575V11.3519L46.5089 59.6448H88.022Z"
      fill="#137BC6"
    />
  </svg>
);

export default Logo;
