import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { IResponseMessage, PageDto } from '../../interfaces/common.interface';
import {
  CreateTimelineEventCommentDto,
  CreateTimelineEventDto,
  DeleteTimelineEventCommentDto,
  GetTimelineEventsParams,
  ITimelineEvent,
  UpdateTimelineEventCommentDto,
} from '../../interfaces/timeline.interface';
import { onQueryStartedExtended } from '../../utils/query';

const createArgument = (
  arg: Partial<GetTimelineEventsParams>,
  asParams: boolean = false,
): string | Record<string, string> => {
  if (asParams) {
    return arg.sales_contact
      ? { sales_contact: arg.sales_contact }
      : { roster_employee: String(arg.roster_employee) };
  }

  return arg.sales_contact
    ? `sales_contact_${arg.sales_contact}`
    : `roster_employee_${arg.roster_employee}`;
};

export const timelineApi = createApi({
  reducerPath: 'timelineApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Timeline'],
  endpoints: (builder) => ({
    getTimelineEvents: builder.query<
      PageDto<ITimelineEvent>,
      GetTimelineEventsParams
    >({
      query: (params) => ({ url: '/timeline', params }),
      serializeQueryArgs: ({ queryArgs }) => createArgument(queryArgs),
      merge: (currentCache, newItems, other) => {
        if (
          currentCache?.meta?.sort !== other.arg.sort ||
          currentCache?.meta?.q !== other.arg.q
        ) {
          return newItems;
        }

        if (currentCache) {
          const { data } = currentCache;
          const ids = new Set(data.map((d) => d.id));
          return {
            data: data.concat([...newItems.data.filter((d) => !ids.has(d.id))]),
            meta: newItems.meta,
          };
        }
        return newItems;
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      providesTags: [{ type: 'Timeline', id: 'LIST' }],
    }),

    createTimelineEvent: builder.mutation<
      ITimelineEvent,
      CreateTimelineEventDto
    >({
      query: (data) => ({
        url: `/timeline`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended<ITimelineEvent>(
          queryFulfilled,
          dispatch,
          {
            successMessage: 'Note created successfully',
            errorMessage: 'Note creation error',
          },
        );

        if (result && typeof result !== 'boolean') {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              { sales_contact: String(result.sales_contact?.id) },
              (draft) => {
                draft.data.unshift(result);
                Object.assign(draft.meta, {
                  itemCount: draft.meta.itemCount + 1,
                });
              },
            ),
          );
        }
      },
      invalidatesTags: ['Timeline'],
      transformResponse: (raw: { data: ITimelineEvent }) => raw.data,
    }),

    createTimelineEventComment: builder.mutation<
      ITimelineEvent,
      CreateTimelineEventCommentDto
    >({
      query: ({ eventId, text }) => {
        return {
          url: `/timeline/${eventId}/comment`,
          method: 'POST',
          data: { text },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Comment created successfully',
          errorMessage: 'Comment creation error',
        });

        if (result && typeof result !== 'boolean') {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              result.sales_contact
                ? { sales_contact: String(result.sales_contact?.id) }
                : { roster_employee: String(result.roster_employee?.id) },
              (draft) => {
                draft.data.forEach((el) => {
                  if (el.id === result.id) {
                    Object.assign(el, result);
                  }
                });
              },
            ),
          );
        }
      },
      transformResponse: (raw: { data: ITimelineEvent }) => raw.data,
    }),

    deleteTimelineEventComment: builder.mutation<
      IResponseMessage,
      DeleteTimelineEventCommentDto
    >({
      query: ({ eventId, commentId }) => {
        return {
          url: `/timeline/${eventId}/comment/${commentId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Comment removed successfully',
          errorMessage: 'Comment removing error',
        });

        if (result) {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              createArgument(arg, true) as GetTimelineEventsParams,
              (draft) => {
                draft.data.forEach((el) => {
                  if (el.id === arg.eventId) {
                    const i = el.comments.findIndex(
                      (c) => c.id === arg.commentId,
                    );
                    el.comments.splice(i, 1);
                  }
                });
              },
            ),
          );
        }
      },
    }),

    updateTimelineEventComment: builder.mutation<
      IResponseMessage,
      UpdateTimelineEventCommentDto
    >({
      query: ({ eventId, commentId, text }) => {
        return {
          url: `/timeline/${eventId}/comment/${commentId}`,
          method: 'PATCH',
          data: { text },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Comment updated successfully',
          errorMessage: 'Comment updating error',
        });

        if (result) {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              createArgument(arg, true) as GetTimelineEventsParams,
              (draft) => {
                draft.data.forEach((el) => {
                  if (el.id === arg.eventId) {
                    el.comments.forEach((c) => {
                      if (c.id === arg.commentId) {
                        Object.assign(c, { text: arg.text });
                      }
                    });
                  }
                });
              },
            ),
          );
        }
      },
    }),
  }),
});

export const {
  useGetTimelineEventsQuery,
  useCreateTimelineEventMutation,
  useCreateTimelineEventCommentMutation,
  useUpdateTimelineEventCommentMutation,
  useDeleteTimelineEventCommentMutation,
} = timelineApi;
