import { SvgIconType } from './svg.interface';

const ItalicIcon: SvgIconType = ({ width = 18, height = 18, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_62)">
      <path
        d="M11.25 15H5.25V13.5H7.44525L9.03225 4.5H6.75V3H12.75V4.5H10.5547L8.96775 13.5H11.25V15Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_62">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ItalicIcon;
