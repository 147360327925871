import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({
  width = 5,
  height = 9,
  className,
  color = 'white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.988261 0.172491C0.880629 0.0608786 0.743642 -1.11631e-06 0.582194 -1.15865e-06C0.259298 -1.24334e-06 2.29123e-06 0.26381 2.20341e-06 0.598646C2.1595e-06 0.766064 0.0684955 0.918263 0.18102 1.03495L3.60568 4.50507L0.181018 7.96505C0.0684936 8.08173 1.99584e-07 8.23901 1.57006e-07 8.40135C6.91892e-08 8.73619 0.259295 9 0.582192 9C0.74364 9 0.880626 8.93912 0.988258 8.82751L4.79452 4.97182C4.93151 4.83991 4.99511 4.67757 5 4.5C5 4.32244 4.93151 4.17024 4.79452 4.03326L0.988261 0.172491Z"
      fill={color}
    />
  </svg>
);

export default Svg;
