import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import { IResponseMessage } from '../../interfaces/common.interface';
import {
  AcceptVacationDto,
  CreateVacationDto,
  RejectVacationDto,
  RequestVacationDto,
} from '../../interfaces/calendar.interface';
import { usersApi } from '../user/api';
import { servicesApi } from '../services/api';

export const vacationApi = createApi({
  reducerPath: 'vacationApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Services'],
  endpoints: (builder) => ({
    requestVacation: builder.mutation<IResponseMessage, RequestVacationDto>({
      query: (data) => ({
        url: '/vacation/request',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const confirmMessage = 'You have requested more';

          if (!data.message.includes(confirmMessage)) {
            dispatch(
              addNotification({
                title: data.message,
                type: 'success',
              }),
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;

          dispatch(
            addNotification({
              title: data.message || 'Error requesting day off',
              type: 'error',
            }),
          );
        }
      },
    }),

    rejectVacationRequest: builder.mutation<
      IResponseMessage,
      RejectVacationDto
    >({
      query: (data) => ({
        url: '/vacation/reject',
        method: 'POST',
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            addNotification({
              title: data.message,
              type: data.message.includes('successfully') ? 'success' : 'error',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Error rejecting day off',
              type: 'error',
            }),
          );
        }
      },
    }),

    acceptVacationRequest: builder.mutation<
      IResponseMessage,
      AcceptVacationDto
    >({
      query: (data) => ({
        url: `/vacation/accept`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            usersApi.util.invalidateTags([{ type: 'User', id: 'currentUser' }]),
          );

          dispatch(
            addNotification({
              title: data.message || 'Event created',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message || 'Error creating event',
              type: 'error',
            }),
          );
        }
      },
    }),
    createVacationByAdmin: builder.mutation<
      IResponseMessage,
      CreateVacationDto
    >({
      query: (data) => ({
        url: `/vacation/create`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            usersApi.util.invalidateTags([{ type: 'User', id: 'currentUser' }]),
          );

          dispatch(servicesApi.util.invalidateTags(['Events']));
          dispatch(
            addNotification({
              title: data.message || 'Event created',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message || 'Error creating event',
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useRequestVacationMutation,
  useRejectVacationRequestMutation,
  useAcceptVacationRequestMutation,
  useCreateVacationByAdminMutation,
} = vacationApi;
