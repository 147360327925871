import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import initialState, { CommentInteraction } from '../initial-store';

interface PostState {
  commentId: string | null;
  commentInteraction: CommentInteraction;
}

const initialPostState: PostState = initialState.post;

const postSlice = createSlice({
  name: 'post',
  initialState: { ...initialPostState },
  reducers: {
    selectComment(state, { payload }: PayloadAction<PostState>) {
      if (
        state.commentId !== payload.commentId ||
        state.commentInteraction !== payload.commentInteraction
      ) {
        return payload;
      }
      return {
        commentId: payload.commentId,
        commentInteraction: CommentInteraction.None,
      };
    },
    unselectComment() {
      return initialPostState;
    },
  },
});

export const { selectComment, unselectComment } = postSlice.actions;

export default postSlice.reducer;

const selectSelf = (state: RootState) => state;

export const getSelectedCommentIdState = createSelector(
  selectSelf,
  (state: RootState) => state.post.commentId,
);

export const getSelectedCommentInteractionState = createSelector(
  selectSelf,
  (state: RootState) => state.post.commentInteraction,
);
