import { SvgIconType } from './svg.interface';

const LinkIcon: SvgIconType = ({ width = 18, height = 18, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_95)">
      <path
        d="M13.773 11.652L12.7125 10.59L13.773 9.5295C14.1237 9.18184 14.4023 8.76832 14.5928 8.3127C14.7833 7.85707 14.8819 7.36831 14.883 6.87447C14.8841 6.38064 14.7876 5.89145 14.5991 5.43499C14.4106 4.97854 14.1338 4.56381 13.7846 4.21461C13.4355 3.86541 13.0207 3.58863 12.5643 3.40014C12.1078 3.21166 11.6186 3.11519 11.1248 3.11627C10.6309 3.11735 10.1422 3.21596 9.68656 3.40645C9.23094 3.59693 8.81742 3.87553 8.46976 4.22625L7.40926 5.2875L6.34801 4.227L7.41001 3.1665C8.39462 2.18188 9.73005 1.62873 11.1225 1.62873C12.515 1.62873 13.8504 2.18188 14.835 3.1665C15.8196 4.15112 16.3728 5.48654 16.3728 6.879C16.3728 8.27146 15.8196 9.60688 14.835 10.5915L13.7738 11.652H13.773ZM11.652 13.773L10.5908 14.8335C9.60614 15.8181 8.27072 16.3713 6.87826 16.3713C5.4858 16.3713 4.15037 15.8181 3.16576 14.8335C2.18114 13.8489 1.62799 12.5135 1.62799 11.121C1.62799 9.72854 2.18114 8.39312 3.16576 7.4085L4.22701 6.348L5.28751 7.41L4.22701 8.4705C3.87629 8.81816 3.59769 9.23168 3.4072 9.6873C3.21672 10.1429 3.11811 10.6317 3.11703 11.1255C3.11595 11.6194 3.21242 12.1086 3.4009 12.565C3.58939 13.0215 3.86617 13.4362 4.21537 13.7854C4.56457 14.1346 4.9793 14.4114 5.43575 14.5999C5.89221 14.7883 6.38139 14.8848 6.87523 14.8837C7.36907 14.8826 7.85783 14.784 8.31346 14.5936C8.76908 14.4031 9.18259 14.1245 9.53026 13.7737L10.5908 12.7133L11.652 13.7737V13.773ZM11.121 5.81775L12.1823 6.879L6.87901 12.1815L5.81776 11.121L11.121 5.8185V5.81775Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_95">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkIcon;
