import { SvgIconType } from './svg.interface';

const FrontendIcon: SvgIconType = ({ width = 54, height = 80, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 54 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M37.0003 0H17.0003C12.5816 0.00529286 8.34548 1.76294 5.22102 4.8874C2.09656 8.01186 0.338911 12.248 0.333618 16.6667V63.3333C0.338911 67.752 2.09656 71.9881 5.22102 75.1126C8.34548 78.2371 12.5816 79.9947 17.0003 80H37.0003C41.4189 79.9947 45.6551 78.2371 48.7796 75.1126C51.904 71.9881 53.6617 67.752 53.667 63.3333V16.6667C53.6617 12.248 51.904 8.01186 48.7796 4.8874C45.6551 1.76294 41.4189 0.00529286 37.0003 0V0ZM17.0003 6.66667H37.0003C39.6525 6.66667 42.196 7.72024 44.0714 9.5956C45.9467 11.471 47.0003 14.0145 47.0003 16.6667V53.3333H7.00028V16.6667C7.00028 14.0145 8.05385 11.471 9.92922 9.5956C11.8046 7.72024 14.3481 6.66667 17.0003 6.66667ZM37.0003 73.3333H17.0003C14.3481 73.3333 11.8046 72.2798 9.92922 70.4044C8.05385 68.529 7.00028 65.9855 7.00028 63.3333V60H47.0003V63.3333C47.0003 65.9855 45.9467 68.529 44.0714 70.4044C42.196 72.2798 39.6525 73.3333 37.0003 73.3333Z"
      fill="white"
    />
  </svg>
);

export default FrontendIcon;
