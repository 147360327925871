import { SvgIconType } from './svg.interface';

const ExpandArrowDown: SvgIconType = ({
  width = 16,
  height = 16,
  className,
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.6933 5.10019C15.8918 4.89354 16 4.63052 16 4.32054C16 3.70058 15.531 3.20274 14.9357 3.20274C14.6381 3.20274 14.3675 3.33424 14.1601 3.55029L7.99098 10.1256L1.83991 3.55029C1.63247 3.33424 1.35287 3.20274 1.06426 3.20274C0.468996 3.20274 -3.97868e-07 3.70058 -3.70768e-07 4.32054C-3.57219e-07 4.63052 0.10823 4.89354 0.306651 5.10019L7.16122 12.4082C7.39572 12.6712 7.68433 12.7933 8 12.8027C8.31567 12.8027 8.58625 12.6712 8.82976 12.4082L15.6933 5.10019Z"
      fill="#137BC6"
    />
  </svg>
);

export default ExpandArrowDown;
