import { SvgIconType } from './svg.interface';

const ListUnorderedIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_68)">
      <path
        d="M6 3H15.75V4.5H6V3ZM3.375 4.875C3.07663 4.875 2.79048 4.75647 2.5795 4.5455C2.36853 4.33452 2.25 4.04837 2.25 3.75C2.25 3.45163 2.36853 3.16548 2.5795 2.9545C2.79048 2.74353 3.07663 2.625 3.375 2.625C3.67337 2.625 3.95952 2.74353 4.1705 2.9545C4.38147 3.16548 4.5 3.45163 4.5 3.75C4.5 4.04837 4.38147 4.33452 4.1705 4.5455C3.95952 4.75647 3.67337 4.875 3.375 4.875ZM3.375 10.125C3.07663 10.125 2.79048 10.0065 2.5795 9.7955C2.36853 9.58452 2.25 9.29837 2.25 9C2.25 8.70163 2.36853 8.41548 2.5795 8.2045C2.79048 7.99353 3.07663 7.875 3.375 7.875C3.67337 7.875 3.95952 7.99353 4.1705 8.2045C4.38147 8.41548 4.5 8.70163 4.5 9C4.5 9.29837 4.38147 9.58452 4.1705 9.7955C3.95952 10.0065 3.67337 10.125 3.375 10.125ZM3.375 15.3C3.07663 15.3 2.79048 15.1815 2.5795 14.9705C2.36853 14.7595 2.25 14.4734 2.25 14.175C2.25 13.8766 2.36853 13.5905 2.5795 13.3795C2.79048 13.1685 3.07663 13.05 3.375 13.05C3.67337 13.05 3.95952 13.1685 4.1705 13.3795C4.38147 13.5905 4.5 13.8766 4.5 14.175C4.5 14.4734 4.38147 14.7595 4.1705 14.9705C3.95952 15.1815 3.67337 15.3 3.375 15.3ZM6 8.25H15.75V9.75H6V8.25ZM6 13.5H15.75V15H6V13.5Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_68">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ListUnorderedIcon;
