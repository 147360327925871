import { SvgIconType } from './svg.interface';

const ComputerIcon: SvgIconType = ({ width = 23, height = 23, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1545_12183)">
      <path
        d="M13 2.75H1C0.723858 2.75 0.5 2.97386 0.5 3.25V11.25C0.5 11.5261 0.723858 11.75 1 11.75H13C13.2761 11.75 13.5 11.5261 13.5 11.25V3.25C13.5 2.97386 13.2761 2.75 13 2.75Z"
        stroke="#45494E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11.75L5 14.25"
        stroke="#45494E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.75L9 14.25"
        stroke="#45494E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14.25H10"
        stroke="#45494E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ComputerIcon;
