import { SvgIconType } from './svg.interface';

const HighLightIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
  color = '#45494E',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5_53)">
      <path
        d="M11.4322 3.38625L6.37875 8.439L5.8485 10.0298L5.0685 10.8105L7.1895 12.9323L7.9695 12.1515L9.561 11.6213L14.6137 6.56775L11.4322 3.38625V3.38625ZM16.2052 6.0375C16.3459 6.17815 16.4248 6.36888 16.4248 6.56775C16.4248 6.76662 16.3459 6.95735 16.2052 7.098L10.371 12.9323L8.781 13.4625L7.71975 14.523C7.5791 14.6636 7.38837 14.7426 7.1895 14.7426C6.99063 14.7426 6.7999 14.6636 6.65925 14.523L3.477 11.3408C3.3364 11.2001 3.25741 11.0094 3.25741 10.8105C3.25741 10.6116 3.3364 10.4209 3.477 10.2803L4.5375 9.21975L5.06775 7.629L10.902 1.79475C11.0426 1.65415 11.2334 1.57516 11.4322 1.57516C11.6311 1.57516 11.8219 1.65415 11.9625 1.79475L16.2052 6.0375V6.0375ZM11.4322 5.50725L12.4927 6.56775L8.78025 10.2803L7.71975 9.21975L11.4322 5.50725V5.50725ZM3.21225 12.6675L5.33325 14.7893L4.27275 15.8498L1.0905 14.7893L3.2115 12.6675H3.21225Z"
        fill={`${color}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_5_53">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HighLightIcon;
