import { createApi } from '@reduxjs/toolkit/query/react';

import { Category, WithCount } from '../../interfaces/category.interface';
import { axiosBaseQuery } from '../../utils/axios';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Category'],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params: { query?: string }) => ({ url: '/category', params }),
      transformResponse: (response: WithCount<Category>) => {
        return response;
      },
      providesTags: ['Category'],
    }),
  }),
});

export const { useGetCategoriesQuery, useLazyGetCategoriesQuery } = categoryApi;
