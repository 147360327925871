import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../utils/axios';
import {
  TGetFAQListResponse,
  IFaq,
  ICreateFAQ,
  IPatchFAQRequest,
} from '../../interfaces/faq.interface';
import { IQueryParams } from '../../interfaces/common.interface';
import { onQueryStartedExtended } from '../../utils/query';

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FAQ'],
  endpoints: (builder) => ({
    getFAQList: builder.query<TGetFAQListResponse, IQueryParams>({
      query: (params) => ({ url: '/faq', params }),
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'FAQ', id })) : ['FAQ'],
    }),
    createFAQ: builder.mutation<IFaq, ICreateFAQ>({
      query: (data) => {
        return {
          url: '/faq',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => ['FAQ'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await onQueryStartedExtended<IFaq>(queryFulfilled, dispatch, {
          successMessage: 'FAQ created successfully',
          errorMessage: 'FAQ creating error',
        });
      },
    }),

    updateFAQ: builder.mutation<IFaq, IPatchFAQRequest>({
      query: (data) => ({
        url: `/faq/${data.id}`,
        method: 'PATCH',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await onQueryStartedExtended<IFaq>(queryFulfilled, dispatch, {
          successMessage: 'FAQ updated successfully',
          errorMessage: 'FAQ updating error',
        });
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'FAQ', id }],
    }),
    deleteFAQ: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => {
        return {
          url: `/faq/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['FAQ'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'FAQ removed successfully',
          errorMessage: 'FAQ removing error',
        });
      },
    }),
  }),
});

export const {
  useGetFAQListQuery,
  useCreateFAQMutation,
  useUpdateFAQMutation,
  useDeleteFAQMutation,
} = faqApi;
