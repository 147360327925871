import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7785 5.81768C13.4082 4.43556 12.5921 3.21426 11.457 2.3432C10.3218 1.47214 8.93087 1 7.5 1C6.06913 1 4.67824 1.47214 3.54305 2.3432C2.40786 3.21426 1.59182 4.43556 1.22148 5.81768"
      stroke="#137BC6"
      strokeWidth="1.3"
    />
    <path
      d="M7.5 6L7.90297 5.48999L7.5 5.17159L7.09703 5.48999L7.5 6ZM6.85 14C6.85 14.359 7.14101 14.65 7.5 14.65C7.85898 14.65 8.15 14.359 8.15 14L6.85 14ZM12.403 9.04554L7.90297 5.48999L7.09703 6.51001L11.597 10.0656L12.403 9.04554ZM7.09703 5.48999L2.59703 9.04554L3.40297 10.0656L7.90297 6.51001L7.09703 5.48999ZM6.85 6L6.85 14L8.15 14L8.15 6L6.85 6Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
