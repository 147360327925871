import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './app/reducer';
import postReduce from './post/reducer';
import dashboardReducer from './dashboard/reducer';

import { categoryApi } from './category/api';
import { usersApi } from './user/api';
import { postApi } from './post/api';
import { widgetsApi } from './widgets/api';
import { wishlistApi } from './wishlist/api';
import { positionApi } from './position/api';
import { knowledgeBaseApi } from './knowledge-base/api';
import { reportApi } from './report/api';
import { fileApi } from './file/api';
import { projectApi } from './project/api';
import { roleApi } from './role/api';
import { technologyApi } from './technology/api';
import { salesContactApi } from './sales-contact/api';
import { salesRosterApi } from './roster-employee/api';
import { rosterProjectApi } from './roster-project/api';
import { permissionsApi } from './permissions/api';
import { timelineApi } from './timeline/api';
import { servicesApi } from './services/api';
import { vacationApi } from './vacation/api';
import { faqApi } from './faq/api';
import { equipmentApi } from './equipment/api';

const rootReducer = combineReducers({
  app: appReducer,
  dashboard: dashboardReducer,
  post: postReduce,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [widgetsApi.reducerPath]: widgetsApi.reducer,
  [wishlistApi.reducerPath]: wishlistApi.reducer,
  [positionApi.reducerPath]: positionApi.reducer,
  [knowledgeBaseApi.reducerPath]: knowledgeBaseApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [technologyApi.reducerPath]: technologyApi.reducer,
  [salesContactApi.reducerPath]: salesContactApi.reducer,
  [salesRosterApi.reducerPath]: salesRosterApi.reducer,
  [rosterProjectApi.reducerPath]: rosterProjectApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [timelineApi.reducerPath]: timelineApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [vacationApi.reducerPath]: servicesApi.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [equipmentApi.reducerPath]: equipmentApi.reducer,
});

export default rootReducer;
