import { lazy, ComponentType, LazyExoticComponent } from 'react';

import { URLS } from '../constants/urls';
import { PERMISSION_AREA } from '../interfaces/permission.interface';

export interface ROUTE_ITEM {
  id: number | string;
  path: string;
  protected: boolean;
  page: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: LazyExoticComponent<ComponentType<any>>;
  layoutType?: 'base' | 'simple';
}

/**
 * when you add new route, it should use lazy from React
 *
 * path - [string] which user will see in url
 * exact - react-router-dom property for replace all (see doc for get more detail)
 * protected - boolean value which detect should user be authorized for access
 * accessConfig - [object | null] if value is 'null' that means it public route,
 * ctx - [object] context object will be passed to component
 * component - [ReactNode] component which will be rendered
 * layoutType - type of used layout
 */

export const ROUTES: ROUTE_ITEM[] = [
  {
    id: 1,
    path: URLS.dashboard,
    page: '',
    protected: true,
    component: lazy(() => import('./dashboard/dashboard')),
  },
  {
    id: 2,
    path: URLS.auth,
    page: '',
    protected: false,
    layoutType: 'simple',
    component: lazy(() => import('./auth/auth')),
  },
  {
    id: 3,
    path: URLS.googleAuth,
    page: '',
    protected: false,
    component: lazy(() => import('./auth/google-auth')),
  },
  {
    id: 4,
    path: URLS.profile,
    page: '',
    protected: true,
    component: lazy(() => import('./profile')),
  },
  {
    id: 5,
    path: URLS.calendar,
    page: PERMISSION_AREA.CalendarPage,
    protected: true,
    component: lazy(() => import('./calendar')),
  },
  {
    id: 6,
    path: URLS.employees,
    page: PERMISSION_AREA.EmployeePage,
    protected: true,
    component: lazy(() => import('./employees')),
  },
  {
    id: 7,
    path: URLS.faq,
    page: '',
    protected: true,
    component: lazy(() => import('./faq/faq')),
  },
  {
    id: 8,
    path: URLS.post,
    page: PERMISSION_AREA.DashboardPage,
    protected: true,
    component: lazy(() => import('./post-details/post-details')),
  },
  {
    id: 9,
    path: URLS.knowledgeBase,
    page: PERMISSION_AREA.KnowledgeBasePage,
    protected: true,
    component: lazy(() => import('./knowledge-base/knowledge-base')),
  },
  {
    id: 10,
    path: URLS.knowledgeBaseCategory,
    page: PERMISSION_AREA.KnowledgeBasePage,
    protected: true,
    component: lazy(() => import('./kb-category/kb-category')),
  },
  {
    id: 11,
    path: URLS.wishlist,
    page: PERMISSION_AREA.WishListPage,
    protected: true,
    component: lazy(() => import('./wishlist/wishlist')),
  },
  {
    id: 12,
    path: URLS.projects,
    page: PERMISSION_AREA.ProjectSpacePage,
    protected: true,
    component: lazy(() => import('./projects/project-space')),
  },
  {
    id: 13,
    path: URLS.project,
    page: PERMISSION_AREA.ProjectSpacePage,
    protected: true,
    component: lazy(() => import('./projects/project-page/project-page')),
  },
  {
    id: 14,
    path: URLS.accounting,
    page: PERMISSION_AREA.AccountingPage,
    protected: true,
    component: lazy(() => import('./accounting/accounting')),
  },
  {
    id: 15,
    path: URLS.requestVacation,
    page: PERMISSION_AREA.CalendarPage,
    protected: true,
    component: lazy(
      () => import('./request-vacation-page/request-vacation-page'),
    ),
  },
  {
    id: 16,
    path: URLS.sales,
    page: PERMISSION_AREA.SalesPage,
    protected: true,
    component: lazy(() => import('./sales/sales')),
  },
  {
    id: 17,
    path: URLS.contacts,
    page: PERMISSION_AREA.SalesContactsSubpage,
    protected: true,
    component: lazy(() => import('./sales/sales-contacts')),
  },
  {
    id: 18,
    path: URLS.roster,
    page: PERMISSION_AREA.SalesRosterSubpage,
    protected: true,
    component: lazy(() => import('./sales/sales-roster')),
  },
  {
    id: 19,
    path: URLS.contact,
    page: PERMISSION_AREA.SalesContactRecordSubpage,
    protected: true,
    component: lazy(() => import('./sales/contact-record')),
  },
  {
    id: 20,
    path: URLS.displayRoles,
    page: PERMISSION_AREA.RolePage,
    protected: true,
    component: lazy(() => import('./display-roles')),
  },
  {
    id: 21,
    path: URLS.rosterEmployee,
    page: PERMISSION_AREA.SalesRosterSubpage,
    protected: true,
    component: lazy(() => import('./sales/roster-employee')),
  },
  {
    id: 22,
    path: URLS.privacyPolicy,
    page: '',
    protected: false,
    layoutType: 'simple',
    component: lazy(() => import('./privacy-policy/privacy-policy')),
  },
  {
    id: 23,
    path: URLS.equipment,
    page: PERMISSION_AREA.EquipmentPage,
    protected: true,
    component: lazy(() => import('./equipment/equipment-main-page')),
  },
  {
    id: 24,
    path: URLS.equipmentItem,
    page: PERMISSION_AREA.EquipmentPage,
    protected: true,
    component: lazy(() => import('./equipment/equipment-item-page')),
  },
  {
    id: 1005001,
    path: URLS.error,
    page: '',
    protected: true,
    component: lazy(() =>
      import('./error-page/error-page').then((module) => ({
        default: module.ErrorPage,
      })),
    ),
  },
  {
    id: 1005002,
    path: '*',
    page: '',
    protected: true,
    component: lazy(() =>
      import('./error-page/error-page').then((module) => ({
        default: module.ErrorPage,
      })),
    ),
  },
];
