export const URLS = Object.freeze({
  dashboard: '/',
  post: '/post/:postId',
  auth: '/auth',
  googleAuth: '/google-auth',
  error: '/error',
  profile: '/profile/:userId',
  knowledgeBase: '/knowledge-base',
  knowledgeBaseCategory: '/knowledge-base/:categoryId/*',
  projects: '/projects',
  project: '/projects/:projectId',
  employees: '/employees',
  wishlist: '/wishlist',
  calendar: '/calendar',
  goals: '/goals', // <- not exist yet
  evaluation: '/evaluation', // <- not exist yet
  faq: '/faq',
  accounting: '/accounting',
  requestVacation: '/result-of-request-vacation',
  sales: '/sales',
  contacts: '/contacts',
  roster: '/roster',
  contact: '/contacts/contact/:contactId',
  displayRoles: '/display-roles',
  rosterEmployee: '/roster/roster-employee/:employeeId',
  privacyPolicy: '/privacy-policy',
  equipment: '/equipment',
  equipmentItem: '/equipment/:equipmentId',
});
