import { SvgIconType } from './icons/svg.interface';

const LogoTitle: SvgIconType = ({ width = 112, height = 17, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 112 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.51984 15.0444H7.5849V2.25771H12.5911V0.442952H0.513613V2.25771H5.51984V15.0444ZM23.4218 9.57931C23.4218 6.17925 21.1481 3.88473 17.9567 3.88473C14.7652 3.88473 12.4081 6.24183 12.4081 9.51673C12.4081 12.8125 14.7861 15.1696 18.353 15.1696C20.1886 15.1696 21.7113 14.5438 22.7126 13.3757L21.607 12.0824C20.7935 12.9794 19.7089 13.4174 18.4156 13.4174C16.2254 13.4174 14.6609 12.1241 14.4106 10.1842H23.3801C23.4009 9.99649 23.4218 9.74618 23.4218 9.57931ZM17.9567 5.57433C19.8966 5.57433 21.2942 6.88846 21.5028 8.74494H14.4106C14.6192 6.8676 16.0376 5.57433 17.9567 5.57433ZM29.9227 3.88473C28.1497 3.88473 26.5018 4.38535 25.3545 5.30316L26.1889 6.80503C27.0441 6.07495 28.3791 5.61605 29.6933 5.61605C31.6749 5.61605 32.6553 6.59643 32.6553 8.28603V8.68236H29.4847C26.1889 8.68236 25.0417 10.1425 25.0417 11.9155C25.0417 13.8346 26.627 15.1696 29.1301 15.1696C30.8614 15.1696 32.0921 14.5855 32.7596 13.5843V15.0444H34.6578V8.36947C34.6578 5.34488 32.9473 3.88473 29.9227 3.88473ZM29.4638 13.626C27.9411 13.626 27.0233 12.9376 27.0233 11.8321C27.0233 10.8934 27.5865 10.1216 29.5681 10.1216H32.6553V11.6652C32.1547 12.9376 30.9657 13.626 29.4638 13.626ZM52.1932 3.88473C50.3159 3.88473 48.7723 4.67738 47.938 5.92894C47.2079 4.55223 45.7894 3.88473 44.0998 3.88473C42.4311 3.88473 41.0753 4.51051 40.2826 5.63691V3.98903H38.3636V15.0444H40.366V9.34986C40.366 6.95104 41.6802 5.67863 43.6827 5.67863C45.4974 5.67863 46.5404 6.74245 46.5404 8.91181V15.0444H48.5429V9.34986C48.5429 6.95104 49.857 5.67863 51.8595 5.67863C53.6742 5.67863 54.7172 6.74245 54.7172 8.91181V15.0444H56.7197V8.68236C56.7197 5.42831 54.8841 3.88473 52.1932 3.88473Z"
      fill="#137BC6"
    />
    <path
      d="M62.1982 13.2297L71.3345 1.86138V0.442952H59.5491V2.25771H68.5811L59.403 13.626V15.0444H71.6057V13.2297H62.1982ZM78.6042 15.1696C81.9208 15.1696 84.3405 12.8125 84.3405 9.51673C84.3405 6.22097 81.9208 3.88473 78.6042 3.88473C75.2875 3.88473 72.847 6.22097 72.847 9.51673C72.847 12.8125 75.2875 15.1696 78.6042 15.1696ZM78.6042 13.4174C76.4765 13.4174 74.8704 11.8738 74.8704 9.51673C74.8704 7.15963 76.4765 5.63691 78.6042 5.63691C80.7318 5.63691 82.3171 7.15963 82.3171 9.51673C82.3171 11.8738 80.7318 13.4174 78.6042 13.4174ZM93.013 3.88473C91.2608 3.88473 89.8424 4.53137 89.0289 5.65777V3.98903H87.1098V15.0444H89.1123V9.34986C89.1123 6.95104 90.489 5.67863 92.6167 5.67863C94.5149 5.67863 95.5996 6.74245 95.5996 8.91181V15.0444H97.6021V8.68236C97.6021 5.42831 95.7039 3.88473 93.013 3.88473ZM111.361 9.57931C111.361 6.17925 109.087 3.88473 105.896 3.88473C102.704 3.88473 100.347 6.24183 100.347 9.51673C100.347 12.8125 102.725 15.1696 106.292 15.1696C108.128 15.1696 109.65 14.5438 110.651 13.3757L109.546 12.0824C108.732 12.9794 107.648 13.4174 106.354 13.4174C104.164 13.4174 102.6 12.1241 102.349 10.1842H111.319C111.34 9.99649 111.361 9.74618 111.361 9.57931ZM105.896 5.57433C107.835 5.57433 109.233 6.88846 109.442 8.74494H102.349C102.558 6.8676 103.977 5.57433 105.896 5.57433Z"
      fill="#45494E"
    />
  </svg>
);

export default LogoTitle;
