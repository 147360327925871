import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './pages/app/app';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './utils/sentry.config';

// styles
import './styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/assets/index.css';

initSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="...loading">
        <Router>
          <App />
        </Router>
      </Suspense>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
