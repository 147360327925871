import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 16, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect
      x="5.76318"
      y="0.5"
      width="9.52632"
      height="14.7895"
      rx="0.5"
      stroke="#137BC6"
    />

    <rect
      x="0.5"
      y="4.71045"
      width="9.52632"
      height="14.7895"
      rx="0.5"
      stroke="#137BC6"
    />
  </svg>
);

export default Svg;
