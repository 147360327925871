/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef, MouseEventHandler } from 'react';

import { Text } from '../typography/typography';
import { classNames } from '../../utils/classNames';

import classes from './notification.module.scss';

export type NotificationTypes = 'warning' | 'success' | 'error' | 'info';

const config = {
  info: {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47254 0 0 4.47293 0 10C0 15.5275 4.47293 20 10 20C15.5275 20 20 15.5271 20 10C20 4.47254 15.527 0 10 0ZM10 18.4375C5.33621 18.4375 1.5625 14.6635 1.5625 10C1.5625 5.33621 5.33652 1.5625 10 1.5625C14.6638 1.5625 18.4375 5.33652 18.4375 10C18.4375 14.6638 14.6634 18.4375 10 18.4375Z"
          fill="white"
        />
        <path
          d="M10 8.37207C9.56852 8.37207 9.21875 8.72184 9.21875 9.15332V14.1843C9.21875 14.6158 9.56852 14.9655 10 14.9655C10.4315 14.9655 10.7812 14.6157 10.7812 14.1843V9.15332C10.7812 8.72184 10.4315 8.37207 10 8.37207Z"
          fill="white"
        />
        <path
          d="M10 7.41455C10.5825 7.41455 11.0547 6.94235 11.0547 6.35986C11.0547 5.77738 10.5825 5.30518 10 5.30518C9.41751 5.30518 8.94531 5.77738 8.94531 6.35986C8.94531 6.94235 9.41751 7.41455 10 7.41455Z"
          fill="white"
        />
      </svg>
    ),
  },
  warning: {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47254 0 0 4.47293 0 10C0 15.5275 4.47293 20 10 20C15.5275 20 20 15.5271 20 10C20 4.47254 15.527 0 10 0ZM10 18.4375C5.33621 18.4375 1.5625 14.6635 1.5625 10C1.5625 5.33621 5.33652 1.5625 10 1.5625C14.6638 1.5625 18.4375 5.33652 18.4375 10C18.4375 14.6638 14.6634 18.4375 10 18.4375Z"
          fill="white"
        />
        <path
          d="M10 11.8984C10.4315 11.8984 10.7813 11.5487 10.7813 11.1172L10.7813 6.08621C10.7813 5.65473 10.4315 5.30496 10 5.30496C9.56852 5.30496 9.21875 5.65477 9.21875 6.08625L9.21875 11.1172C9.21875 11.5487 9.56852 11.8984 10 11.8984Z"
          fill="white"
        />
        <path
          d="M10 12.856C9.41751 12.856 8.94531 13.3282 8.94531 13.9106C8.94531 14.4931 9.41751 14.9653 10 14.9653C10.5825 14.9653 11.0547 14.4931 11.0547 13.9106C11.0547 13.3282 10.5825 12.856 10 12.856Z"
          fill="white"
        />
      </svg>
    ),
  },
  success: {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47254 0 0 4.47293 0 10C0 15.5275 4.47293 20 10 20C15.5275 20 20 15.5271 20 10C20 4.47254 15.527 0 10 0ZM10 18.4375C5.33621 18.4375 1.5625 14.6635 1.5625 10C1.5625 5.33621 5.33652 1.5625 10 1.5625C14.6638 1.5625 18.4375 5.33652 18.4375 10C18.4375 14.6638 14.6634 18.4375 10 18.4375Z"
          fill="white"
        />
        <path
          d="M8.46971 11.8489L6.81289 10.1932C6.72369 10.1039 6.60257 10.0537 6.47628 10.0537C6.34998 10.0537 6.22887 10.1039 6.13966 10.1932C5.95345 10.3793 5.95345 10.6799 6.13966 10.866L8.13548 12.8604C8.32169 13.0465 8.6225 13.0465 8.80871 12.8604L13.8603 7.81232C14.0466 7.62624 14.0466 7.32564 13.8603 7.13956C13.7711 7.05021 13.65 7 13.5237 7C13.3974 7 13.2763 7.05021 13.1871 7.13956L8.46971 11.8489Z"
          fill="white"
        />
      </svg>
    ),
  },
  error: {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47254 0 0 4.47293 0 10C0 15.5275 4.47293 20 10 20C15.5275 20 20 15.5271 20 10C20 4.47254 15.527 0 10 0ZM10 18.4375C5.33621 18.4375 1.5625 14.6635 1.5625 10C1.5625 5.33621 5.33652 1.5625 10 1.5625C14.6638 1.5625 18.4375 5.33652 18.4375 10C18.4375 14.6638 14.6634 18.4375 10 18.4375Z"
          fill="white"
        />
        <path
          d="M10.7329 10.0001L13.848 6.88486C14.0507 6.68223 14.0507 6.3546 13.848 6.15197C13.6453 5.94934 13.3177 5.94934 13.1151 6.15197L9.99995 9.26717L6.88491 6.15197C6.68219 5.94934 6.35467 5.94934 6.15204 6.15197C5.94932 6.3546 5.94932 6.68223 6.15204 6.88486L9.26708 10.0001L6.15204 13.1153C5.94932 13.3179 5.94932 13.6455 6.15204 13.8481C6.25302 13.9492 6.3858 14 6.51848 14C6.65116 14 6.78384 13.9492 6.88491 13.8481L9.99995 10.7329L13.1151 13.8481C13.2162 13.9492 13.3488 14 13.4815 14C13.6142 14 13.7469 13.9492 13.848 13.8481C14.0507 13.6455 14.0507 13.3179 13.848 13.1153L10.7329 10.0001Z"
          fill="white"
        />
      </svg>
    ),
  },
};

interface NotificationProps {
  title: string;
  description?: string;
  type?: NotificationTypes;
  removeNotificationHandler: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (
    {
      removeNotificationHandler,
      title,
      description,
      type = 'info',
      onMouseEnter,
      onMouseLeave,
      className,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          classes.notification,
          classes[`notification__${type}`],
          className,
        )}
        onClick={removeNotificationHandler}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={classes.notification__icon}>{config[type].icon}</div>
        <div className={classes['notification__text-box']}>
          <Text
            className={classes.notification__title}
            as="p"
            type="button-bold"
            color="white"
          >
            {title}
          </Text>
          {description && (
            <Text
              className={classes.notification__description}
              color="white"
              as="p"
              ellipsis
            >
              {description}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

Notification.displayName = 'Notification';
