import { DEFAULT_PARAMS } from '../constants/params';

export enum CommentInteraction {
  Delete,
  Edit,
  Reply,
  None,
}

const dashboardInitState = { ...DEFAULT_PARAMS, category: '' };

const initialStore = {
  app: {
    isSidebarOpen: false,
    notifications: [],
  },
  dashboard: dashboardInitState,
  post: {
    commentId: null,
    commentInteraction: CommentInteraction.None,
  },
  filterStage: {
    stages: [] as string[],
  },
};

export default initialStore;
