import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { addNotification } from '../store/app/reducer';

export const onQueryStartedExtended = async <T>(
  queryFulfilled: Promise<{ data: T }>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  {
    errorMessage,
    successMessage,
  }: { errorMessage?: string; successMessage: string },
): Promise<boolean | T> => {
  try {
    const data = await queryFulfilled;

    dispatch(
      addNotification({
        title: successMessage,
        type: 'success',
      }),
    );
    return data.data;
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = err as any;
    dispatch(
      addNotification({
        title:
          error?.error?.data?.message || errorMessage || 'Something went wrong',
        type: 'error',
      }),
    );

    return false;
  }
};
