import { SvgIconType } from './svg.interface';

const SortIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 14L6 17L9 14L8.293 13.293L6.5 15.086L6.5 3H5.5L5.5 15.086L3.707 13.293L3 14Z"
      fill="#B6B7B8"
    />
    <path
      d="M17 6L14 3L11 6L11.707 6.707L13.5 4.914L13.5 17L14.5 17L14.5 4.914L16.293 6.707L17 6Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default SortIcon;
