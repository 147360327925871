import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import {
  ICreateKBItem,
  IDeleteKBItem,
  IKBCategories,
  IKBDriveGetFiles,
  IKBDriveParams,
  IKBDriveUpdatedItem,
  IKBDriveUploadParams,
  IKBItem,
  IKBParams,
  IUpdateKBItem,
  MimeType,
} from '../../interfaces/knowledge-base.interface';
import {
  IResponseMessage,
  ISingleElement,
} from '../../interfaces/common.interface';

export const knowledgeBaseApi = createApi({
  reducerPath: 'knowledgeBaseApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['KBItem'],
  endpoints: (builder) => ({
    getKBCategories: builder.query<IKBCategories, {}>({
      query: (params: IKBParams) => ({
        url: '/knowledge-base',
        params,
      }),
      providesTags: [{ type: 'KBItem', id: 'CATEGORIES_LIST' }],
    }),
    getKBFilesDrive: builder.query<IKBDriveGetFiles, IKBDriveParams>({
      query: (params: IKBDriveParams) => ({
        url: '/knowledge-base/files',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'KBItem' as const,
                id,
              })),
              { type: 'KBItem', id: 'LIST' },
            ]
          : [{ type: 'KBItem', id: 'LIST' }],
    }),
    uploadKBFiles: builder.mutation<IKBItem, IKBDriveUploadParams>({
      query: ({ data, categoryName }) => {
        return {
          url: '/knowledge-base/upload',
          method: 'POST',
          data,
          params: { categoryName },
          // a new axios logic in versions 1.x about FormData https://github.com/axios/axios/issues/5556#issuecomment-1434668134
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: 'KBItem', id: result.id }] : [],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'File was uploaded successfully',
              type: 'success',
            }),
          );
          dispatch(
            knowledgeBaseApi.util.invalidateTags([
              { type: 'KBItem', id: 'LIST' },
            ]),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'File upload error',
              type: 'error',
            }),
          );
        }
      },
    }),
    createKBItem: builder.mutation<IKBItem, ICreateKBItem>({
      query: (data) => ({
        url: '/knowledge-base',
        method: 'POST',
        params: {
          categoryName: data.categoryName,
        },
        data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'KBItem', id: result.id }] : [],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const title =
            arg.mimeType === MimeType.folder
              ? 'Folder was created successfully'
              : 'File was created successfully';
          dispatch(
            addNotification({
              title,
              type: 'success',
            }),
          );

          dispatch(
            knowledgeBaseApi.util.invalidateTags([
              { type: 'KBItem', id: 'LIST' },
            ]),
          );
        } catch (err) {
          const title = 'Folder creation error';
          dispatch(
            addNotification({
              title,
              type: 'error',
            }),
          );
        }
      },
    }),
    deleteKBItem: builder.mutation<IResponseMessage, IDeleteKBItem>({
      query: ({ itemId, categoryName }) => ({
        url: `knowledge-base/${itemId}`,
        method: 'DELETE',
        params: {
          categoryName,
        },
      }),

      invalidatesTags: () => ['KBItem'],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            addNotification({
              title: data.message,
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
    }),
    updateKBFile: builder.mutation<
      ISingleElement<IKBDriveUpdatedItem>,
      IUpdateKBItem
    >({
      query: (data) => ({
        url: `/knowledge-base/${data.itemId}`,
        method: 'PATCH',
        params: {
          categoryName: data.categoryName,
        },
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'File updated successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: () => ['KBItem'],
    }),

    updateKBFolder: builder.mutation<
      ISingleElement<IKBDriveUpdatedItem>,
      IUpdateKBItem
    >({
      query: (data) => ({
        url: `/knowledge-base/${data.itemId}`,
        method: 'PATCH',
        params: {
          categoryName: data.categoryName,
        },
        data,
      }),

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Folder updated successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: () => ['KBItem'],
    }),
  }),
});

export const {
  useGetKBCategoriesQuery,
  useUploadKBFilesMutation,
  useCreateKBItemMutation,
  useDeleteKBItemMutation,
  useUpdateKBFileMutation,
  useUpdateKBFolderMutation,
  useGetKBFilesDriveQuery,
} = knowledgeBaseApi;
