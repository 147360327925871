import { FC, useCallback, useRef, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getNotificationsSelector,
  removeNotification,
} from '../../store/app/reducer';
import { classNames } from '../../utils/classNames';
import { Notification, NotificationTypes } from './notification';

import classes from './notification.module.scss';

export interface NotificationContainerProps {
  position: 'right-top' | 'left-top' | 'left-bottom' | 'right-bottom';
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface Notification {
  id: string;
  title: string;
  description?: string;
  type?: NotificationTypes;
  autoClose?: boolean;
}

export const NotificationContainer: FC<NotificationContainerProps> = ({
  position,
}) => {
  const timeoutsForDisplay = useRef<{ [key in string]: NodeJS.Timeout }>({});
  const timeoutsForExit = useRef<{
    [key in string]: NodeJS.Timeout;
  }>({});
  const [notificationsToExit, setNotificationsToExit] = useState<string[]>([]);

  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsSelector);

  const isLeftSide = useMemo(
    () => position === 'left-bottom' || position === 'left-top',
    [position],
  );

  const removeNotificationHandler = useCallback(
    (id: string) => {
      dispatch(removeNotification({ id }));
      clearTimeout(timeoutsForExit.current[id]);
      delete timeoutsForExit.current[id];
      setNotificationsToExit((arr) => arr.filter((el) => el !== id));
    },
    [dispatch],
  );

  const cancelDisplayHandler = useCallback(
    (id: string) => {
      clearTimeout(timeoutsForDisplay.current[id]);
      delete timeoutsForDisplay.current[id];
      if (!timeoutsForExit.current[id]) {
        const timeout = setTimeout(() => removeNotificationHandler(id), 300);
        timeoutsForExit.current[id] = timeout;
        setNotificationsToExit((arr) => [...arr, id]);
      }
    },
    [removeNotificationHandler],
  );

  const setDisplayTimeout = useCallback(
    ({ id, autoClose }: Notification) => {
      if (
        (autoClose === undefined || autoClose) &&
        !timeoutsForDisplay.current[id]
      ) {
        const timeout = setTimeout(() => cancelDisplayHandler(id), 2500);
        timeoutsForDisplay.current[id] = timeout;
      }
    },
    [cancelDisplayHandler],
  );

  useEffect(() => {
    notifications.forEach((el) => {
      if (!timeoutsForDisplay.current[el.id]) {
        setDisplayTimeout(el);
      }
    });
  }, [notifications, setDisplayTimeout]);

  return (
    <div
      className={classNames(
        classes.container,
        classes[`container__${position}`],
      )}
    >
      {notifications.map((e) => (
        <Notification
          key={e.id}
          className={classNames({
            [classes.enterFromLeftToRight]: isLeftSide,
            [classes.enterFromRightToLeft]: !isLeftSide,
            [classes.exitFromRightToLeft]:
              isLeftSide && notificationsToExit.indexOf(e.id) >= 0,
            [classes.exitFromLeftToRight]:
              !isLeftSide && notificationsToExit.indexOf(e.id) >= 0,
          })}
          title={e.title}
          type={e.type}
          description={e.description}
          removeNotificationHandler={() => cancelDisplayHandler(e.id)}
        />
      ))}
    </div>
  );
};
