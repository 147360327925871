import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 100, height = 100, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M49.9998 77.3502C74.3791 77.3502 94.1424 76.196 94.1424 74.7722C94.1424 73.3484 74.3791 72.1942 49.9998 72.1942C25.6205 72.1942 5.85718 73.3484 5.85718 74.7722C5.85718 76.196 25.6205 77.3502 49.9998 77.3502Z"
      fill="#F5F5F5"
    />
    <path
      d="M52.3464 38.7926C52.3464 38.7926 56.1149 44.4661 58.5859 40.069C58.5859 40.069 63.4973 42.8867 64.217 38.7501C64.217 38.7501 68.9992 41.88 69.8269 37.1224C69.8269 37.1224 73.1123 39.1992 75.0638 34.5891C75.0638 34.5891 79.1396 36.8503 80.3652 31.9039C80.3652 31.9039 83.9677 33.9361 85.5637 27.8299C85.5637 27.8299 77.0299 22.2898 66.6346 26.122C56.2393 29.9542 52.3464 38.7926 52.3464 38.7926Z"
      fill="#007CFF"
    />
    <path
      d="M52.3464 38.7926C52.3464 38.7926 56.1149 44.4661 58.5859 40.069C58.5859 40.069 63.4973 42.8867 64.217 38.7501C64.217 38.7501 68.9992 41.88 69.8269 37.1224C69.8269 37.1224 73.1123 39.1992 75.0638 34.5891C75.0638 34.5891 79.1396 36.8503 80.3652 31.9039C80.3652 31.9039 83.9677 33.9361 85.5637 27.8299C85.5637 27.8299 77.0299 22.2898 66.6346 26.122C56.2393 29.9542 52.3464 38.7926 52.3464 38.7926Z"
      fill="#0065A1"
    />
    <path
      d="M66.6351 26.1218L65.6528 26.4739L82.6892 74.0006L83.6715 73.6485L66.6351 26.1218Z"
      fill="#1A2E35"
    />
    <path
      d="M62.7826 24.7821C63.615 24.1721 64.386 23.6537 65.053 23.2263C65.8341 23.0987 66.7616 22.9718 67.7887 22.8724C73.5111 22.1588 82.4676 23.1054 87.513 26.5445C81.5666 26.6564 75.6721 27.6754 70.0331 29.5662C68.5216 30.073 67.0327 30.641 65.5665 31.27C60.1008 33.6151 55.0252 36.7808 50.5149 40.6577C51.9879 34.7316 58.0437 28.0582 62.7826 24.7821Z"
      fill="#167BC4"
    />
    <path
      opacity="0.5"
      d="M67.0476 23.1258C66.2992 23.1408 65.6281 23.1797 65.0581 23.2242C64.6021 23.5687 64.0725 23.9901 63.5042 24.4774C60.2792 27.1318 56.0947 32.1892 55.2075 37.0215C58.6484 34.6249 62.3373 32.6055 66.2103 30.9982C67.2523 30.5658 68.3061 30.1638 69.3718 29.7922C73.3313 28.4117 77.4279 27.4612 81.5909 26.9574C77.7113 23.9432 71.2169 22.9592 67.0476 23.1258Z"
      fill="white"
    />
    <path
      d="M66.0699 23.4752C65.6875 23.3681 65.346 23.2872 65.0566 23.2246C64.881 23.4621 64.6795 23.7525 64.4657 24.0871C63.255 25.9373 61.7425 29.2559 61.9009 32.9953C63.5855 32.1297 65.308 31.348 67.0682 30.65C67.5428 30.4618 68.0199 30.2798 68.4995 30.104C70.2753 29.4531 72.0808 28.8891 73.916 28.4121C71.544 25.517 68.2032 24.0498 66.0699 23.4752Z"
      fill="#49B2F5"
    />
    <path
      d="M50.5149 40.6577C50.6195 40.6037 54.7966 43.0216 55.2076 37.0216C53.5828 38.1534 52.0165 39.367 50.5149 40.6577Z"
      fill="#007CFF"
    />
    <path
      d="M55.2075 37.0215C55.3634 36.9152 61.1663 39.3713 61.9012 32.9954C59.5827 34.1866 57.3465 35.5317 55.2075 37.0215Z"
      fill="#007CFF"
    />
    <path
      opacity="0.4"
      d="M55.2075 37.0215C55.3634 36.9152 61.1663 39.3713 61.9012 32.9954C59.5827 34.1866 57.3465 35.5317 55.2075 37.0215Z"
      fill="white"
    />
    <path
      d="M50.5149 40.6577C50.6195 40.6037 54.7966 43.0216 55.2076 37.0216C53.5828 38.1534 52.0165 39.367 50.5149 40.6577Z"
      fill="#005E9D"
    />
    <path
      d="M87.5127 26.5443C87.3987 26.5738 85.8932 31.1592 81.5906 26.9574C83.5563 26.7194 85.5329 26.5816 87.5127 26.5443Z"
      fill="#007CFF"
    />
    <path
      d="M81.5909 26.9574C81.4038 26.9819 78.7111 32.6787 73.9163 28.4121C76.4392 27.7566 79.0031 27.2706 81.5909 26.9574Z"
      fill="#007CFF"
    />
    <path
      opacity="0.4"
      d="M81.5909 26.9574C81.4038 26.9819 78.7111 32.6787 73.9163 28.4121C76.4392 27.7566 79.0031 27.2706 81.5909 26.9574Z"
      fill="white"
    />
    <path
      d="M87.5127 26.5443C87.3987 26.5738 85.8932 31.1592 81.5906 26.9574C83.5563 26.7194 85.5329 26.5816 87.5127 26.5443Z"
      fill="#005E9D"
    />
    <path
      d="M61.9011 32.9952C62.205 32.9229 68.4976 35.6708 73.9162 28.412C69.7548 29.4933 65.7254 31.0303 61.9011 32.9952Z"
      fill="#007CFF"
    />
    <path
      d="M61.9011 32.9952C62.205 32.9229 68.4976 35.6708 73.9162 28.412C69.7548 29.4933 65.7254 31.0303 61.9011 32.9952Z"
      fill="#2693C8"
    />
    <path
      d="M87.7282 74.2307C87.7282 74.2307 85.3321 72.3524 84.4403 72.4059C83.5485 72.4594 82.5037 73.3177 82.5037 73.3177C82.5037 73.3177 81.1977 72.6045 80.8374 72.7738C80.4772 72.9431 78.3606 74.2307 78.3606 74.2307H87.7282Z"
      fill="#007CFF"
    />
    <path
      d="M87.7282 74.2307C87.7282 74.2307 85.3321 72.3524 84.4403 72.4059C83.5485 72.4594 82.5037 73.3177 82.5037 73.3177C82.5037 73.3177 81.1977 72.6045 80.8374 72.7738C80.4772 72.9431 78.3606 74.2307 78.3606 74.2307H87.7282Z"
      fill="#49B2F5"
    />
    <path
      d="M57.1493 71.6087C54.7265 72.2297 52.4002 72.7246 50.1725 73.1109C21.597 78.0631 8.85181 64.9828 8.85181 64.9828L9.42353 64.0582L42.206 68.4111L56.8162 70.351L57.1493 71.6087Z"
      fill="#49B2F5"
    />
    <path
      d="M56.8165 70.3508C56.8165 70.3508 54.0584 71.1512 49.8929 71.8964C44.4281 72.8762 36.5425 73.7613 29.2858 72.6179C16.4952 70.6031 9.42383 64.0581 9.42383 64.0581L10.5416 62.3765L23.0443 64.0186L56.1028 68.3636L56.8165 70.3508Z"
      fill="#007CFF"
    />
    <path
      opacity="0.7"
      d="M56.8165 70.3508C56.8165 70.3508 54.0584 71.1512 49.8929 71.8964C44.4281 72.8762 36.5425 73.7613 29.2858 72.6179C16.4952 70.6031 9.42383 64.0581 9.42383 64.0581L10.5416 62.3765L23.0443 64.0186L56.1028 68.3636L56.8165 70.3508Z"
      fill="white"
    />
    <path
      d="M10.5422 62.3754L37.4667 31.6047L49.4742 70.0772C49.4742 70.0772 28.4279 76.9455 10.5422 62.3754Z"
      fill="#007CFF"
    />
    <path
      d="M37.4668 31.6047L56.1035 68.3632L49.4743 70.0772L37.4668 31.6047Z"
      fill="#167BC4"
    />
    <path
      d="M10.5422 62.3754L37.4667 31.6047L49.4742 70.0772C49.4742 70.0772 28.4279 76.9455 10.5422 62.3754Z"
      fill="#49B2F5"
    />
    <path
      d="M42.6787 64.9827C42.6787 64.9827 44.634 67.145 43.2252 67.5609C41.8164 67.9768 42.6787 64.9827 42.6787 64.9827Z"
      fill="#1A2E35"
    />
    <path
      d="M36.73 65.9316C36.73 65.9316 38.1195 68.4945 36.6523 68.566C35.1851 68.6376 36.73 65.9316 36.73 65.9316Z"
      fill="#1A2E35"
    />
    <path
      d="M29.7762 62.2891C29.7762 62.2891 30.764 65.0319 29.3028 64.8817C27.8416 64.7316 29.7762 62.2891 29.7762 62.2891Z"
      fill="#1A2E35"
    />
    <path
      d="M23.2362 63.1981C23.2362 63.1981 23.7004 66.0762 22.2921 65.6587C20.8837 65.2412 23.2362 63.1981 23.2362 63.1981Z"
      fill="#1A2E35"
    />
    <path
      d="M21.0225 57.3496C21.0225 57.3496 20.6027 60.2344 19.3842 59.414C18.1658 58.5936 21.0225 57.3496 21.0225 57.3496Z"
      fill="#1A2E35"
    />
    <path
      d="M27.3439 55.0475C27.3439 55.0475 27.8217 57.9233 26.4114 57.5125C25.0011 57.1017 27.3439 55.0475 27.3439 55.0475Z"
      fill="#1A2E35"
    />
    <path
      d="M34.868 56.4487C34.868 56.4487 35.9944 59.1376 34.5274 59.0621C33.0605 58.9867 34.868 56.4487 34.868 56.4487Z"
      fill="#1A2E35"
    />
    <path
      d="M39.471 52.5215C39.471 52.5215 40.8916 55.0672 39.4254 55.1566C37.9593 55.2461 39.471 52.5215 39.471 52.5215Z"
      fill="#1A2E35"
    />
    <path
      d="M32.4814 50.4563C32.4814 50.4563 32.8571 53.3472 31.4623 52.8868C30.0674 52.4264 32.4814 50.4563 32.4814 50.4563Z"
      fill="#1A2E35"
    />
    <path
      d="M28.4373 46.6465C28.4373 46.6465 28.3334 49.2051 27.2841 48.9841C26.2348 48.7631 28.4373 46.6465 28.4373 46.6465Z"
      fill="#1A2E35"
    />
    <path
      d="M32.9741 42.5679C32.9741 42.5679 33.1665 45.1213 32.0987 45.023C31.031 44.9248 32.9741 42.5679 32.9741 42.5679Z"
      fill="#1A2E35"
    />
    <path
      d="M37.2516 44.22C37.2516 44.22 37.8326 46.7139 36.7624 46.7802C35.6922 46.8466 37.2516 44.22 37.2516 44.22Z"
      fill="#1A2E35"
    />
    <path
      d="M36.1454 38.3967C36.1454 38.3967 36.7264 40.8906 35.6562 40.957C34.586 41.0233 36.1454 38.3967 36.1454 38.3967Z"
      fill="#1A2E35"
    />
    <path
      d="M49.8235 63.7932C49.8235 63.7932 51.1796 65.2932 50.4658 65.7488C49.7521 66.2043 49.8235 63.7932 49.8235 63.7932Z"
      fill="#1A2E35"
    />
    <path
      d="M49.5293 57.6371C49.5293 57.6371 50.8853 59.1371 50.1716 59.5926C49.4579 60.0482 49.5293 57.6371 49.5293 57.6371Z"
      fill="#1A2E35"
    />
    <path
      d="M45.46 48.9503C45.46 48.9503 46.816 50.4503 46.1023 50.9059C45.3885 51.3614 45.46 48.9503 45.46 48.9503Z"
      fill="#1A2E35"
    />
    <path
      d="M41.9233 41.7931C41.9233 41.7931 42.8806 43.3614 42.4541 43.7677C42.0277 44.174 41.9233 41.7931 41.9233 41.7931Z"
      fill="#1A2E35"
    />
    <path
      d="M45.9351 53.9329C45.9351 53.9329 47.2911 55.4328 46.5774 55.8884C45.8636 56.3439 45.9351 53.9329 45.9351 53.9329Z"
      fill="#1A2E35"
    />
    <path
      d="M82.5674 39.9742C82.5674 39.9742 80.2677 48.5587 78.4622 49.606C76.6568 50.6533 76.1447 53.7291 74.7186 55.187C73.2926 56.645 72.0075 58.0267 70.53 59.4287C69.0525 60.8307 69.3761 61.5806 67.0729 62.4968C64.7698 63.4131 60.1741 64.1651 58.8372 64.6664C57.5003 65.1678 56.6743 65.2395 55.683 65.9278C54.6915 66.616 50.064 67.6358 48.4761 66.4832C46.8882 65.3307 42.9824 63.0194 42.5633 62.956C42.1443 62.8925 35.8474 56.3806 35.8474 56.3806C35.8474 56.3806 41.2902 71.1003 45.5516 72.9386C49.8131 74.7769 65.634 78.3127 73.8083 74.0798C81.9825 69.847 85.0742 61.322 82.5674 39.9742Z"
      fill="#007CFF"
    />
    <path
      d="M82.5674 39.9742C82.5674 39.9742 80.2677 48.5587 78.4622 49.606C76.6568 50.6533 76.1447 53.7291 74.7186 55.187C73.2926 56.645 72.0075 58.0267 70.53 59.4287C69.0525 60.8307 69.3761 61.5806 67.0729 62.4968C64.7698 63.4131 60.1741 64.1651 58.8372 64.6664C57.5003 65.1678 56.6743 65.2395 55.683 65.9278C54.6915 66.616 50.064 67.6358 48.4761 66.4832C46.8882 65.3307 42.9824 63.0194 42.5633 62.956C42.1443 62.8925 35.8474 56.3806 35.8474 56.3806C35.8474 56.3806 41.2902 71.1003 45.5516 72.9386C49.8131 74.7769 65.634 78.3127 73.8083 74.0798C81.9825 69.847 85.0742 61.322 82.5674 39.9742Z"
      fill="#167BC4"
    />
    <path
      d="M66.113 69.385C66.113 69.385 67.6404 71.0353 66.8178 71.3463C65.9952 71.6574 66.113 69.385 66.113 69.385Z"
      fill="#1A2E35"
    />
    <path
      d="M74.7693 66.4833C74.7693 66.4833 77.4549 66.8111 76.822 67.591C76.189 68.3709 74.7693 66.4833 74.7693 66.4833Z"
      fill="#1A2E35"
    />
    <path
      d="M80.3911 60.2379C80.3911 60.2379 81.4253 59.8479 81.4253 60.2379C81.4253 60.6278 80.3911 60.2379 80.3911 60.2379Z"
      fill="#1A2E35"
    />
    <path
      d="M77.9475 61.9366C77.9475 61.9366 79.6792 61.8505 79.4921 62.4329C79.305 63.0153 77.9475 61.9366 77.9475 61.9366Z"
      fill="#1A2E35"
    />
    <path
      d="M72.4021 70.5466C72.4021 70.5466 73.7675 71.6152 73.2448 71.9329C72.7221 72.2506 72.4021 70.5466 72.4021 70.5466Z"
      fill="#1A2E35"
    />
    <path
      d="M63.3834 71.7443C63.3834 71.7443 64.3136 73.2074 63.7149 73.3324C63.1161 73.4573 63.3834 71.7443 63.3834 71.7443Z"
      fill="#1A2E35"
    />
    <path
      d="M55.4191 68.6033C55.4191 68.6033 55.5257 70.3338 54.9412 70.1536C54.3567 69.9734 55.4191 68.6033 55.4191 68.6033Z"
      fill="#1A2E35"
    />
    <path
      d="M49.7808 68.4093C49.7808 68.4093 48.8042 69.8419 48.4529 69.3412C48.1015 68.8405 49.7808 68.4093 49.7808 68.4093Z"
      fill="#1A2E35"
    />
    <path
      d="M80.3911 52.272C80.3911 52.272 81.1337 51.1827 81.6695 51.8109C82.2052 52.4391 80.3911 52.272 80.3911 52.272Z"
      fill="#1A2E35"
    />
    <path
      d="M59.2643 68.2925C59.2643 68.2925 60.0535 70.6023 59.2643 70.6023C58.4752 70.6023 59.2643 68.2925 59.2643 68.2925Z"
      fill="#1A2E35"
    />
    <path
      d="M52.0953 69.6555C52.0953 69.6555 51.8098 71.5686 51.1896 71.343C50.5693 71.1175 52.0953 69.6555 52.0953 69.6555Z"
      fill="#1A2E35"
    />
    <path
      d="M45.9716 66.1187C45.9716 66.1187 44.0058 66.7152 43.7075 65.8582C43.4092 65.0012 45.9716 66.1187 45.9716 66.1187Z"
      fill="#1A2E35"
    />
    <path
      d="M78.4257 53.3626C78.4257 53.3626 81.7215 50.6323 81.7683 50.2546C81.8151 49.8769 78.4173 50.0864 78.4173 50.0864L78.2241 49.1767C78.2241 49.1767 82.9014 48.7915 83.8623 49.3551C84.8232 49.9187 78.738 56.2463 78.738 56.2463L78.4257 53.3626Z"
      fill="#CC7A63"
    />
    <path
      d="M79.8936 46.6744C79.8936 46.6744 80.6895 46.829 80.959 47.7555C81.335 49.0482 80.6039 49.5773 80.1025 50.312C79.6012 51.0467 79.8631 51.2962 80.1389 51.7923C80.4148 52.2885 80.6874 53.2401 79.6429 54.0548C78.5985 54.8694 78.6836 54.5132 78.5993 54.9629C78.5149 55.4126 76.2369 55.893 74.4514 55.1619C72.6659 54.4308 72.4693 53.1199 73.3092 52.6473C74.149 52.1747 74.9846 52.6133 74.337 51.4749C73.6895 50.3365 73.8346 49.7508 74.4514 49.1768C75.0682 48.6027 75.1828 49.2595 75.2404 48.012C75.298 46.7644 75.4772 45.1211 78.3478 45.4693C80.0398 45.6745 79.8936 46.6744 79.8936 46.6744Z"
      fill="#1A2E35"
    />
    <path
      d="M74.1289 52.5136L73.7847 53.2951L73.2925 54.4166C73.2925 54.4166 71.5817 54.6321 70.0335 54.7271C68.9161 54.7947 67.8833 54.7968 67.6383 54.6109C67.0532 54.1653 69.366 49.715 69.366 49.715L70.0694 50.0043L69.1422 53.1345C71.7886 52.4671 74.1289 52.5136 74.1289 52.5136Z"
      fill="#CC7A63"
    />
    <path
      d="M69.5326 50.1068C69.5326 50.1068 69.0607 50.0733 68.9833 49.6722C68.9059 49.2712 69.1346 47.9614 69.1224 47.9237C69.1101 47.886 70.5989 47.8457 70.6506 47.8691C70.7024 47.8925 70.5738 49.7019 70.4292 49.9043C70.2846 50.1068 69.8917 50.1192 69.5326 50.1068Z"
      fill="#CC7A63"
    />
    <path
      d="M72.344 47.2148L70.9523 49.6722L70.8489 49.8535C70.8489 49.8535 68.974 49.0386 68.9832 46.9911L69.2394 47.0079L72.344 47.2148Z"
      fill="#49B2F5"
    />
    <path
      opacity="0.7"
      d="M71.0193 49.5517L70.9521 49.6722C69.4709 48.924 69.2436 47.6842 69.2393 47.0078L69.3588 47.0163C69.4232 47.6604 69.7188 48.9531 71.0193 49.5517Z"
      fill="white"
    />
    <path
      d="M72.3442 47.2147L71.0197 49.5517C69.7191 48.953 69.4235 47.6604 69.3591 47.0162L72.3442 47.2147Z"
      fill="#49B2F5"
    />
    <path
      d="M70.4782 48.0896C70.4782 48.0896 70.3394 48.3297 70.2709 48.2254C70.2024 48.1211 70.4782 48.0896 70.4782 48.0896Z"
      fill="#1A2E35"
    />
    <path
      d="M71.2208 48.2384C71.2208 48.2384 71.1981 48.5418 71.0557 48.4415C70.9133 48.3412 71.2208 48.2384 71.2208 48.2384Z"
      fill="#1A2E35"
    />
    <path
      d="M70.9608 47.6196C70.9608 47.6196 70.6998 47.8569 70.6544 47.7247C70.6091 47.5926 70.9608 47.6196 70.9608 47.6196Z"
      fill="#1A2E35"
    />
    <path
      d="M71.6952 47.6063C71.6952 47.6063 71.3983 47.4989 71.4371 47.6657C71.4759 47.8324 71.6952 47.6063 71.6952 47.6063Z"
      fill="#1A2E35"
    />
    <path
      d="M70.7815 48.6936C70.7815 48.6936 70.8167 49.0055 70.7125 48.9447C70.6084 48.8839 70.7815 48.6936 70.7815 48.6936Z"
      fill="#1A2E35"
    />
    <path
      d="M70.0965 47.6545C70.0965 47.6545 69.854 47.7264 69.8038 47.645C69.7537 47.5635 70.0965 47.6545 70.0965 47.6545Z"
      fill="#1A2E35"
    />
    <path
      d="M70.515 47.3091C70.515 47.3091 70.144 47.3818 70.1965 47.2778C70.2489 47.1739 70.515 47.3091 70.515 47.3091Z"
      fill="#1A2E35"
    />
    <path
      d="M70.4302 48.6532C70.4302 48.6532 70.3316 48.885 70.2752 48.8251C70.2188 48.7651 70.4302 48.6532 70.4302 48.6532Z"
      fill="#1A2E35"
    />
    <path
      d="M71.6812 48.0068C71.6812 48.0068 71.6193 48.1883 71.5473 48.1426C71.4753 48.097 71.6812 48.0068 71.6812 48.0068Z"
      fill="#1A2E35"
    />
    <path
      d="M71.2492 48.7156C71.2492 48.7156 71.2476 49.0005 71.1795 48.9692C71.1115 48.9379 71.2492 48.7156 71.2492 48.7156Z"
      fill="#1A2E35"
    />
    <path
      opacity="0.2"
      d="M73.7849 53.2952L73.2927 54.4168C73.2927 54.4168 71.5819 54.6322 70.0337 54.7273C70.9651 53.3206 73.7489 53.0481 73.7489 53.0481C73.7705 53.1288 73.7826 53.2117 73.7849 53.2952Z"
      fill="black"
    />
    <path
      d="M56.2517 59.5435C57.6436 59.5756 64.0635 60.6916 64.0635 60.6916L66.2392 61.1616L66.7157 61.2646C66.7157 61.2646 66.5935 61.3967 66.4178 61.6064C65.894 62.2233 64.886 63.5153 65.0719 64.0982C65.3549 64.9828 67.7408 66.2114 68.4086 66.5414C68.5183 66.5951 68.5825 66.6256 68.5825 66.6256C68.5375 66.6294 68.4925 66.6331 68.446 66.6348C65.5291 66.812 60.0836 64.4483 57.4401 63.2435C56.5663 62.8451 55.9988 62.5729 55.929 62.5595C55.8447 62.5443 55.9332 59.5357 56.2517 59.5435Z"
      fill="#CC7A63"
    />
    <path
      opacity="0.2"
      d="M57.4402 63.2434C60.0836 64.4482 65.5292 66.8119 68.4461 66.6348L68.4087 66.5413L66.4179 61.6063L66.7004 62.4035C66.7004 62.4035 61.4349 61.679 57.4402 63.2434Z"
      fill="black"
    />
    <path
      d="M77.3403 53.4218C77.3403 53.4218 76.7627 53.9059 75.3241 53.7141C75.3241 53.7141 74.7551 53.0773 75.1942 52.604C75.1942 52.604 75.5204 52.5899 75.7128 52.1281C75.8067 51.9057 75.8699 51.5781 75.8496 51.0963C75.8478 51.0408 75.8435 50.9811 75.8393 50.9213L77.8909 50.2886C77.8909 50.2886 76.6788 52.3537 77.3403 53.4218Z"
      fill="#CC7A63"
    />
    <path
      opacity="0.2"
      d="M77.3261 50.8218C77.3261 50.8218 76.8977 52.0546 75.7129 52.1281C75.8068 51.9057 75.8701 51.5781 75.8497 51.0963C76.5815 50.928 77.3261 50.8218 77.3261 50.8218Z"
      fill="black"
    />
    <path
      d="M78.0542 46.352C76.6657 45.7603 75.9019 46.3133 75.1905 47.7009C74.3013 49.4349 74.1564 50.8744 76.0554 51.2323C78.6343 51.7184 80.3603 47.3346 78.0542 46.352Z"
      fill="#CC7A63"
    />
    <path
      d="M76.1211 48.0057C76.1211 48.0057 75.7742 48.2865 75.4717 48.4095C75.4879 48.4841 75.5244 48.5529 75.5772 48.6081C75.63 48.6633 75.697 48.7029 75.7708 48.7225L76.1211 48.0057Z"
      fill="#B84D3C"
    />
    <path
      d="M77.0196 48.2543C76.9683 48.3672 76.9878 48.4865 77.0631 48.5207C77.1384 48.5549 77.2409 48.491 77.2922 48.3781C77.3435 48.2652 77.324 48.1459 77.2487 48.1118C77.1735 48.0776 77.0709 48.1414 77.0196 48.2543Z"
      fill="#1A2E35"
    />
    <path
      d="M77.2791 48.1328L77.0757 47.9235C77.0757 47.9235 77.1044 48.1876 77.2791 48.1328Z"
      fill="#1A2E35"
    />
    <path
      d="M75.6263 47.5933C75.575 47.7062 75.5945 47.8255 75.6697 47.8597C75.7451 47.8939 75.8476 47.83 75.8989 47.7171C75.9502 47.6042 75.9307 47.4849 75.8555 47.4508C75.7802 47.4166 75.6776 47.4804 75.6263 47.5933Z"
      fill="#1A2E35"
    />
    <path
      d="M75.886 47.472L75.6826 47.2627C75.6826 47.2627 75.7112 47.5268 75.886 47.472Z"
      fill="#1A2E35"
    />
    <path
      d="M77.4421 47.3014C77.4421 47.3014 77.8539 47.4169 77.9549 47.7679"
      stroke="#1A2E35"
      strokeWidth="0.45214"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.458 46.8127C76.458 46.8127 76.2514 46.6174 75.856 46.691"
      stroke="#1A2E35"
      strokeWidth="0.45214"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.7406 49.3551C75.7495 49.5567 75.697 49.9064 75.697 49.9064C75.7279 49.9169 75.7587 49.9273 75.79 49.9362C76.2116 50.0675 76.4505 49.9892 76.5876 49.8625C76.6532 49.7992 76.7027 49.7211 76.7318 49.6348C76.75 49.5864 76.7613 49.5357 76.7654 49.4842C76.5003 49.5481 76.0979 49.4559 75.8849 49.3977C75.7956 49.3742 75.7406 49.3551 75.7406 49.3551Z"
      fill="#1A2E35"
    />
    <path
      d="M75.8851 49.3976L75.8765 49.5605C76.2739 49.6832 76.5701 49.7163 76.732 49.6347C76.7502 49.5863 76.7614 49.5356 76.7655 49.4841C76.5004 49.548 76.098 49.4558 75.8851 49.3976Z"
      fill="white"
    />
    <path
      d="M75.79 49.9361C76.2116 50.0674 76.4506 49.9891 76.5877 49.8624C76.4423 49.8232 76.2626 49.7921 76.0907 49.8093C75.9797 49.8191 75.8745 49.8634 75.79 49.9361Z"
      fill="#FD5652"
    />
    <path
      d="M78.5747 49.3391C78.5747 49.3391 78.4116 47.9216 78.8852 47.0827C78.8852 47.0827 76.6126 44.786 75.2866 47.5211C75.2866 47.5211 76.0746 44.9265 78.4852 45.8884C80.8919 46.8488 79.3975 50.3382 77.7213 50.8534C77.7213 50.8534 78.6723 50.2237 78.5747 49.3391Z"
      fill="#1A2E35"
    />
    <path
      d="M78.2229 49.5355C78.3275 49.3159 78.7194 49.0358 79.1159 49.2583C79.5262 49.4886 79.3548 50.4594 78.194 50.2157C78.0274 50.1807 78.0048 49.9937 78.2229 49.5355Z"
      fill="#CC7A63"
    />
    <path
      d="M75.2766 46.9313C75.2766 46.9313 75.6264 46.8992 76.1656 47.2659L76.1669 47.2639C76.1669 47.2639 76.4623 47.2382 76.6813 47.4633C77.0546 47.563 77.4059 47.7318 77.7171 47.9608L78.7384 48.69L78.7055 48.8149L77.6268 48.212C77.4559 48.471 77.0835 48.8382 76.7139 48.655C76.222 48.4111 76.3965 47.8819 76.5013 47.6407C76.4254 47.4845 76.2227 47.4119 76.1553 47.4981C76.054 47.7099 75.7764 48.1853 75.2405 48.012C74.6983 47.8366 75.2766 46.9313 75.2766 46.9313Z"
      fill="#007CFF"
    />
    <path
      d="M75.2766 46.9313C75.2766 46.9313 75.6264 46.8992 76.1656 47.2659L76.1669 47.2639C76.1669 47.2639 76.4623 47.2382 76.6813 47.4633C77.0546 47.563 77.4059 47.7318 77.7171 47.9608L78.7384 48.69L78.7055 48.8149L77.6268 48.212C77.4559 48.471 77.0835 48.8382 76.7139 48.655C76.222 48.4111 76.3965 47.8819 76.5013 47.6407C76.4254 47.4845 76.2227 47.4119 76.1553 47.4981C76.054 47.7099 75.7764 48.1853 75.2405 48.012C74.6983 47.8366 75.2766 46.9313 75.2766 46.9313Z"
      fill="#167BC4"
    />
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M75.4517 47.055L75.458 47.9599C75.458 47.9599 75.1073 47.983 75.0887 47.7019C75.0702 47.4209 75.2951 47.0138 75.2951 47.0138C75.2951 47.0138 75.3969 47.0225 75.4517 47.055Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M75.5283 47.0673L75.5306 47.9791C75.5306 47.9791 75.6868 47.9727 75.703 47.9181L75.7072 47.1405C75.7072 47.1405 75.5801 47.0864 75.5283 47.0673Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M76.849 47.6456L76.8552 48.5505C76.8552 48.5505 76.5045 48.5736 76.486 48.2925C76.4674 48.0115 76.6923 47.6044 76.6923 47.6044C76.6923 47.6044 76.7942 47.6131 76.849 47.6456Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M76.9255 47.6577L76.9278 48.5696C76.9278 48.5696 77.084 48.5632 77.1002 48.5086L77.1044 47.731C77.1044 47.731 76.9774 47.6769 76.9255 47.6577Z"
        fill="white"
      />
    </g>
    <path
      d="M70.0701 60.1262C70.0701 60.1262 67.9781 60.5004 66.2386 61.1615C66.2386 61.1615 65.5044 62.2246 65.2733 63.1676C64.7697 65.2222 64.9145 67.7853 67.6418 68.0509C71.6167 68.438 73.1048 67.355 74.425 62.2106L70.0701 60.1262Z"
      fill="#007CFF"
    />
    <path
      opacity="0.7"
      d="M70.0701 60.1262C70.0701 60.1262 67.9781 60.5004 66.2386 61.1615C66.2386 61.1615 65.5044 62.2246 65.2733 63.1676C64.7697 65.2222 64.9145 67.7853 67.6418 68.0509C71.6167 68.438 73.1048 67.355 74.425 62.2106L70.0701 60.1262Z"
      fill="white"
    />
    <path
      d="M70.0698 60.1253L74.4246 62.21C74.4246 62.21 75.8419 60.6765 77.1486 58.8504L77.1507 58.8471C77.1635 58.8289 77.1783 58.8092 77.193 58.7896C77.4294 58.455 77.6632 58.113 77.8812 57.7674C78.3882 56.972 78.8232 56.1666 79.0724 55.4431C79.3267 54.706 79.3892 54.0555 79.1384 53.5863C79.1384 53.5863 78.7577 53.4569 78.1712 53.2877C78.1245 53.2752 78.0745 53.2605 78.0245 53.2458C77.1546 52.9994 75.9069 52.6898 74.7838 52.5675L74.7803 52.5671C74.6765 52.5552 74.5723 52.5467 74.4716 52.5387C74.2279 52.5195 73.9919 52.5101 73.7703 52.5149C73.2218 52.5265 72.7057 53.0606 72.243 53.8432C71.9866 54.2735 71.7483 54.779 71.5299 55.3135C71.253 55.9838 71.0084 56.6989 70.8029 57.3686C70.789 57.4115 70.7771 57.453 70.7632 57.4959C70.3265 58.9339 70.0698 60.1253 70.0698 60.1253Z"
      fill="#CC7A63"
    />
    <path
      d="M70.8028 57.3687C71.6965 57.3006 72.7646 57.0031 72.9212 56.9587C72.9341 56.9549 72.9416 56.9522 72.9416 56.9522C72.9855 57.0196 73.0316 57.0837 73.0814 57.1465L73.268 57.3677C73.5244 57.6437 73.8203 57.8803 74.1461 58.0695C75.3393 58.7739 76.7465 58.8434 77.0773 58.8494L77.0791 58.8496C77.1235 58.8512 77.1485 58.8505 77.1485 58.8505L77.1507 58.8472L77.2006 58.7852L77.9569 57.8368C77.931 57.8142 77.9051 57.7916 77.8812 57.7675C77.5005 57.4045 77.3268 56.9175 77.2796 56.3988C77.166 55.1112 77.844 53.6209 78.1711 53.2878C78.1877 53.2713 78.2056 53.2561 78.2245 53.2423L78.119 53.1677C78.0845 53.1899 78.0527 53.2162 78.0244 53.246C77.69 53.596 77.1004 54.7525 77.1004 54.7525C75.9058 54.5703 73.9229 54.8248 73.9229 54.8248C73.9229 54.8248 73.583 54.0317 72.8534 53.7889C72.8534 53.7889 73.9704 52.6807 74.7802 52.5673C74.7509 52.5585 74.5534 52.509 74.4902 52.5321C74.4845 52.5349 74.4773 52.5359 74.4716 52.5388C73.862 52.7555 73.0164 53.2409 72.2429 53.8433C71.8937 54.1126 71.5653 54.4078 71.2606 54.7265C71.2202 54.7692 71.1823 54.8142 71.1471 54.8613C70.3594 55.865 70.8028 57.3687 70.8028 57.3687Z"
      fill="#007CFF"
    />
    <path
      opacity="0.7"
      d="M70.8028 57.3687C71.6965 57.3006 72.7646 57.0031 72.9212 56.9587C72.9341 56.9549 72.9416 56.9522 72.9416 56.9522C72.9855 57.0196 73.0316 57.0837 73.0814 57.1465L73.268 57.3677C73.5244 57.6437 73.8203 57.8803 74.1461 58.0695C75.3393 58.7739 76.7465 58.8434 77.0773 58.8494L77.0791 58.8496C77.1235 58.8512 77.1485 58.8505 77.1485 58.8505L77.1507 58.8472L77.2006 58.7852L77.9569 57.8368C77.931 57.8142 77.9051 57.7916 77.8812 57.7675C77.5005 57.4045 77.3268 56.9175 77.2796 56.3988C77.166 55.1112 77.844 53.6209 78.1711 53.2878C78.1877 53.2713 78.2056 53.2561 78.2245 53.2423L78.119 53.1677C78.0845 53.1899 78.0527 53.2162 78.0244 53.246C77.69 53.596 77.1004 54.7525 77.1004 54.7525C75.9058 54.5703 73.9229 54.8248 73.9229 54.8248C73.9229 54.8248 73.583 54.0317 72.8534 53.7889C72.8534 53.7889 73.9704 52.6807 74.7802 52.5673C74.7509 52.5585 74.5534 52.509 74.4902 52.5321C74.4845 52.5349 74.4773 52.5359 74.4716 52.5388C73.862 52.7555 73.0164 53.2409 72.2429 53.8433C71.8937 54.1126 71.5653 54.4078 71.2606 54.7265C71.2202 54.7692 71.1823 54.8142 71.1471 54.8613C70.3594 55.865 70.8028 57.3687 70.8028 57.3687Z"
      fill="white"
    />
    <path
      d="M41.6013 62.5817C41.5682 63.0698 41.5377 63.5048 41.5092 63.9061C41.4762 64.3942 41.4452 64.8327 41.4161 65.2553C41.3626 65.9978 42.2428 65.4291 43.5382 65.4482C43.5808 65.4496 43.6252 65.4512 43.6695 65.4527L43.6715 65.4511C43.691 65.4516 43.7086 65.4233 43.7211 65.3766C43.8053 65.1101 43.8109 64.2266 43.8109 64.2266L43.8093 64.2247C43.6037 64 43.4101 63.7647 43.2292 63.5197C42.8988 63.077 42.5385 62.4919 42.4546 61.9831C42.2951 61.0004 41.6761 61.4995 41.6013 62.5817Z"
      fill="#CC7A63"
    />
    <path
      d="M43.4419 64.1949L43.3594 65.3903C43.3594 65.3903 43.4342 65.406 43.5755 65.433C43.605 65.44 43.6365 65.4453 43.6714 65.4512C45.1739 65.7456 51.7308 66.9576 55.3444 66.5185C55.3444 66.5185 66.4046 67.845 68.4173 68.0128C70.4299 68.1806 71.7645 65.5396 70.9262 63.8281C70.4356 62.8269 69.6429 61.9566 66.7003 62.4035C63.7593 62.8523 57.4578 64.0956 55.1403 64.2064C55.1403 64.2064 51.8715 64.6389 46.4865 64.3942L46.4847 64.394C45.6442 64.356 44.7504 64.3011 43.8092 64.2247C43.6872 64.216 43.5654 64.2055 43.4419 64.1949Z"
      fill="#CC7A63"
    />
    <path
      opacity="0.2"
      d="M41.6013 62.5817C41.5682 63.0698 41.5377 63.5048 41.5092 63.9061C41.4762 64.3942 41.4452 64.8327 41.4161 65.2553C41.3626 65.9978 42.2428 65.4291 43.5381 65.4482C43.551 65.4443 43.5635 65.4391 43.5755 65.4329C43.6239 65.4154 43.6725 65.396 43.7211 65.3766C44.7182 64.9836 45.7386 64.636 46.4848 64.394C45.6443 64.356 44.7505 64.3011 43.8093 64.2247C43.6037 64 43.4101 63.7647 43.2292 63.5197C42.8988 63.077 42.5385 62.4919 42.4546 61.9831C42.2951 61.0004 41.6761 61.4995 41.6013 62.5817Z"
      fill="black"
    />
    <path
      d="M42.0076 64.7691C42.1362 64.739 42.1252 63.3712 42.1252 63.3712C42.1252 63.3712 40.4145 62.6603 39.9926 61.7581C39.5707 60.8558 39.1123 61.5053 39.3343 62.5659C39.5563 63.6265 39.7257 64.4359 39.8834 65.1895C40.0412 65.9431 40.6745 65.0809 42.0076 64.7691Z"
      fill="#CC7A63"
    />
    <path
      d="M56.2519 59.5434C56.5008 59.5491 55.9872 62.5706 55.9292 62.5595C55.6063 62.5009 41.1748 64.9068 41.1748 64.9068L40.8606 63.7512C40.8606 63.7512 54.8619 59.5097 56.2519 59.5434Z"
      fill="#CC7A63"
    />
    <path
      d="M34.4387 56.3806L35.6085 55.4946C35.6085 55.4946 51.4467 77.8569 69.9089 73.7741C87.4463 69.896 82.5677 39.9742 82.5677 39.9742L84.1552 40.6581C84.1552 40.6581 88.2496 79.3994 64.4358 75.8766C40.622 72.3537 34.4387 56.3806 34.4387 56.3806Z"
      fill="#007CFF"
    />
    <path
      opacity="0.7"
      d="M34.4387 56.3806L35.6085 55.4946C35.6085 55.4946 51.4467 77.8569 69.9089 73.7741C87.4463 69.896 82.5677 39.9742 82.5677 39.9742L84.1552 40.6581C84.1552 40.6581 88.2496 79.3994 64.4358 75.8766C40.622 72.3537 34.4387 56.3806 34.4387 56.3806Z"
      fill="white"
    />
    <path
      d="M68.9312 76.1295C65.9172 76.29 63.1439 76.3323 60.5925 76.2837H60.5903C57.5087 76.2267 54.7524 76.0366 52.3002 75.7599C50.714 75.5804 49.2545 75.3649 47.9154 75.1263H47.9112C45.3914 74.6743 43.2983 74.1378 41.5895 73.5992C39.8449 73.0479 38.4974 72.4945 37.5068 72.0256C35.9607 71.2927 35.2743 70.7626 35.2743 70.7626C34.6216 68.9292 34.2667 66.9987 34.0978 65.1696C33.6563 60.4427 34.4378 56.381 34.4378 56.381C34.4378 56.381 37.0168 59.8006 41.4396 63.7461C42.0099 64.2551 42.6118 64.7725 43.2433 65.2921C49.2608 70.2557 57.9333 75.4452 67.8814 75.4452C86.5464 75.4452 84.2653 42.122 84.1597 40.7047C84.3287 42.0987 88.0862 75.1157 68.9312 76.1295Z"
      fill="#0065A1"
    />
    <path
      opacity="0.3"
      d="M41.5897 73.5992C42.2191 72.9655 42.5 72.7247 41.3404 72.353C40.0373 71.9369 38.884 71.2103 38.9601 70.1944C39.034 69.1784 38.1152 69.2418 37.7815 68.2069C37.4457 67.174 37.1014 65.6512 37.1014 65.6512C37.1014 65.6512 35.4413 65.6702 35.5257 64.2487C35.6081 62.8273 36.6938 61.505 35.6926 60.5377C34.6893 59.5703 34.438 56.7295 34.438 56.7295C34.438 56.7295 37.6759 61.7332 37.1014 62.5316C36.5269 63.3299 36.1932 64.4198 37.6147 65.1696C39.034 65.9194 38.4489 66.2721 38.4489 67.1825C38.4489 68.0928 38.7003 68.133 39.3677 68.7814C40.0373 69.4298 40.1196 70.0972 39.9528 70.5999C39.7859 71.1005 42.1663 71.7046 43.3575 71.9453C44.5488 72.1861 43.9638 72.9486 44.2151 73.3203C44.3502 73.521 46.2237 74.3807 47.9113 75.1262C45.3915 74.6743 43.2984 74.1378 41.5897 73.5992Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M60.5902 76.2836C57.5086 76.2266 54.7522 76.0365 52.3 75.7598C51.2968 75.481 50.5617 75.2444 50.6294 75.0945C50.8237 74.6636 49.7296 73.1049 48.8932 72.7014C48.0589 72.3001 46.1368 70.5154 46.403 70.2197C46.6691 69.9219 44.9668 68.5109 43.8726 67.8604C42.7764 67.212 42.5441 66.8381 42.5631 66.0883C42.5695 65.8159 42.0731 64.8548 41.4395 63.746C42.0098 64.255 42.6117 64.7725 43.2432 65.292C43.8621 66.12 44.3732 66.8149 44.633 67.1824C45.6342 68.6123 47.7231 69.4297 47.6365 70.2197C47.5499 71.0096 48.8108 71.7679 50.6822 72.7014C52.5535 73.635 52.3191 73.9412 52.5704 74.5263C52.8218 75.1114 55.5781 74.7777 57.8339 75.0945C58.8773 75.2402 59.8489 75.764 60.5902 76.2836Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M37.5069 72.0256C35.9608 71.2927 35.2744 70.7625 35.2744 70.7625C34.6217 68.9292 34.2669 66.9987 34.0979 65.1696C34.7442 66.0862 35.5806 67.0895 35.3568 67.759C35.1349 68.4265 35.6356 69.377 36.0263 69.793C36.417 70.2091 37.9863 71.1807 37.5069 72.0256Z"
      fill="black"
    />
    <path
      d="M83.8624 49.3551C83.4764 49.1287 82.4908 49.0556 81.4497 49.049V50.0945C81.6534 50.1294 81.7776 50.1806 81.7684 50.2546C81.7215 50.6323 78.4258 53.3627 78.4258 53.3627L78.7381 56.2464C78.7381 56.2464 84.8233 49.9187 83.8624 49.3551Z"
      fill="#CC7A63"
    />
  </svg>
);

export default Svg;
