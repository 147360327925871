import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 16, height = 11, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.398 7.6624C13.2707 7.6624 13.1886 7.6624 13.1066 7.6624C10.4911 7.66235 7.87565 7.67037 5.26024 7.65986C4.09795 7.66132 2.96825 7.27575 2.04938 6.56398C1.13052 5.85222 0.474783 4.85478 0.185635 3.72902C0.0868012 3.33055 0.0298927 2.92283 0.0158394 2.51252C-0.0123379 1.89281 0.005103 1.2709 0.00806135 0.649942C0.00973222 0.29913 0.190454 0.110927 0.509237 0.113835C0.816527 0.116638 0.98809 0.300092 0.994161 0.640763C1.00742 1.3854 0.979197 2.13309 1.03698 2.87415C1.18914 4.82547 2.90835 6.50361 4.85748 6.64275C5.09717 6.65986 5.33796 6.66902 5.57825 6.66919C8.07729 6.67107 10.5763 6.67145 13.0754 6.67035L13.4144 6.67035C13.3173 6.56746 13.2634 6.50865 13.2077 6.45167C12.5184 5.74708 11.8285 5.04303 11.1381 4.33953C11.0524 4.27148 10.9906 4.17787 10.9616 4.0723C10.9327 3.96673 10.9381 3.85469 10.9772 3.75244C11.0067 3.67376 11.0562 3.60414 11.1208 3.55042C11.1854 3.4967 11.2629 3.46074 11.3456 3.44608C11.4284 3.43142 11.5135 3.43858 11.5926 3.46683C11.6717 3.49509 11.7421 3.54348 11.7969 3.60723C12.2053 4.01091 12.6028 4.42571 13.0056 4.83503C13.5828 5.42137 14.1601 6.00748 14.7377 6.59337C14.8195 6.66315 14.8853 6.74978 14.9306 6.84733C14.9759 6.94487 14.9995 7.05105 15 7.15859C15.0005 7.26612 14.9777 7.3725 14.9333 7.47043C14.8889 7.56837 14.8238 7.65556 14.7426 7.72605C13.7757 8.71283 12.8069 9.69768 11.8361 10.6806C11.5912 10.9289 11.3127 10.9521 11.098 10.7515C10.8774 10.5455 10.8871 10.248 11.1349 9.99326C11.8277 9.28123 12.5224 8.57114 13.2192 7.86296C13.2706 7.81043 13.3172 7.75328 13.398 7.6624Z"
      fill="#137BC6"
    />
  </svg>
);

export default Svg;
