import { createApi } from '@reduxjs/toolkit/query/react';

import { moodType, UserInterface } from '../../interfaces/user.interface';
import { axiosBaseQuery } from '../../utils/axios';

interface IWidgetsParams {
  end_birthday_date?: string;
}

export const widgetsApi = createApi({
  reducerPath: 'widgetsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Widgets'],
  endpoints: (builder) => ({
    getWidgets: builder.query<
      {
        upcoming_birthdays: UserInterface[];
        mood_list: moodType[];
        upcoming_anniversaries: UserInterface[];
      },
      IWidgetsParams
    >({
      query: (params: IWidgetsParams) => ({
        url: '/widgets',
        params,
      }),
      providesTags: (result) =>
        result?.upcoming_birthdays
          ? result.upcoming_birthdays.map(({ id }: { id: string }) => ({
              type: 'Widgets',
              id,
            }))
          : [],
    }),
    sendMood: builder.mutation<{ message: string }, { mood: moodType }>({
      query: (data) => {
        return {
          url: '/widgets/mood',
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const {
  useGetWidgetsQuery,
  useLazyGetWidgetsQuery,
  useSendMoodMutation,
} = widgetsApi;
