import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateRosterProjectDto,
  IRosterProject,
  UpdateRosterProjectDto,
} from '../../interfaces/roster-project.interface';
import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';

export const rosterProjectApi = createApi({
  reducerPath: 'rosterProjectApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    createRosterProject: builder.mutation<
      IRosterProject,
      CreateRosterProjectDto
    >({
      query: (data) => {
        return {
          url: '/roster-project',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Project', id: 'LIST' }],
    }),

    getRosterProjects: builder.query<IRosterProject[], unknown>({
      query: () => ({
        url: '/roster-project',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: { id: string }) => ({
                type: 'Project' as const,
                id,
              })),
              { type: 'Project', id: 'LIST' },
            ]
          : [{ type: 'Project', id: 'LIST' }],
      keepUnusedDataFor: 0,
      transformResponse: (rawResult: { data: IRosterProject[] }) => {
        return rawResult.data;
      },
    }),

    deleteProject: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => {
        return {
          url: `/roster-project/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, data) => [{ type: 'Project', id: data.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Project removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Project removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updateRosterProject: builder.mutation<
      IRosterProject,
      Partial<UpdateRosterProjectDto>
    >({
      query: (data) => {
        const { id, ...rest } = data;
        return {
          url: `/roster-project/${id}`,
          method: 'PATCH',
          data: rest,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Project', id: arg.id },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Project updated successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Project updating error',
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useCreateRosterProjectMutation,
  useGetRosterProjectsQuery,
  useDeleteProjectMutation,
  useUpdateRosterProjectMutation,
} = rosterProjectApi;
