import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import {
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
} from './local-storage-actions';

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 10000,
  headers: {
    'content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_MASTER_KEY || '',
  },
};

export const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    // if (config.headers.Authorization === false) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   const { Authorization, ...rest } = config.headers;
    //   return {
    //     ...config,
    //     headers: { ...rest },
    //   };
    // }
    if (config.headers?.Authorization) return config;

    const token = getTokenFromLocalStorage();
    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response.status === 401 &&
      error?.response.statusText === 'Unauthorized'
    ) {
      removeTokenFromLocalStorage();
    }
    return Promise.reject(error);
  },
);

export const http = async <T>({
  url,
  method = 'GET',
  params,
  data,
  ...rest
}: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
  instance({ url, method, params, data, ...rest });

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async ({ url, method, data, params, ...rest }) => {
    try {
      const result = await instance({ url, method, data, params, ...rest });
      return { data: result.data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data,
          },
        };
      }
      throw new Error('Unknown error');
    }
  };
