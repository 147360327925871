import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9345_14795)">
      <path
        d="M14.9999 11.6013C14.9322 11.8172 14.8439 12.0261 14.7362 12.2251C14.6201 12.3954 14.4638 12.5345 14.281 12.63C14.0983 12.7254 13.8948 12.7743 13.6887 12.7722C13.6594 12.7736 13.6301 12.7731 13.6008 12.7731C9.53508 12.7731 5.46935 12.7733 1.40362 12.7735C1.18582 12.7837 0.968819 12.7407 0.771293 12.6484C0.573768 12.5561 0.401637 12.4171 0.269715 12.2435C0.0905698 12.0087 -0.0038323 11.7201 0.00186558 11.4247C0.000400575 8.80863 0.0007462 6.19251 0.00290245 3.57639C-0.00170503 3.3949 0.0312547 3.21441 0.0997127 3.04627C0.168171 2.87812 0.270649 2.72594 0.400714 2.59928C0.53078 2.47262 0.685624 2.37421 0.855528 2.31023C1.02543 2.24626 1.20673 2.2181 1.38803 2.22751C3.07191 2.22562 4.7558 2.22542 6.43969 2.22693C8.82641 2.22693 11.2131 2.22697 13.5999 2.22703C13.8626 2.21122 14.124 2.27505 14.3499 2.41018C14.5757 2.5453 14.7556 2.74543 14.8659 2.9844C14.9211 3.11887 14.9659 3.25739 14.9999 3.39872V11.6013ZM1.52272 3.11195C1.54728 3.143 1.55529 3.15552 1.56556 3.16575C3.22661 4.81839 4.88902 6.46964 6.55282 8.11949C6.69532 8.25912 6.86519 8.36773 7.05173 8.4385C7.29694 8.53522 7.56604 8.55384 7.82224 8.49182C8.07844 8.4298 8.30923 8.29017 8.48307 8.09201C10.1205 6.46413 11.7575 4.83577 13.3941 3.20692C13.4196 3.18149 13.4431 3.15396 13.4818 3.11196L1.52272 3.11195ZM5.29297 8.09835L1.57916 11.8836H13.4157L9.72771 8.07044C9.55293 8.255 9.43577 8.38588 9.31081 8.50883C9.1631 8.66457 9.00585 8.81099 8.83998 8.94724C8.41433 9.27984 7.88048 9.4428 7.34165 9.40462C6.80282 9.36644 6.29728 9.12984 5.92278 8.74055C5.71714 8.54044 5.52059 8.33099 5.29298 8.09835L5.29297 8.09835ZM4.58191 7.52508L0.891174 3.77512V11.2851C2.15575 9.9968 3.39228 8.73705 4.58191 7.52508ZM14.1158 3.76746L14.0838 3.74658L10.3555 7.53431L14.1158 11.271V3.76746Z"
        fill="#137BC6"
      />
    </g>
    <defs>
      <clipPath id="clip0_9345_14795">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Svg;
