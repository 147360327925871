import { Suspense, FC, PropsWithChildren, useMemo, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Text } from '../../components/typography/typography';
import { breakpoints } from '../../constants/breakpoints';
import { useResizeParams } from '../../components/hooks/useResizeParams';
import { BaseLayout } from '../../components/layout/base';
import { SimpleLayout } from '../../components/layout/simple';
import { URLS } from '../../constants/urls';
import { getTokenFromLocalStorage } from '../../utils/local-storage-actions';
import { useLazyGetMeQuery } from '../../store/user/api';
import { useLazyGetPermissionsQuery } from '../../store/permissions/api';
import { ROUTES } from '..';
import { PERMISSION_AREA } from '../../interfaces/permission.interface';

import classes from './app.module.scss';

const RequireAuth: FC<PropsWithChildren<{ privateRoute: boolean }>> = ({
  privateRoute,
  children,
}) => {
  const token = getTokenFromLocalStorage();
  const location = useLocation();

  if (privateRoute && !token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={URLS.auth} state={{ from: location }} />;
  }

  return <>{children}</>;
};

const App: FC = () => {
  const token = getTokenFromLocalStorage();
  const { pathname } = useLocation();
  const [getMe, { isLoading }] = useLazyGetMeQuery();
  const [
    getPermissions,
    { data: permissionsData, isLoading: permissionsIsLoading },
  ] = useLazyGetPermissionsQuery();
  const isGooglePage = pathname === URLS.googleAuth;

  useEffect(() => {
    if ((isGooglePage && token) || !isGooglePage) {
      const getData = async () => {
        await getPermissions({
          page: true,
        });
      };
      getData();
    }
  }, [getPermissions, pathname, token, isGooglePage]);

  const routesByPermissions = useMemo(
    () =>
      ROUTES.filter((route) => {
        if (route.page) {
          if (!permissionsData) return false;
          return permissionsData[route.page as PERMISSION_AREA].read;
        }
        return route;
      }),
    [permissionsData],
  );

  const { width } = useResizeParams();

  useEffect(() => {
    if ((isGooglePage && token) || !isGooglePage) {
      getMe(undefined);
    }
  }, [getMe, pathname, token, isGooglePage]);

  const mobileFriendlyPages: string[] = [
    URLS.requestVacation,
    URLS.auth,
    URLS.googleAuth,
  ];

  if (isLoading || permissionsIsLoading) return <span>Loading...</span>;
  if (width < breakpoints.min && !mobileFriendlyPages.includes(pathname)) {
    return (
      <div className={classes['not-supported']}>
        <Text type="title" as="h1">
          Mobile version is not supported
        </Text>
      </div>
    );
  }

  return (
    <Routes>
      {routesByPermissions.map((el) => {
        const Layout = el.layoutType === 'simple' ? SimpleLayout : BaseLayout;

        return (
          <Route
            key={el.id}
            path={el.path}
            element={
              <Suspense fallback={null}>
                <RequireAuth privateRoute={el.protected}>
                  <Layout>
                    <el.component />
                  </Layout>
                </RequireAuth>
              </Suspense>
            }
          />
        );
      })}
    </Routes>
  );
};

export default App;
