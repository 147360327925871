import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import {
  IGetRoleListRequest,
  TGetRoleListResponse,
  IPathUpdateRoleRequest,
  ICreateRole,
  IRole,
} from '../../interfaces/role';
import { addNotification } from '../app/reducer';
import { usersApi } from '../user/api';
import { UserInterface } from '../../interfaces/user.interface';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Role'],
  endpoints: (builder) => ({
    getRoleList: builder.query<TGetRoleListResponse, IGetRoleListRequest>({
      query: (params) => ({ url: '/role', params }),
      providesTags: (result) =>
        result ? result.rows.map(({ id }) => ({ type: 'Role', id })) : ['Role'],
    }),

    createRole: builder.mutation<{ data: IRole }, ICreateRole>({
      query: (data) => {
        return {
          url: '/role',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => ['Role'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Role created successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message || 'Role creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updateRole: builder.mutation<UserInterface, IPathUpdateRoleRequest>({
      query: (data) => ({
        url: `/role/${data.id}`,
        method: 'PATCH',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;

          dispatch(
            usersApi.util.invalidateTags([{ type: 'User', id: 'USERS-LIST' }]),
          );

          dispatch(
            addNotification({
              title: 'Role is successfully updated',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Role', id }],
    }),
    deleteRole: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => {
        return {
          url: `/role/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Role'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            addNotification({
              title: data.message,
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useGetRoleListQuery,
  useDeleteRoleMutation,
} = roleApi;
