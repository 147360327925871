import { SvgIconType } from './svg.interface';

const ArrowDownUpIcon: SvgIconType = ({
  width = 24,
  height = 24,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 2L7.29289 1.29289L8 0.585787L8.70711 1.29289L8 2ZM9 21C9 21.5523 8.55228 22 8 22C7.44771 22 7 21.5523 7 21L9 21ZM3.29289 5.29289L7.29289 1.29289L8.70711 2.70711L4.70711 6.70711L3.29289 5.29289ZM8.70711 1.29289L12.7071 5.29289L11.2929 6.70711L7.29289 2.70711L8.70711 1.29289ZM9 2L9 21L7 21L7 2L9 2Z"
      fill="#137BC6"
    />
    <path
      d="M16 22L15.2929 22.7071L16 23.4142L16.7071 22.7071L16 22ZM17 13C17 12.4477 16.5523 12 16 12C15.4477 12 15 12.4477 15 13L17 13ZM11.2929 18.7071L15.2929 22.7071L16.7071 21.2929L12.7071 17.2929L11.2929 18.7071ZM16.7071 22.7071L20.7071 18.7071L19.2929 17.2929L15.2929 21.2929L16.7071 22.7071ZM17 22L17 13L15 13L15 22L17 22Z"
      fill="#137BC6"
    />
  </svg>
);

export default ArrowDownUpIcon;
