import { memo } from 'react';
import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 24, height = 24, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 6L12.6 13.05C12.4269 13.1798 12.2164 13.25 12 13.25C11.7836 13.25 11.5731 13.1798 11.4 13.05L3 6M21 6C21 5.44772 20.5523 5 20 5H4C3.44772 5 3 5.44772 3 6M21 6V18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18V6"
      stroke="#2F2F38"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(Svg);
