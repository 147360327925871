import { SvgIconType } from './svg.interface';

const DatePickerIcon: SvgIconType = ({
  width = 15,
  height = 15,
  className,
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <rect
      x="1.875"
      y="3.75"
      width="11.25"
      height="9.375"
      rx="2"
      stroke="#137BC6"
      strokeWidth="1.2"
    />
    <path
      d="M4.375 1.875L4.375 3.75"
      stroke="#137BC6"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M10.625 1.875L10.625 3.75"
      stroke="#137BC6"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <rect x="4.375" y="7.5" width="2.5" height="1.25" rx="0.5" fill="#137BC6" />
    <rect x="4.375" y="10" width="2.5" height="1.25" rx="0.5" fill="#137BC6" />
    <rect x="8.125" y="7.5" width="2.5" height="1.25" rx="0.5" fill="#137BC6" />
    <rect x="8.125" y="10" width="2.5" height="1.25" rx="0.5" fill="#137BC6" />
  </svg>
);

export default DatePickerIcon;
