import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { ISingleElement } from '../../interfaces/common.interface';
import { addNotification } from '../app/reducer';
import {
  IMonthlyReportCreate,
  IReportInfo,
} from '../../interfaces/report.interface';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    sendMonthlyReport: builder.mutation<
      ISingleElement<IReportInfo>,
      IMonthlyReportCreate
    >({
      query: (data: IMonthlyReportCreate) => ({
        url: '/monthly-report',
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Report sent successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Report sending error',
              type: 'error',
            }),
          );
        }
      },
    }),
    getLastMonthlyReport: builder.query<ISingleElement<IReportInfo>, {}>({
      query: () => ({
        url: '/monthly-report/latest',
      }),
    }),
  }),
});

export const { useSendMonthlyReportMutation, useGetLastMonthlyReportQuery } =
  reportApi;
