import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';

import {
  ICreateTechnologyRequest,
  IGetSingleTechnologyRequest,
  ITechnology,
  IUpdateTechnologyRequest,
} from '../../interfaces/technology.interface';
import { IQueryParams, PageDto } from '../../interfaces/common.interface';

export const technologyApi = createApi({
  reducerPath: 'technologyApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Technology'],
  endpoints: (builder) => ({
    getTechnologies: builder.query<PageDto<ITechnology>, IQueryParams>({
      query: (params) => ({
        url: '/technology',
        params,
      }),
      providesTags: (result) =>
        result
          ? result.data.map(({ id }: { id: string }) => ({
              type: 'Technology',
              id,
            }))
          : [],
    }),

    getSingleTechnology: builder.query<
      ITechnology,
      IGetSingleTechnologyRequest
    >({
      query: ({ id }) => ({
        url: `/technology/${id}`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Technology', id: result.id }] : [],
    }),

    createTechnology: builder.mutation<
      { data: ITechnology },
      ICreateTechnologyRequest
    >({
      query: (data) => ({
        url: '/technology',
        method: 'POST',
        data,
      }),
      invalidatesTags: () => ['Technology'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Technology was created successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message || 'Technology creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    deleteTechnology: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => ({
        url: `/technology/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Technology', id: data.id },
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Technology removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Technology removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updateTechnology: builder.mutation<ITechnology, IUpdateTechnologyRequest>({
      query: (data) => {
        return {
          url: `/technology/${data.id}`,
          method: 'PATCH',
          data: data.value,
        };
      },
      invalidatesTags: () => ['Technology'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Technology was updated successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message,
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetTechnologiesQuery,
  useGetSingleTechnologyQuery,
  useCreateTechnologyMutation,
  useDeleteTechnologyMutation,
  useUpdateTechnologyMutation,
} = technologyApi;
