import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 50, height = 50, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 46"
    fill="#00f260"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity="0.5">
      <path
        d="M45.3305 2.64954H4.66946C2.09864 2.64954 0 4.74817 0 7.319V32.765V42.681C0 45.2518 2.09864 47.3505 4.66946 47.3505H45.3305C47.9014 47.3505 50 45.2518 50 42.681V7.319C50 4.74817 47.9014 2.64954 45.3305 2.64954ZM47.639 42.681C47.639 43.9402 46.5897 44.9895 45.2781 44.9895H4.66946C3.35782 44.9895 2.3085 43.9402 2.3085 42.6285V33.1847L15.4774 19.7534L27.3872 33.447C27.8069 33.9192 28.4365 33.9717 28.9612 33.6569L38.6149 26.8363L47.639 33.3421V42.681ZM47.6915 30.5089L39.297 24.4754C38.8772 24.1606 38.3526 24.1606 37.9328 24.4754L28.489 31.191L16.4218 17.34C16.212 17.0777 15.8972 16.9727 15.5824 16.9203C15.2676 16.9203 14.9528 17.0252 14.6905 17.2875L2.3085 29.8793V7.319C2.3085 6.05982 3.35782 5.0105 4.66946 5.0105H45.3305C46.6422 5.0105 47.6915 6.05982 47.6915 7.37147V30.5089Z"
        fill="#18A0FB"
      />
      <path
        d="M32.1085 9.62744C28.7507 9.62744 26.075 12.3557 26.075 15.661C26.0225 18.9664 28.7507 21.6946 32.1085 21.6946C35.4664 21.6946 38.1421 18.9664 38.1421 15.661C38.1421 12.3032 35.4139 9.62744 32.1085 9.62744ZM32.1085 19.3861C30.0624 19.3861 28.3835 17.7072 28.3835 15.661C28.3835 13.5624 30.0624 11.9359 32.1085 11.9359C34.1547 11.9359 35.8336 13.6148 35.8336 15.661C35.8336 17.7072 34.1547 19.3861 32.1085 19.3861Z"
        fill="#18A0FB"
      />
    </g>
  </svg>
);

export default Svg;
