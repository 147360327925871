import { SvgIconType } from './svg.interface';

const FolderIcon: SvgIconType = ({ width = 90, height = 81, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 90 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21.182 60.0095H68.818C69.4708 60.0095 70 59.4802 70 58.8274V24.1223C70 23.4695 69.4708 22.9403 68.818 22.9403H44.4663C44.0307 22.9403 43.6304 22.7007 43.4246 22.3168L42.5547 20.6947C42.3489 20.3108 41.9486 20.0713 41.513 20.0713H21.182C20.5292 20.0713 20 20.6005 20 21.2533V58.8274C20 59.4802 20.5292 60.0095 21.182 60.0095Z"
      fill="#FFC93E"
    />
    <rect
      x="22.1277"
      y="24.3855"
      width="44.6809"
      height="33.6516"
      rx="1.18203"
      fill="black"
      fillOpacity="0.05"
    />
    <rect
      x="22.6006"
      y="25.0022"
      width="44.6809"
      height="33.6516"
      rx="1.18203"
      fill="white"
    />
    <path
      d="M68.818 59.6396H21.182C20.5292 59.6396 20 59.1103 20 58.4575V31.5391C20 30.8863 20.5292 30.3571 21.182 30.3571H45.5486C46.0231 30.3571 46.4517 30.0733 46.6369 29.6364L47.5643 27.4483C47.7495 27.0113 48.1781 26.7275 48.6526 26.7275H68.818C69.4708 26.7275 70 27.2568 70 27.9096V58.4575C70 59.1103 69.4708 59.6396 68.818 59.6396Z"
      fill="black"
      fillOpacity="0.06"
    />
    <path
      d="M68.818 60.0099H21.182C20.5292 60.0099 20 59.4807 20 58.8279V31.9095C20 31.2567 20.5292 30.7274 21.182 30.7274H45.5486C46.0231 30.7274 46.4517 30.4436 46.6369 30.0067L47.5643 27.8186C47.7495 27.3817 48.1781 27.0979 48.6526 27.0979H68.818C69.4708 27.0979 70 27.6271 70 28.2799V58.8279C70 59.4807 69.4708 60.0099 68.818 60.0099Z"
      fill="#FFAA2B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.1326 60.0716H68.7588C69.4116 60.0716 69.9408 59.5424 69.9408 58.8895V42.5537C61.5281 51.8363 50.0051 57.2237 40.1326 60.0716Z"
      fill="#FFA114"
    />
  </svg>
);

export default FolderIcon;
