import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 23, height = 23, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M27.4671 21.7449C26.8585 22.5112 26.1747 23.2187 25.3895 23.8573C29.8719 26.4285 32.8981 31.2625 32.8981 36.7901C32.8981 39.7768 26.354 42.1165 17.9999 42.1165C9.64585 42.1165 3.10176 39.7768 3.10176 36.7901C3.10176 31.2625 6.12793 26.4285 10.6104 23.8573C9.82521 23.2187 9.14149 22.5113 8.5328 21.7449C3.54104 24.8972 0.218262 30.4625 0.218262 36.7901C0.218262 38.7068 1.26781 41.3781 6.26815 43.2192C9.38721 44.3676 13.5537 45 18 45C22.4464 45 26.6128 44.3676 29.7319 43.2192C34.7321 41.3781 35.7818 38.7068 35.7818 36.7901C35.7816 30.4625 32.4588 24.8972 27.4671 21.7449Z"
      fill="white"
    />
    <path
      d="M17.9994 23.6449C23.6034 23.6449 27.9187 18.228 27.9187 11.8225C27.9187 5.41409 23.6012 0 17.9994 0C12.3954 0 8.08008 5.41694 8.08008 11.8224C8.08008 18.2308 12.3977 23.6449 17.9994 23.6449ZM17.9994 2.8835C21.8789 2.8835 25.0352 6.89345 25.0352 11.8224C25.0352 16.7514 21.8789 20.7613 17.9994 20.7613C14.1199 20.7613 10.9636 16.7514 10.9636 11.8224C10.9636 6.89345 14.1199 2.8835 17.9994 2.8835Z"
      fill="white"
    />
  </svg>
);

export default Svg;
