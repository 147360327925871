import { SvgIconType } from './svg.interface';

const HrIcon: SvgIconType = ({ width = 80, height = 80, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M30 39.9998C33.9556 39.9998 37.8224 38.8268 41.1114 36.6292C44.4004 34.4315 46.9638 31.308 48.4776 27.6534C49.9913 23.9989 50.3874 19.9776 49.6157 16.098C48.844 12.2183 46.9392 8.65468 44.1421 5.85763C41.3451 3.06058 37.7814 1.15576 33.9018 0.384059C30.0222 -0.387645 26.0009 0.00842158 22.3463 1.52217C18.6918 3.03593 15.5682 5.59938 13.3706 8.88836C11.173 12.1773 10 16.0441 10 19.9998C10.0053 25.3025 12.1141 30.3865 15.8637 34.1361C19.6133 37.8856 24.6973 39.9945 30 39.9998ZM30 6.66643C32.6371 6.66643 35.2149 7.44842 37.4076 8.9135C39.6003 10.3786 41.3092 12.461 42.3184 14.8973C43.3276 17.3337 43.5916 20.0146 43.0771 22.601C42.5627 25.1874 41.2928 27.5632 39.4281 29.4279C37.5634 31.2926 35.1876 32.5624 32.6012 33.0769C30.0148 33.5914 27.3339 33.3273 24.8976 32.3182C22.4612 31.309 20.3788 29.6 18.9137 27.4074C17.4487 25.2147 16.6667 22.6368 16.6667 19.9998C16.6667 16.4635 18.0714 13.0722 20.5719 10.5717C23.0724 8.07119 26.4638 6.66643 30 6.66643Z"
      fill="white"
    />
    <path
      d="M30 46.6655C22.0465 46.6752 14.4215 49.839 8.79751 55.463C3.17352 61.087 0.00970319 68.712 0 76.6654C0 77.5495 0.35119 78.3973 0.976311 79.0225C1.60143 79.6476 2.44928 79.9988 3.33333 79.9988C4.21739 79.9988 5.06523 79.6476 5.69036 79.0225C6.31548 78.3973 6.66667 77.5495 6.66667 76.6654C6.66667 70.4771 9.12499 64.5422 13.5008 60.1663C17.8767 55.7905 23.8116 53.3322 30 53.3322C36.1884 53.3322 42.1233 55.7905 46.4992 60.1663C50.875 64.5422 53.3333 70.4771 53.3333 76.6654C53.3333 77.5495 53.6845 78.3973 54.3096 79.0225C54.9348 79.6476 55.7826 79.9988 56.6667 79.9988C57.5507 79.9988 58.3986 79.6476 59.0237 79.0225C59.6488 78.3973 60 77.5495 60 76.6654C59.9903 68.712 56.8265 61.087 51.2025 55.463C45.5785 49.839 37.9535 46.6752 30 46.6655Z"
      fill="white"
    />
    <path
      d="M73.3329 26.25C71.4778 26.3424 69.7349 27.1659 68.4855 28.5402C67.2361 29.9146 66.5819 31.7279 66.6662 33.5833C66.7505 31.7279 66.0964 29.9146 64.847 28.5402C63.5976 27.1659 61.8546 26.3424 59.9996 26.25C58.1445 26.3424 56.4016 27.1659 55.1522 28.5402C53.9028 29.9146 53.2486 31.7279 53.3329 33.5833C53.3329 39.35 60.8529 46.1067 64.5996 49.1133C65.186 49.5829 65.9149 49.8387 66.6662 49.8387C67.4175 49.8387 68.1464 49.5829 68.7329 49.1133C72.4795 46.1133 79.9995 39.35 79.9995 33.5833C80.0838 31.7279 79.4297 29.9146 78.1803 28.5402C76.9309 27.1659 75.1879 26.3424 73.3329 26.25Z"
      fill="white"
    />
  </svg>
);

export default HrIcon;
