import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 30, height = 30, className }) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.7556 26.4356C50.7556 24.719 50.4694 22.7162 50.1833 21.2856H26.15V31.2995H39.8833C39.3111 34.4467 37.5944 37.0217 34.7333 39.0245V45.6051H43.3167C48.1806 41.0273 50.7556 34.1606 50.7556 26.4356Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.15 51.6132C33.0167 51.6132 39.025 49.3243 43.3167 45.3187L34.7333 39.0243C32.4444 40.4548 29.5833 41.5993 26.15 41.5993C19.5694 41.5993 13.8472 37.0215 11.8444 31.0132H3.26111V37.3076C7.26666 45.8909 16.1361 51.6132 26.15 51.6132Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8444 30.7276C11.2722 29.297 10.9861 27.5804 10.9861 25.8637C10.9861 24.147 11.2722 22.4304 11.8444 20.9998V14.4193H3.26111C1.54444 17.8526 0.399994 21.8582 0.399994 25.8637C0.399994 29.8693 1.25833 33.8748 3.26111 37.3082L11.8444 30.7276Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.15 10.4137C29.8694 10.4137 33.3028 11.8443 35.8778 14.1332L43.3167 6.69426C39.025 2.68871 33.0167 0.113708 26.15 0.113708C16.1361 0.113708 7.26666 5.83593 3.26111 14.4193L11.8444 20.9998C13.8472 14.9915 19.5694 10.4137 26.15 10.4137Z"
      fill="#EA4335"
    />
  </svg>
);

export default Svg;
