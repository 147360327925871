import { SvgIconType } from './svg.interface';

const AddRoleIcon: SvgIconType = ({ width = 47, height = 42, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 47 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13737_39153)">
      <circle
        cx="21.5833"
        cy="17.6663"
        r="3.83333"
        stroke="#45494E"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M27.1381 29.5948C26.8105 28.3722 26.0886 27.2918 25.0844 26.5212C24.0802 25.7507 22.8498 25.333 21.584 25.333C20.3182 25.333 19.0878 25.7507 18.0836 26.5212C17.0794 27.2918 16.3575 28.3722 16.0299 29.5948"
        stroke="#45494E"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M30.209 19.583L30.209 25.333"
        stroke="#45494E"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M33.084 22.458L27.334 22.458"
        stroke="#45494E"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13737_39153">
        <rect width="47" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AddRoleIcon;
