import { memo } from 'react';
import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 24, height = 24, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 3V6M12 3V6M17 3V6M3 9H21M7 13H17M4 5H20C20.5523 5 21 5.44772 21 6V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V6C3 5.44772 3.44772 5 4 5Z"
      stroke="#2F2F38"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(Svg);
