import { memo } from 'react';
import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 30, height = 30, className, ...rest }) => (
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 755.78 560.77"
    width={width}
    height={height}
    className={className}
    {...rest}
  >
    <g>
      <polygon
        fill="#0066DA"
        points="505.79,113.39 469.59,133.29 451.59,167.59 469.59,209.59 503.59,246.39 540.29,225.89 562.89,186.99 
		540.29,138.79 	"
      />
      <path
        fill="#2684FC"
        d="M365.49,253.69l86.2-86.2c25.4,19.3,44,46.9,51.9,78.8l-118.3,118.4c-10.6,10.6-27.9,10.6-38.5,0l-79.7-79.7
		c-8.5-8.5-8.5-22.3,0-30.8l34-34c8.5-8.5,22.3-8.5,30.8,0L365.49,253.69z M601.19,117.99l-34.6-34.6c-8.5-8.5-22.3-8.5-30.8,0
		l-30,30c24,20,43.5,45.1,57.1,73.6l38.3-38.3C609.69,140.29,609.69,126.39,601.19,117.99z M507.69,280.39
		c0,78.2-63.4,141.6-141.6,141.6s-141.6-63.4-141.6-141.6s63.3-141.6,141.6-141.6c32.2,0,61.8,10.7,85.6,28.8l54.2-54.2
		c-37.8-31.8-86.5-50.9-139.8-50.9c-120.4,0.1-217.9,97.6-217.9,217.9s97.5,217.8,217.8,217.8s217.9-97.5,217.9-217.8
		c0-33.4-7.6-65.1-21-93.4l-59.3,59.4C506.19,257.29,507.69,268.69,507.69,280.39z"
      />
    </g>
    <rect x="127.89" y="30.39" fill="none" width="30" height="30" />
  </svg>
);

export default memo(Svg);
