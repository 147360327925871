import { SvgIconType } from './svg.interface';

const SortAscIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 14L10 17L13 14L12.293 13.293L10.5 15.086V3H9.5V15.086L7.707 13.293L7 14Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default SortAscIcon;
