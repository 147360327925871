import { SvgIconType } from './svg.interface';

const ProjectSpaceIcon: SvgIconType = ({
  width = 23,
  height = 23,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.2972 23C11.1915 23 11.0859 23 10.8746 22.895L3.37345 17.5388C2.8452 17.1187 2.4226 16.9087 2.2113 16.4886C2 16.0685 2 15.6484 2 14.9132V6.40639C2 6.19635 2.10565 5.9863 2.31695 5.88128L9.50113 0.840183C10.2407 0.315069 10.6633 0 11.2972 0C11.9311 0 12.3537 0.315069 13.1989 0.840183L20.3831 5.88128C20.5944 5.9863 20.7 6.19635 20.7 6.40639V14.9132C20.7 15.6484 20.7 16.0685 20.4887 16.4886C20.2774 16.9087 19.9605 17.1187 19.3266 17.5388L11.8254 22.895C11.5085 23 11.4028 23 11.2972 23ZM3.2678 7.66667V14.9132C3.2678 15.4384 3.2678 15.7534 3.37345 15.8584C3.4791 15.9635 3.6904 16.1735 4.11299 16.4886L10.6633 21.1096V12.9178L3.2678 7.66667ZM11.9311 12.9178V21.1096L18.4814 16.4886C18.904 16.1735 19.1153 16.0685 19.2209 15.8584C19.3266 15.7534 19.3266 15.4384 19.3266 14.9132V7.66667L11.9311 12.9178ZM3.79605 6.40639L11.2972 11.7626L18.7983 6.40639L12.3537 1.89041C11.8254 1.47032 11.5085 1.26027 11.2972 1.26027C11.0859 1.26027 10.7689 1.47032 10.135 1.89041L3.79605 6.40639Z"
      fill="white"
    />
  </svg>
);

export default ProjectSpaceIcon;
