import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 12, height = 7, className, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.2892 1.68591C11.4256 1.55675 11.5 1.39237 11.5 1.19863C11.5 0.811155 11.1776 0.500001 10.7683 0.500001C10.5637 0.500001 10.3777 0.582193 10.2351 0.717222L5.9938 4.82681L1.76494 0.717222C1.62232 0.582192 1.4301 0.500001 1.23168 0.500001C0.822435 0.500001 0.5 0.811155 0.5 1.19863C0.5 1.39237 0.574408 1.55675 0.710823 1.68591L5.42334 6.25342C5.58456 6.41781 5.78298 6.49413 6 6.5C6.21702 6.5 6.40304 6.41781 6.57046 6.25342L11.2892 1.68591Z"
      fill={color || '#45494E'}
    />
  </svg>
);

export default Svg;
