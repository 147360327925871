import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 15C11.6422 15 15 11.6422 15 7.5C15 3.35786 11.6422 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6422 3.35786 15 7.5 15Z"
      fill="url(#paint0_linear_9345_14745)"
    />
    <path
      d="M6.125 10.9375C5.882 10.9375 5.9233 10.8457 5.8395 10.6144L5.125 8.26294L10.625 5"
      fill="#C8DAEA"
    />
    <path
      d="M6.125 10.9375C6.3125 10.9375 6.39531 10.8518 6.50001 10.75L7.50001 9.77764L6.25263 9.02545"
      fill="#A9C9DD"
    />
    <path
      d="M6.25246 9.02564L9.27496 11.2587C9.61989 11.449 9.86877 11.3504 9.95471 10.9386L11.1851 5.14082C11.311 4.63582 10.9926 4.4067 10.6626 4.55651L3.43814 7.3422C2.94502 7.54001 2.94796 7.81514 3.34827 7.9377L5.20221 8.51639L9.49427 5.80857C9.69689 5.6857 9.88289 5.7517 9.73027 5.8872"
      fill="url(#paint1_linear_9345_14745)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9345_14745"
        x1="10.005"
        y1="2.505"
        x2="6.255"
        y2="11.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37AEE2" />
        <stop offset="1" stopColor="#1E96C8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9345_14745"
        x1="8.4402"
        y1="7.50061"
        x2="9.61067"
        y2="10.1674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF7FC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
