import { SvgIconType } from './svg.interface';

const SortAZIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 14L6 17L9 14L8.293 13.293L6.5 15.086L6.5 3H5.5L5.5 15.086L3.707 13.293L3 14Z"
      fill="#B6B7B8"
    />
    <path
      d="M13.4405 16.0571L16.8911 11.7457V11H12.0586V11.9429H15.4506L12 16.2543V17H17V16.0571H13.4405Z"
      fill="#B6B7B8"
    />
    <path
      d="M16.9249 9H18L15.502 3H14.4901L12 9H13.0593L13.6047 7.61143H16.3715L16.9249 9ZM13.9526 6.73714L14.9881 4.11429L16.0316 6.73714H13.9526Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default SortAZIcon;
