import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Notification } from '../../components/notifications/container';
import { uuidv4 } from '../../utils/uuid';

import { RootState } from '..';
import initialState from '../initial-store';

interface AppState {
  isSidebarOpen: boolean;
  notifications: Notification[];
}

const initialAppState: AppState = initialState.app;

const appSlice = createSlice({
  name: 'app',
  initialState: { ...initialAppState },
  reducers: {
    changeSidebarState(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    addNotification(
      state,
      { payload }: PayloadAction<Omit<Notification, 'id'>>,
    ) {
      state.notifications.push({ ...payload, id: uuidv4() });
    },
    removeNotification(state, { payload }: PayloadAction<{ id: string }>) {
      state.notifications = state.notifications.filter(
        (el) => el.id !== payload.id,
      );
    },
  },
});

export const { changeSidebarState, addNotification, removeNotification } =
  appSlice.actions;

export default appSlice.reducer;

const selectSelf = (state: RootState) => state;
export const getSidebarState = createSelector(
  selectSelf,
  (state: RootState) => state.app.isSidebarOpen,
);

export const getNotificationsSelector = createSelector(
  selectSelf,
  (state: RootState) => state.app.notifications,
);
