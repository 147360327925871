import { SvgIconType } from './svg.interface';

const StrikethroughIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_71)">
      <path
        d="M12.8655 10.5C13.038 10.887 13.125 11.3175 13.125 11.79C13.125 12.7965 12.732 13.584 11.9467 14.1503C11.16 14.7165 10.0748 15 8.6895 15C7.4595 15 6.24225 14.7143 5.037 14.142V12.45C6.177 13.1078 7.34325 13.437 8.5365 13.437C10.4498 13.437 11.409 12.888 11.4157 11.7892C11.4197 11.5665 11.3787 11.3452 11.2952 11.1386C11.2117 10.9321 11.0874 10.7444 10.9297 10.587L10.8398 10.4992H2.25V8.99925H15.75V10.4992H12.8655V10.5ZM9.807 8.25H5.72175C5.59037 8.13021 5.46967 7.99922 5.361 7.8585C5.037 7.44 4.875 6.9345 4.875 6.339C4.875 5.412 5.2245 4.62375 5.92275 3.97425C6.6225 3.32475 7.70325 3 9.1665 3C10.2698 3 11.3258 3.246 12.333 3.738V5.352C11.433 4.83675 10.4468 4.5795 9.3735 4.5795C7.5135 4.5795 6.58425 5.166 6.58425 6.339C6.58425 6.654 6.74775 6.9285 7.07475 7.16325C7.40175 7.398 7.80525 7.58475 8.2845 7.72575C8.7495 7.86075 9.25725 8.03625 9.807 8.25V8.25Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_71">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StrikethroughIcon;
