import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48205 0C3.35037 0 0 3.44279 0 7.68988C0 11.0875 2.14383 13.97 5.11668 14.9868C5.49061 15.0579 5.62789 14.82 5.62789 14.6169C5.62789 14.4334 5.62096 13.8277 5.61773 13.1852C3.53622 13.6503 3.09699 12.2778 3.09699 12.2778C2.75665 11.3891 2.26625 11.1527 2.26625 11.1527C1.58741 10.6754 2.31742 10.6852 2.31742 10.6852C3.06875 10.7393 3.46435 11.4778 3.46435 11.4778C4.13168 12.6532 5.21469 12.3133 5.64164 12.1169C5.7088 11.6199 5.90269 11.2805 6.11668 11.0886C4.45481 10.8942 2.70782 10.2348 2.70782 7.28824C2.70782 6.44869 3.0001 5.7627 3.47872 5.22418C3.40105 5.03036 3.14494 4.24835 3.55121 3.18914C3.55121 3.18914 4.17951 2.98246 5.6093 3.97738C6.20613 3.80699 6.84619 3.72154 7.48205 3.71861C8.1179 3.72154 8.75846 3.80699 9.3564 3.97738C10.7845 2.98246 11.4119 3.18914 11.4119 3.18914C11.8192 4.24835 11.563 5.03036 11.4852 5.22418C11.9649 5.7627 12.2553 6.44869 12.2553 7.28824C12.2553 10.2418 10.505 10.8921 8.83887 11.0825C9.10724 11.3211 9.34636 11.7892 9.34636 12.5066C9.34636 13.5355 9.33769 14.3635 9.33769 14.6169C9.33769 14.8215 9.47237 15.0613 9.85162 14.9857C12.8229 13.9678 14.9639 11.0864 14.9639 7.68988C14.9639 3.44279 11.6141 0 7.48205 0Z"
      fill="#1B1817"
    />
    <path
      d="M2.83393 11.0409C2.81745 11.0793 2.75897 11.0906 2.70569 11.0644C2.65143 11.0394 2.62094 10.9873 2.63853 10.949C2.65464 10.9097 2.71325 10.8987 2.76739 10.9249C2.82179 10.9501 2.85276 11.0027 2.83393 11.0409Z"
      fill="#1B1817"
    />
    <path
      d="M3.13689 11.3883C3.10122 11.4224 3.03146 11.4066 2.98413 11.3528C2.93519 11.2993 2.92602 11.2275 2.9622 11.193C2.99899 11.1592 3.06665 11.1751 3.11571 11.2285C3.16465 11.2828 3.17419 11.354 3.13689 11.3883Z"
      fill="#1B1817"
    />
    <path
      d="M3.43187 11.8313C3.38603 11.864 3.31107 11.8333 3.26473 11.7649C3.21888 11.6966 3.21889 11.6145 3.26572 11.5817C3.31218 11.5489 3.38603 11.5784 3.43298 11.6463C3.4787 11.7157 3.4787 11.7978 3.43187 11.8313Z"
      fill="#1B1817"
    />
    <path
      d="M3.83605 12.2592C3.79504 12.3056 3.70769 12.2931 3.64376 12.2297C3.57834 12.1677 3.56013 12.0797 3.60126 12.0332C3.64276 11.9867 3.73061 11.9998 3.79504 12.0626C3.85996 12.1246 3.87978 12.2131 3.83605 12.2592Z"
      fill="#1B1817"
    />
    <path
      d="M4.39354 12.5076C4.37545 12.5678 4.29132 12.5952 4.20657 12.5696C4.12195 12.5432 4.06656 12.4727 4.08367 12.4118C4.10125 12.3511 4.18576 12.3227 4.27112 12.35C4.35563 12.3762 4.41114 12.4464 4.39354 12.5076Z"
      fill="#1B1817"
    />
    <path
      d="M5.00584 12.5536C5.00795 12.6171 4.93608 12.6697 4.84713 12.6708C4.75767 12.6728 4.68531 12.6216 4.68433 12.5592C4.68433 12.4951 4.75457 12.443 4.84403 12.4415C4.93299 12.4397 5.00584 12.4906 5.00584 12.5536Z"
      fill="#1B1817"
    />
    <path
      d="M5.57559 12.454C5.58624 12.5158 5.52442 12.5794 5.43608 12.5964C5.34922 12.6126 5.26882 12.5745 5.2578 12.513C5.24701 12.4496 5.30995 12.386 5.39669 12.3697C5.48515 12.3539 5.56432 12.391 5.57559 12.454Z"
      fill="#1B1817"
    />
  </svg>
);

export default Svg;
