import { SvgIconType } from './svg.interface';

const AlignRightIcon: SvgIconType = ({
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6_89)">
      <path
        d="M2.25 3H15.75V4.5H2.25V3ZM5.25 14.25H15.75V15.75H5.25V14.25ZM2.25 10.5H15.75V12H2.25V10.5ZM5.25 6.75H15.75V8.25H5.25V6.75Z"
        fill="#45494E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_89">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AlignRightIcon;
