import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';

import {
  ICreatePostRequest,
  IGetPostRequest,
  IPost,
  IUpdatePostPollVoteRequest,
  IPostParams,
  IPreviewPostParams,
  IUpdatePostReactionRequest,
  IUpdatePostRequest,
  ICreatePollRequest,
  ICreatePostCommentRequest,
  ICreatePostCommentResponse,
  IDeletePostCommentRequest,
  IDeletePostCommentResponse,
  IGetCommentsRequest,
  IGetPostCommentsResponse,
  IUpdatePostCommentRequest,
  IDeletePostPollVoteRequest,
  IUpdatePostPollVoteResponse,
  IUpdatePollRequest,
  IPollResponse,
  IUpdatePostCommentReactionRequest,
  IPostComment,
} from '../../interfaces/post.interface';

import {
  IListOfElementsResponse,
  IResponseMessage,
  ISingleElement,
} from '../../interfaces/common.interface';

import { addNotification } from '../app/reducer';

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Post', 'Comment'],
  endpoints: (builder) => ({
    getPosts: builder.query<IListOfElementsResponse<IPost>, IPostParams>({
      query: (params: IPostParams) => ({
        url: '/post',
        params,
      }),
      providesTags: (result) =>
        result
          ? result.rows.map(({ id }: { id: string }) => ({
              type: 'Post',
              id,
            }))
          : [],
    }),

    getPost: builder.query<ISingleElement<IPost>, IGetPostRequest>({
      query: ({ id }) => ({
        url: `/post/${id}`,
      }),
      providesTags: (result) => [{ type: 'Post', id: result?.data.id }],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            postApi.util.updateQueryData('getPosts', arg.params, (draft) => {
              const post = draft.rows.find((p) => p.id === data.data.id);
              if (post) {
                post.viewers = data.data.viewers;
              }
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {}
      },
    }),

    uploadPostPicture: builder.mutation<{ key: string; url: string }, FormData>(
      {
        query(data) {
          return {
            url: 'upload/post/picture',
            method: 'Post',
            data,
            // a new axios logic in versions 1.x about FormData https://github.com/axios/axios/issues/5556#issuecomment-1434668134
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        },
      },
    ),

    createPost: builder.mutation<{ data: { id: string } }, ICreatePostRequest>({
      query: (data) => {
        return {
          url: '/post',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Post' }],
    }),

    updatePost: builder.mutation<{ data: { id: string } }, IUpdatePostRequest>({
      query: (data) => {
        return {
          url: `/post/${data.id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Post updated successfully',
              type: 'success',
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          const { data } = err.error;
          dispatch(
            addNotification({
              title: data.message || 'Post updating error',
              type: 'error',
            }),
          );
        }
      },
    }),

    deletePost: builder.mutation<{ message: string }, { id: string }>({
      query: (data) => {
        return {
          url: `/post/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, data) => [{ type: 'Post', id: data.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Post removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Post removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updatePostReaction: builder.mutation<
      ISingleElement<IPreviewPostParams>,
      IUpdatePostReactionRequest
    >({
      query: ({ id, reaction }) => ({
        url: `/post/${id}/reaction`,
        method: 'PATCH',
        data: { reaction },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            postApi.util.updateQueryData(
              'getPost',
              { id: arg.id, params: arg.params },
              (draft) => {
                if (draft.data.id === arg.id) {
                  draft.data.like_count = data.data.like_count;
                  draft.data.dislike_count = data.data.dislike_count;
                  draft.data.reaction = data.data.reaction;
                  draft.data.reactions = data.data.reactions;
                }
              },
            ),
          );
          dispatch(
            postApi.util.updateQueryData('getPosts', arg.params, (draft) => {
              const post = draft.rows.find((p) => p.id === data.data.id);
              if (post) {
                post.like_count = data.data.like_count;
                post.dislike_count = data.data.dislike_count;
                post.reaction = data.data.reaction;
                post.reactions = data.data.reactions;
              }
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {}
      },
    }),

    createPoll: builder.mutation<IPollResponse, ICreatePollRequest>({
      query: (data) => {
        return {
          url: `/post/${data.idPost}/poll`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Post', id: arg.idPost },
      ],
    }),

    updatePoll: builder.mutation<IPollResponse, IUpdatePollRequest>({
      query: (data) => {
        return {
          url: `/post/${data.idPost}/poll/${data.pollId}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Post', id: arg.idPost },
      ],
    }),

    deletePoll: builder.mutation<
      { message: string },
      { postId: string; pollId: string }
    >({
      query: (data) => {
        return {
          url: `/post/${data.postId}/poll/${data.pollId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, { postId }) => [{ type: 'Post', id: postId }],
    }),

    updatePollVote: builder.mutation<
      IUpdatePostPollVoteResponse,
      IUpdatePostPollVoteRequest
    >({
      query: (data) => {
        return {
          url: `post/${data.postId}/poll/${data.pollId}/vote`,
          method: 'PATCH',
          data: data.value,
        };
      },
      invalidatesTags: (_, __, { postId }) => [{ type: 'Post', id: postId }],
    }),

    deletePollVote: builder.mutation<
      IResponseMessage,
      IDeletePostPollVoteRequest
    >({
      query: ({ postId, pollId }) => ({
        url: `post/${postId}/poll/${pollId}/vote`,
        method: 'DELETE',
      }),

      invalidatesTags: (_, __, { postId }) => [{ type: 'Post', id: postId }],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Change vote error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getCategory: builder.query({
      query() {
        return {
          url: '/category',
        };
      },
    }),

    getPostComments: builder.query<
      IGetPostCommentsResponse,
      IGetCommentsRequest
    >({
      query: ({ id }) => ({
        url: `/post/${id}/comments`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? result.data.map(({ id }: { id: string }) => ({
              type: 'Comment',
              id,
            }))
          : [],
    }),

    createPostComment: builder.mutation<
      ISingleElement<ICreatePostCommentResponse>,
      ICreatePostCommentRequest
    >({
      query: (data) => ({
        url: `/post/${data.id}/comment`,
        method: 'POST',
        data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Comment create error',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: () => [{ type: 'Comment' }, { type: 'Post' }],
    }),

    deletePostComment: builder.mutation<
      IDeletePostCommentResponse,
      IDeletePostCommentRequest
    >({
      query: ({ postId, commentId }) => ({
        url: `/post/${postId}/comment/${commentId}`,
        method: 'DELETE',
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Comment delete error',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (_, __, { postId, commentId }) => [
        { type: 'Comment', id: commentId },
        { type: 'Post', id: postId },
      ],
    }),

    updatePostComment: builder.mutation<
      ISingleElement<ICreatePostCommentResponse>,
      IUpdatePostCommentRequest
    >({
      query: ({ postId, commentId, parent_id, text }) => ({
        url: `/post/${postId}/comment/${commentId}`,
        method: 'PATCH',
        data: { parent_id, text },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Comment update error',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (_, __, { commentId }) => [
        { type: 'Comment', id: commentId },
      ],
    }),

    updatePostCommentReaction: builder.mutation<
      ISingleElement<IPostComment>,
      IUpdatePostCommentReactionRequest
    >({
      query: ({ postId, commentId, reaction }) => ({
        url: `/post/${postId}/comment/${commentId}/reaction`,
        method: 'PATCH',
        data: { reaction },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Comment reaction update error',
              type: 'error',
            }),
          );
        }
      },

      invalidatesTags: (_, __, { commentId }) => [
        { type: 'Comment', id: commentId },
      ],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useGetPostQuery,
  useCreatePostMutation,
  useUpdatePostReactionMutation,
  useUpdatePollVoteMutation,
  useDeletePollVoteMutation,
  useUploadPostPictureMutation,
  useCreatePollMutation,
  useDeletePollMutation,
  useGetCategoryQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useUpdatePollMutation,
  useGetPostCommentsQuery,
  useCreatePostCommentMutation,
  useDeletePostCommentMutation,
  useUpdatePostCommentMutation,
  useUpdatePostCommentReactionMutation,
} = postApi;
