/* eslint-disable no-bitwise */

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function uuidv4ToHash(uuid: string) {
  let hash = 0;
  let i;
  let chr;
  if (uuid.length === 0) return hash;
  for (i = 0; i < uuid.length; i += 1) {
    chr = uuid.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
}
