import { useEffect, useState } from 'react';

interface ResizeParams {
  width: number;
  height: number;
}

export const useResizeParams = (): ResizeParams => {
  const [params, setParams] = useState<ResizeParams>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const resizeCallback = () =>
    setParams({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return params;
};
