import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import {
  IQueryParams,
  ISingleElement,
} from '../../interfaces/common.interface';
import {
  ICreateProject,
  IProjectView,
  IUpdateProject,
  RosterFreeProject,
} from '../../interfaces/project.interface';
import { addNotification } from '../app/reducer';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (params: IQueryParams) => ({
        url: '/project',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }: { id: string }) => ({
                type: 'Project' as const,
                id,
              })),
              { type: 'Project', id: 'PROJECTS-LIST' },
            ]
          : [{ type: 'Project', id: 'PROJECTS-LIST' }],
    }),

    getProject: builder.query<ISingleElement<IProjectView>, { id: string }>({
      query: ({ id }) => ({
        url: `/project/${id}`,
      }),
      providesTags: (result) => [{ type: 'Project', id: result?.data.id }],
    }),

    createProject: builder.mutation<{ data: { id: string } }, ICreateProject>({
      query: (data) => {
        return {
          url: '/project',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Project', id: 'PROJECTS-LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Project created successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Project creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updateProject: builder.mutation<{ data: { id: string } }, IUpdateProject>({
      query: (data) => {
        return {
          url: `/project/${data.id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Project', id: arg.id },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Project updated successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Project updating error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getRosterFreeProjectList: builder.query<RosterFreeProject[], undefined>({
      query: () => ({
        url: '/project/roster-free-list',
      }),
      transformResponse: (rawResult: { data: RosterFreeProject[] }) => {
        return rawResult.data;
      },
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetRosterFreeProjectListQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} = projectApi;
