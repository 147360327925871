import {
  memo,
  createElement,
  PropsWithChildren,
  CSSProperties,
  forwardRef,
  ForwardedRef,
} from 'react';

import { classNames } from '../../utils/classNames';

import classes from './typography.module.scss';

export type TextTypes =
  | 'title'
  | 'button'
  | 'button-bold'
  | 'text'
  | 'text-small'
  | 'card'
  | 'percents'
  | 'welcome-bold'
  | 'text-bold'
  | 'title-bold';

export type TextColors =
  | 'white'
  | 'blue'
  | 'grey'
  | 'text-color'
  | 'red'
  | 'black'
  | 'secondary-blue'
  | 'green';

export interface ITextProps {
  type?: TextTypes;
  as?: string;
  ellipsis?: boolean;
  uppercase?: boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  color?: TextColors;
  htmlFor?: string;
  underlined?: boolean;
  ref?: ForwardedRef<HTMLElement>;
  title?: string;
}

export const Text = memo(
  forwardRef<HTMLElement, PropsWithChildren<ITextProps>>(
    (
      {
        type = 'text',
        uppercase = false,
        ellipsis = false,
        as = 'span',
        children,
        style,
        className = '',
        color = '',
        underlined = false,
        ...rest
      },
      ref,
    ) => {
      const props = {
        className: classNames(classes[type], {
          uppercase: !!uppercase,
          ellipsis: !!ellipsis,
          underlined: !!underlined,
          [color]: !!color,
          [className]: true,
        }),
        style,
        ref,
        ...rest,
      };

      return createElement(as, props, children);
    },
  ),
);

Text.displayName = 'Text';
