import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 23, height = 23, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.4578 3.30364C14.6245 2.77647 15.1895 2.4799 15.6986 2.69574C17.6927 3.54127 19.3873 4.98301 20.5419 6.83457C21.8738 8.97043 22.4 11.5115 22.0257 14.0006C21.6514 16.4897 20.4012 18.7636 18.5 20.4133C16.5988 22.063 14.1713 22.9801 11.6543 22.9998C9.13721 23.0195 6.69573 22.1404 4.76897 20.5207C2.8422 18.901 1.5566 16.6469 1.1434 14.1639C0.73021 11.6809 1.21654 9.13197 2.51491 6.97554C3.64047 5.10615 5.31225 3.63809 7.29293 2.76147C7.79855 2.5377 8.36812 2.82539 8.54309 3.3499C8.71805 3.8744 8.43133 4.43619 7.93079 4.6711C6.40072 5.38919 5.10978 6.54762 4.23028 8.00836C3.17782 9.75635 2.7836 11.8225 3.11854 13.8352C3.45347 15.8479 4.49558 17.6751 6.05741 18.988C7.61924 20.301 9.5983 21.0135 11.6386 20.9976C13.6789 20.9816 15.6466 20.2382 17.1877 18.901C18.7288 17.5638 19.7422 15.7205 20.0456 13.7028C20.349 11.6852 19.9226 9.62541 18.8429 7.89409C17.9407 6.44728 16.6318 5.30918 15.0907 4.61511C14.5865 4.38806 14.291 3.83082 14.4578 3.30364Z"
      fill="white"
    />
    <rect
      x="10.5834"
      width="1.91666"
      height="9.58332"
      rx="0.958332"
      fill="white"
    />
  </svg>
);

export default Svg;
