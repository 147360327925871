import { SvgIconType } from './svg.interface';

const FileIcon: SvgIconType = ({ width = 50, height = 50, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5 5V45C5 46.3261 5.52678 47.5979 6.46447 48.5355C7.40215 49.4732 8.67392 50 10 50H40C41.3261 50 42.5979 49.4732 43.5355 48.5355C44.4732 47.5979 45 46.3261 45 45V15L30 0H10C8.67392 0 7.40215 0.526784 6.46447 1.46447C5.52678 2.40215 5 3.67392 5 5Z"
      fill="#137BC6"
    />
    <path
      d="M16 36H33C33.2652 36 33.5196 36.1054 33.7071 36.2929C33.8946 36.4804 34 36.7348 34 37C34 37.2652 33.8946 37.5196 33.7071 37.7071C33.5196 37.8946 33.2652 38 33 38H16C15.7348 38 15.4804 37.8946 15.2929 37.7071C15.1054 37.5196 15 37.2652 15 37C15 36.7348 15.1054 36.4804 15.2929 36.2929C15.4804 36.1054 15.7348 36 16 36Z"
      fill="white"
    />
    <path
      d="M16 40H33C33.2652 40 33.5196 40.1054 33.7071 40.2929C33.8946 40.4804 34 40.7348 34 41C34 41.2652 33.8946 41.5196 33.7071 41.7071C33.5196 41.8946 33.2652 42 33 42H16C15.7348 42 15.4804 41.8946 15.2929 41.7071C15.1054 41.5196 15 41.2652 15 41C15 40.7348 15.1054 40.4804 15.2929 40.2929C15.4804 40.1054 15.7348 40 16 40Z"
      fill="white"
    />
    <path
      d="M16 44H33C33.2652 44 33.5196 44.1054 33.7071 44.2929C33.8946 44.4804 34 44.7348 34 45C34 45.2652 33.8946 45.5196 33.7071 45.7071C33.5196 45.8946 33.2652 46 33 46H16C15.7348 46 15.4804 45.8946 15.2929 45.7071C15.1054 45.5196 15 45.2652 15 45C15 44.7348 15.1054 44.4804 15.2929 44.2929C15.4804 44.1054 15.7348 44 16 44Z"
      fill="white"
    />
    <path opacity="0.15" d="M45 26.25V15L42.5 12.5H31L45 26.25Z" fill="black" />
    <path
      d="M35 15H45L30 0V10C30 11.3261 30.5268 12.5979 31.4645 13.5355C32.4021 14.4732 33.6739 15 35 15Z"
      fill="white"
    />
  </svg>
);

export default FileIcon;
