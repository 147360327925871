import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../utils/axios';
import { s3File } from '../../interfaces/file.interface';
import { UserInterface } from '../../interfaces/user.interface';
import { usersApi } from '../user/api';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['File'],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<s3File, FormData>({
      query(data) {
        return {
          url: 'upload/file',
          method: 'Post',
          data,
          // a new axios logic in versions 1.x about FormData https://github.com/axios/axios/issues/5556#issuecomment-1434668134
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      transformResponse: (raw: { data: s3File }) => raw.data,
    }),
    deleteUserAvatar: builder.mutation<
      UserInterface,
      { userId: string; fileId: string }
    >({
      query(data) {
        return {
          url: `upload/file/${data.fileId}`,
          method: 'Delete',
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            usersApi.util.updateQueryData('getMe', undefined, (draft) => {
              if (draft.id === arg.userId) {
                draft.avatar = null;
              }
            }),
          );
          // eslint-disable-next-line no-empty
        } catch (err) {}
      },
      transformResponse: (response: { data: UserInterface }) => response.data,
    }),
  }),
});

export const { useUploadFileMutation, useDeleteUserAvatarMutation } = fileApi;
