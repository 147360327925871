import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 16, height = 16, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.77097 2.14015L8.47259 2.57747L8.77097 2.14015ZM8.32494 14.2853L8.3671 13.7575L8.32494 14.2853ZM6.80782 13.8518L7.32457 13.7367L6.80782 13.8518ZM8.08591 12.1557C9.79248 11.1831 10.9412 9.34825 10.9412 7.24581H12C12 9.74395 10.6337 11.9223 8.61021 13.0756L8.08591 12.1557ZM10.9412 7.24581C10.9412 5.30636 9.96394 3.59502 8.47259 2.57747L9.06935 1.70283C10.8375 2.90921 12 4.94167 12 7.24581H10.9412ZM14.4706 8.12862C14.4706 5.00983 11.9423 2.48156 8.82353 2.48156V1.42274C12.5271 1.42274 15.5294 4.42506 15.5294 8.12862H14.4706ZM8.82353 13.7757C11.9423 13.7757 14.4706 11.2474 14.4706 8.12862H15.5294C15.5294 11.8322 12.5271 14.8345 8.82353 14.8345V13.7757ZM8.3671 13.7575C8.51755 13.7695 8.66977 13.7757 8.82353 13.7757V14.8345C8.6416 14.8345 8.46126 14.8272 8.28279 14.813L8.3671 13.7575ZM8.47259 2.57747C8.22464 2.4083 8.15367 2.11886 8.21791 1.8848C8.28365 1.64527 8.50483 1.42274 8.82353 1.42274V2.48156C9.04176 2.48156 9.19515 2.32471 9.23898 2.16503C9.2813 2.01081 9.23429 1.81537 9.06935 1.70283L8.47259 2.57747ZM8.61021 13.0756C8.05953 13.3895 7.69169 13.6002 7.46421 13.7691C7.3503 13.8537 7.31133 13.8997 7.30352 13.911C7.30219 13.9129 7.3145 13.8958 7.32321 13.861C7.33275 13.8228 7.33399 13.779 7.32457 13.7367L6.29107 13.9669C6.23303 13.7063 6.31551 13.4788 6.43163 13.3102C6.53816 13.1556 6.68729 13.0272 6.83304 12.919C7.12487 12.7023 7.56256 12.454 8.08591 12.1557L8.61021 13.0756ZM8.28279 14.813C7.82014 14.776 7.40878 14.7447 7.10972 14.6741C6.8258 14.6071 6.39804 14.4472 6.29107 13.9669L7.32457 13.7367C7.28842 13.5744 7.13712 13.5927 7.353 13.6436C7.55375 13.691 7.86555 13.7175 8.3671 13.7575L8.28279 14.813Z"
      fill="#137BC6"
    />
    <path
      d="M2.8451 6.11824C2.89177 6.21621 2.92785 6.32444 3 6.5409C3.08945 6.80924 3.13417 6.94341 3.1789 7.00193C3.35548 7.23294 3.70334 7.23294 3.87992 7.00193C3.92465 6.94341 3.96938 6.80924 4.05882 6.5409C4.13098 6.32444 4.16705 6.21621 4.21372 6.11824C4.38786 5.75271 4.6824 5.45817 5.04793 5.28404C5.1459 5.23737 5.25413 5.20129 5.47059 5.12914C5.73893 5.03969 5.8731 4.99497 5.93162 4.95023C6.16263 4.77365 6.16263 4.42579 5.93162 4.24922C5.8731 4.20448 5.73893 4.15976 5.47059 4.07031C5.25413 3.99816 5.1459 3.96208 5.04793 3.91541C4.6824 3.74128 4.38786 3.44674 4.21372 3.0812C4.16705 2.98324 4.13098 2.87501 4.05882 2.65855C3.96938 2.39021 3.92465 2.25604 3.87992 2.19752C3.70334 1.96651 3.35548 1.96651 3.1789 2.19752C3.13417 2.25604 3.08945 2.39021 3 2.65854L3 2.65855C2.92785 2.87501 2.89177 2.98324 2.8451 3.0812C2.67097 3.44674 2.37643 3.74128 2.01089 3.91541C1.91293 3.96208 1.8047 3.99816 1.58824 4.07031C1.3199 4.15976 1.18573 4.20448 1.1272 4.24922C0.896194 4.42579 0.896194 4.77365 1.1272 4.95023C1.18573 4.99497 1.3199 5.03969 1.58823 5.12913L1.58824 5.12914C1.8047 5.20129 1.91293 5.23737 2.01089 5.28404C2.37643 5.45817 2.67097 5.75271 2.8451 6.11824Z"
      stroke="#137BC6"
      strokeWidth="1.05882"
    />
  </svg>
);

export default Svg;
