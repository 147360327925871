import { SvgIconType } from './svg.interface';

const SortZAIcon: SvgIconType = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 14L6 17L9 14L8.293 13.293L6.5 15.086L6.5 3H5.5L5.5 15.086L3.707 13.293L3 14Z"
      fill="#B6B7B8"
    />
    <path
      d="M16.9249 17H18L15.502 11H14.4901L12 17H13.0593L13.6047 15.6114H16.3715L16.9249 17ZM13.9526 14.7371L14.9881 12.1143L16.0316 14.7371H13.9526Z"
      fill="#B6B7B8"
    />
    <path
      d="M13.4405 8.05714L16.8911 3.74571V3H12.0586V3.94286H15.4506L12 8.25429V9H17V8.05714H13.4405Z"
      fill="#B6B7B8"
    />
  </svg>
);

export default SortZAIcon;
