import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 6, height = 11, className, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.18591 0.210823C1.05675 0.074408 0.892367 -5.07151e-07 0.69863 -4.90214e-07C0.311154 -4.5634e-07 2.02119e-08 0.322435 5.59892e-08 0.731679C7.38778e-08 0.936302 0.0821916 1.12232 0.217221 1.26494L4.32681 5.5062L0.217222 9.73506C0.0821923 9.87768 8.72362e-07 10.0699 8.89709e-07 10.2683C9.25486e-07 10.6776 0.311155 11 0.698631 11C0.892368 11 1.05675 10.9256 1.18591 10.7892L5.75342 6.07666C5.91781 5.91544 5.99413 5.71702 6 5.5C6 5.28298 5.91781 5.09696 5.75342 4.92954L1.18591 0.210823Z"
      fill={color || '#45494E'}
    />
  </svg>
);

export default Svg;
