import { SvgIconType } from './svg.interface';

const DesignIcon: SvgIconType = ({ width = 80, height = 80, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.6661 46.6667C18.507 46.6667 19.9994 45.1743 19.9994 43.3334C19.9994 41.4924 18.507 40 16.6661 40C14.8251 40 13.3328 41.4924 13.3328 43.3334C13.3328 45.1743 14.8251 46.6667 16.6661 46.6667Z"
      fill="white"
    />
    <path
      d="M3.33333 33.3339C5.17428 33.3339 6.66667 31.8415 6.66667 30.0006C6.66667 28.1596 5.17428 26.6672 3.33333 26.6672C1.49238 26.6672 0 28.1596 0 30.0006C0 31.8415 1.49238 33.3339 3.33333 33.3339Z"
      fill="white"
    />
    <path
      d="M16.6661 19.9994C18.507 19.9994 19.9994 18.507 19.9994 16.6661C19.9994 14.8251 18.507 13.3328 16.6661 13.3328C14.8251 13.3328 13.3328 14.8251 13.3328 16.6661C13.3328 18.507 14.8251 19.9994 16.6661 19.9994Z"
      fill="white"
    />
    <path
      d="M3.33333 6.66667C5.17428 6.66667 6.66667 5.17428 6.66667 3.33333C6.66667 1.49238 5.17428 0 3.33333 0C1.49238 0 0 1.49238 0 3.33333C0 5.17428 1.49238 6.66667 3.33333 6.66667Z"
      fill="white"
    />
    <path
      d="M76.6661 46.6667C78.507 46.6667 79.9994 45.1743 79.9994 43.3334C79.9994 41.4924 78.507 40 76.6661 40C74.8251 40 73.3328 41.4924 73.3328 43.3334C73.3328 45.1743 74.8251 46.6667 76.6661 46.6667Z"
      fill="white"
    />
    <path
      d="M63.3334 33.3339C65.1743 33.3339 66.6667 31.8415 66.6667 30.0006C66.6667 28.1596 65.1743 26.6672 63.3334 26.6672C61.4924 26.6672 60 28.1596 60 30.0006C60 31.8415 61.4924 33.3339 63.3334 33.3339Z"
      fill="white"
    />
    <path
      d="M76.6661 19.9994C78.507 19.9994 79.9994 18.507 79.9994 16.6661C79.9994 14.8251 78.507 13.3328 76.6661 13.3328C74.8251 13.3328 73.3328 14.8251 73.3328 16.6661C73.3328 18.507 74.8251 19.9994 76.6661 19.9994Z"
      fill="white"
    />
    <path
      d="M63.3334 6.66667C65.1743 6.66667 66.6667 5.17428 66.6667 3.33333C66.6667 1.49238 65.1743 0 63.3334 0C61.4924 0 60 1.49238 60 3.33333C60 5.17428 61.4924 6.66667 63.3334 6.66667Z"
      fill="white"
    />
    <path
      d="M46.6661 79.9994C48.5071 79.9994 49.9995 78.507 49.9995 76.6661C49.9995 74.8251 48.5071 73.3328 46.6661 73.3328C44.8252 73.3328 43.3328 74.8251 43.3328 76.6661C43.3328 78.507 44.8252 79.9994 46.6661 79.9994Z"
      fill="white"
    />
    <path
      d="M33.3333 66.6667C35.1743 66.6667 36.6667 65.1743 36.6667 63.3334C36.6667 61.4924 35.1743 60 33.3333 60C31.4924 60 30 61.4924 30 63.3334C30 65.1743 31.4924 66.6667 33.3333 66.6667Z"
      fill="white"
    />
    <path
      d="M46.6661 53.3339C48.5071 53.3339 49.9995 51.8415 49.9995 50.0006C49.9995 48.1596 48.5071 46.6672 46.6661 46.6672C44.8252 46.6672 43.3328 48.1596 43.3328 50.0006C43.3328 51.8415 44.8252 53.3339 46.6661 53.3339Z"
      fill="white"
    />
    <path
      d="M33.3333 39.9995C35.1743 39.9995 36.6667 38.5071 36.6667 36.6661C36.6667 34.8252 35.1743 33.3328 33.3333 33.3328C31.4924 33.3328 30 34.8252 30 36.6661C30 38.5071 31.4924 39.9995 33.3333 39.9995Z"
      fill="white"
    />
    <path
      d="M50.0005 10C48.2324 10 46.5367 9.29762 45.2865 8.04738C44.0363 6.79714 43.3339 5.10144 43.3339 3.33333C43.3339 2.44928 42.9827 1.60143 42.3576 0.976311C41.7324 0.35119 40.8846 0 40.0005 0C39.1165 0 38.2686 0.35119 37.6435 0.976311C37.0184 1.60143 36.6672 2.44928 36.6672 3.33333V3.33333C36.6672 5.10144 35.9648 6.79714 34.7146 8.04738C33.4644 9.29762 31.7687 10 30.0006 10C29.1165 10 28.2687 10.3512 27.6435 10.9763C27.0184 11.6014 26.6672 12.4493 26.6672 13.3333C26.6672 14.2174 27.0184 15.0652 27.6435 15.6904C28.2687 16.3155 29.1165 16.6667 30.0006 16.6667V16.6667C31.7687 16.6667 33.4644 17.369 34.7146 18.6193C35.9648 19.8695 36.6672 21.5652 36.6672 23.3333C36.6672 24.2174 37.0184 25.0652 37.6435 25.6904C38.2686 26.3155 39.1165 26.6667 40.0005 26.6667C40.8846 26.6667 41.7324 26.3155 42.3576 25.6904C42.9827 25.0652 43.3339 24.2174 43.3339 23.3333C43.3339 21.5652 44.0363 19.8695 45.2865 18.6193C46.5367 17.369 48.2324 16.6667 50.0005 16.6667C50.8846 16.6667 51.7324 16.3155 52.3575 15.6904C52.9827 15.0652 53.3339 14.2174 53.3339 13.3333C53.3339 12.4493 52.9827 11.6014 52.3575 10.9763C51.7324 10.3512 50.8846 10 50.0005 10V10Z"
      fill="white"
    />
    <path
      d="M23.3333 63.3327C21.5652 63.3327 19.8695 62.6304 18.6193 61.3801C17.369 60.1299 16.6667 58.4342 16.6667 56.6661C16.6667 55.782 16.3155 54.9342 15.6904 54.3091C15.0652 53.6839 14.2174 53.3328 13.3333 53.3328C12.4493 53.3328 11.6014 53.6839 10.9763 54.3091C10.3512 54.9342 10 55.782 10 56.6661V56.6661C10 58.4342 9.29762 60.1299 8.04738 61.3801C6.79714 62.6304 5.10144 63.3327 3.33333 63.3327C2.44928 63.3327 1.60143 63.6839 0.976311 64.3091C0.35119 64.9342 0 65.782 0 66.6661C0 67.5501 0.35119 68.398 0.976311 69.0231C1.60143 69.6482 2.44928 69.9994 3.33333 69.9994V69.9994C5.10144 69.9994 6.79714 70.7018 8.04738 71.952C9.29762 73.2023 10 74.8979 10 76.6661C10 77.5501 10.3512 78.398 10.9763 79.0231C11.6014 79.6482 12.4493 79.9994 13.3333 79.9994C14.2174 79.9994 15.0652 79.6482 15.6904 79.0231C16.3155 78.398 16.6667 77.5501 16.6667 76.6661C16.6667 74.8979 17.369 73.2023 18.6193 71.952C19.8695 70.7018 21.5652 69.9994 23.3333 69.9994V69.9994C24.2174 69.9994 25.0652 69.6482 25.6904 69.0231C26.3155 68.398 26.6667 67.5501 26.6667 66.6661C26.6667 65.782 26.3155 64.9342 25.6904 64.3091C25.0652 63.6839 24.2174 63.3327 23.3333 63.3327Z"
      fill="white"
    />
    <path
      d="M76.6661 63.3327C74.8979 63.3327 73.2023 62.6304 71.952 61.3801C70.7018 60.1299 69.9994 58.4342 69.9994 56.6661C69.9994 55.782 69.6482 54.9342 69.0231 54.3091C68.398 53.6839 67.5501 53.3328 66.6661 53.3328C65.782 53.3328 64.9342 53.6839 64.3091 54.3091C63.6839 54.9342 63.3327 55.782 63.3327 56.6661C63.3327 58.4342 62.6304 60.1299 61.3801 61.3801C60.1299 62.6304 58.4342 63.3327 56.6661 63.3327C55.782 63.3327 54.9342 63.6839 54.3091 64.3091C53.6839 64.9342 53.3328 65.782 53.3328 66.6661C53.3328 67.5501 53.6839 68.398 54.3091 69.0231C54.9342 69.6482 55.782 69.9994 56.6661 69.9994C58.4342 69.9994 60.1299 70.7018 61.3801 71.952C62.6304 73.2023 63.3327 74.8979 63.3327 76.6661C63.3327 77.5501 63.6839 78.398 64.3091 79.0231C64.9342 79.6482 65.782 79.9994 66.6661 79.9994C67.5501 79.9994 68.398 79.6482 69.0231 79.0231C69.6482 78.398 69.9994 77.5501 69.9994 76.6661C69.9994 74.8979 70.7018 73.2023 71.952 71.952C73.2023 70.7018 74.8979 69.9994 76.6661 69.9994V69.9994C77.5501 69.9994 78.398 69.6482 79.0231 69.0231C79.6482 68.398 79.9994 67.5501 79.9994 66.6661C79.9994 65.782 79.6482 64.9342 79.0231 64.3091C78.398 63.6839 77.5501 63.3327 76.6661 63.3327Z"
      fill="white"
    />
  </svg>
);

export default DesignIcon;
