import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 4, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="2" cy="2" r="2" fill="white" />
    <circle cx="2" cy="10" r="2" fill="white" />
    <circle cx="2" cy="18" r="2" fill="white" />
  </svg>
);

export default Svg;
