import { SvgIconType } from './svg.interface';

const EditIcon: SvgIconType = ({
  width = 21,
  height = 20,
  className,
  color,
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M20.4284 3.24571L17.7011 0.516136C17.014 -0.171605 15.8186 -0.172926 15.1287 0.517457L2.91108 12.8557C2.85692 12.9103 2.81784 12.9774 2.79653 13.0511L0.978384 19.4202C0.933086 19.5788 0.977489 19.7499 1.09425 19.8667C1.1808 19.9533 1.29666 20 1.41564 20C1.45736 20 1.49955 19.9942 1.54037 19.9827L7.90399 18.1629C7.97766 18.1416 8.0447 18.1025 8.09928 18.0483L20.4284 5.81893C20.772 5.47508 20.961 5.01835 20.961 4.53234C20.961 4.04633 20.772 3.58955 20.4284 3.24571ZM13.148 3.82393L14.8211 5.49711L6.08554 14.2328L5.4586 12.9785C5.38135 12.8244 5.22423 12.7272 5.05199 12.7272H4.32406L13.148 3.82393ZM2.07747 18.8836L2.66955 16.811L4.15003 18.2915L2.07747 18.8836ZM7.3247 17.3845L5.14991 18.0058L2.95518 15.8111L3.57645 13.6363H4.77095L5.5544 15.2032C5.59833 15.2912 5.6698 15.3626 5.75771 15.4066L7.32465 16.19V17.3845H7.3247ZM8.23381 16.637V15.9091C8.23381 15.7368 8.13661 15.5797 7.98256 15.5024L6.72828 14.8755L15.4639 6.13989L17.1371 7.81307L8.23381 16.637ZM19.787 5.18684L17.7827 7.17319L13.7878 3.17829L15.7728 1.17541C16.1164 0.831817 16.7148 0.831817 17.0584 1.17541L19.7856 3.90268C19.9574 4.07445 20.052 4.30261 20.052 4.54542C20.052 4.78823 19.9574 5.01639 19.787 5.18684Z"
      fill={color || '#137BC6'}
    />
  </svg>
);

export default EditIcon;
