import { SvgIconType } from './svg.interface';

const BellIcon: SvgIconType = ({ width = 24, height = 24, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4555 2.47362C13.6945 2.16764 12.8652 2 12.0002 2C8.6441 2 5.82473 4.52345 5.45411 7.85899L5.20231 10.1252L5.19626 10.1793C5.08343 11.1625 4.76339 12.1106 4.25726 12.9611L4.22932 13.0077L3.65128 13.9711L3.62713 14.0114C3.38561 14.4138 3.1668 14.7785 3.02104 15.0879C2.87293 15.4024 2.71455 15.8286 2.79079 16.3117C2.86614 16.7893 3.11191 17.2233 3.48264 17.5337C3.85771 17.8476 4.30458 17.931 4.65047 17.9658C4.99084 18.0001 5.4161 18 5.88548 18L5.93239 18H18.0679L18.1148 18C18.5842 18 19.0095 18.0001 19.3498 17.9658C19.6957 17.931 20.1426 17.8476 20.5177 17.5337C20.8884 17.2233 21.1342 16.7893 21.2095 16.3117C21.2858 15.8286 21.1274 15.4024 20.9793 15.0879C20.8335 14.7785 20.6147 14.4138 20.3732 14.0114L20.349 13.9711L19.771 13.0077L19.743 12.9611C19.3643 12.3247 19.0898 11.6336 18.9283 10.914C18.6275 10.9705 18.3173 11 18.0002 11C17.6162 11 17.2423 10.9567 16.8832 10.8748C17.0678 11.9715 17.4542 13.0259 18.0244 13.9839L18.056 14.0367L18.634 15.0001C18.9075 15.456 19.0724 15.733 19.1699 15.9401C19.1752 15.9514 19.1801 15.9621 19.1846 15.972C19.1737 15.9733 19.1621 15.9746 19.1496 15.9759C18.9219 15.9988 18.5995 16 18.0679 16H5.93239C5.40079 16 5.07842 15.9988 4.85068 15.9759C4.83822 15.9746 4.82657 15.9733 4.81571 15.972C4.82019 15.9621 4.82509 15.9514 4.83042 15.9401C4.92794 15.733 5.09277 15.456 5.36627 15.0001L5.9443 14.0367L5.97594 13.9839C6.62668 12.8904 7.03816 11.6714 7.18322 10.4073L7.19008 10.3461L7.44188 8.07985C7.69996 5.75718 9.6632 4 12.0002 4C12.4632 4 12.9115 4.06898 13.3349 4.19767C13.5868 3.54594 13.9714 2.96023 14.4555 2.47362ZM16.9823 4.27804C16.3943 4.62632 16.0002 5.26713 16.0002 6C16.0002 6.14503 16.0156 6.28645 16.0449 6.42272C16.1653 6.6476 16.2676 6.88404 16.3499 7.13021C16.7103 7.65547 17.315 8 18.0002 8C18.192 8 18.3776 7.97298 18.5533 7.92254L18.5462 7.85899C18.3939 6.48875 17.8285 5.25555 16.9823 4.27804Z"
      fill="#137BC6"
    />
    <path
      d="M9.10222 17.6647C9.27315 18.6215 9.64978 19.467 10.1737 20.0701C10.6976 20.6731 11.3396 21 12 21C12.6604 21 13.3024 20.6731 13.8263 20.0701C14.3502 19.467 14.7269 18.6215 14.8978 17.6647"
      stroke="#137BC6"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="18" cy="6" r="2.5" fill="#F74141" stroke="#F74141" />
  </svg>
);

export default BellIcon;
