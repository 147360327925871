import { UserInterface } from './user.interface';
import { s3File } from './file.interface';

export type PredefinedCategories =
  | 'frontend'
  | 'backend'
  | 'qa'
  | 'management'
  | 'hr'
  | 'devops'
  | 'sales'
  | 'design';

export interface IKBItem {
  id: string;
  category: PredefinedCategories;
  name: string;
  created_at: string;
  updated_at: string;
  knowledge_base_category: IKBCategory;
  file?: s3File;
  google_drive_url: string;
  owner?: UserInterface;
}

export interface IKBCategory
  extends Pick<IKBItem, 'id' | 'name' | 'category'> {}

export interface IKBParams {
  q?: string;
  page?: string | number;
  limit?: string | number;
  sort?: string | null;
  ext?: string;
  parentId?: string;
  breadcrumbs?: string;
  categoryId?: string;
  categoryName?: string;
}

export enum MimeType {
  folder = 'application/vnd.google-apps.folder',
  document = 'application/vnd.google-apps.document',
  spreadsheet = 'application/vnd.google-apps.spreadsheet',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc = 'application/msword',
  pdf = 'application/pdf',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface IKBDriveParams {
  categoryId?: string;
  parentId?: string;
  query?: string;
  filter?: string;
  categoryName?: PredefinedCategories;
}

export interface IKBDriveUploadParams {
  data: FormData;
  categoryName?: PredefinedCategories;
}

export interface IKBCategoryFolder {
  id: string;
  name: string;
  category: PredefinedCategories;
}

export interface IKBDriveFile {
  id: string;
  name: string;
  mimeType: MimeType;
  parents: string[];
  webContentLink?: string;
  fullFileExtension: string;
  webViewLink: string;
}

export interface IKBDriveUpdatedItem {
  id: string;
  name: string;
  kind: string;
  mimeType: string;
}

export interface IKBDriveGetFiles {
  rows: IKBDriveFile[];
  count: number;
  category: PredefinedCategories;
}

export interface IKBCategories {
  rows: IKBCategoryFolder[];
  count: number;
}

export interface ICreateKBItem {
  fileName: string;
  mimeType: MimeType;
  parentId: string;
  categoryName?: PredefinedCategories;
}

export interface IDeleteKBItem {
  categoryName?: PredefinedCategories;
  itemId: string;
}

export interface IUpdateKBItem {
  itemId: string;
  name: string;
  categoryName?: PredefinedCategories;
}
