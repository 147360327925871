import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IArchiveContactsRequest,
  IGetContactRequest,
  IGetContactsParams,
  ISalesContact,
  IUpdateContactOrder,
} from '../../interfaces/sales.interface';
import { axiosBaseQuery } from '../../utils/axios';
import { ISingleElement, PageDto } from '../../interfaces/common.interface';
import { addNotification } from '../app/reducer';
import { timelineApi } from '../timeline/api';
import { ITimelineEvent } from '../../interfaces/timeline.interface';
import { onQueryStartedExtended } from '../../utils/query';

export const salesContactApi = createApi({
  reducerPath: 'salesContactApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Contact'],
  endpoints: (builder) => ({
    createSalesContact: builder.mutation<
      { data: { id: string } },
      Partial<ISalesContact>
    >({
      query: (data) => {
        return {
          url: '/sales-contact',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Contact', id: 'LIST' }],
    }),

    archiveContacts: builder.mutation<
      { data: string[] },
      IArchiveContactsRequest
    >({
      query: (data) => {
        return {
          url: 'sales-contact/archive-contacts',
          method: 'PATCH',
          data: data.value,
        };
      },
      invalidatesTags: [{ type: 'Contact', id: 'LIST' }],
    }),

    unarchiveContacts: builder.mutation<
      { data: string[] },
      IArchiveContactsRequest
    >({
      query: (data) => {
        return {
          url: 'sales-contact/unarchive-contacts',
          method: 'PATCH',
          data: data.value,
        };
      },
      invalidatesTags: [{ type: 'Contact', id: 'LIST' }],
    }),

    getContacts: builder.query<PageDto<ISalesContact>, IGetContactsParams>({
      query: (params: IGetContactsParams) => ({
        url: '/sales-contact',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: { id: string }) => ({
                type: 'Contact' as const,
                id,
              })),
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    updateContact: builder.mutation<
      { data: ISalesContact; timeline: ITimelineEvent },
      Partial<ISalesContact>
    >({
      query: ({ id, ...rest }) => {
        return {
          url: `/sales-contact/${id}`,
          method: 'PATCH',
          data: rest,
        };
      },
      invalidatesTags: [{ type: 'Contact' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Contact updated successfully',
          errorMessage: 'Contact updating error',
        });

        if (result && typeof result !== 'boolean') {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              { sales_contact: result.data.id },
              (draft) => {
                draft.data.unshift(result.timeline);
                Object.assign(draft.meta, {
                  itemCount: draft.meta.itemCount + 1,
                });
              },
            ),
          );
        }
      },
    }),

    getContact: builder.query<
      ISingleElement<ISalesContact>,
      IGetContactRequest
    >({
      query: ({ id }) => ({
        url: `/sales-contact/${id}`,
      }),
      providesTags: (result) => [{ type: 'Contact', id: result?.data.id }],
    }),

    updateContactOrder: builder.mutation<
      { data: { message: string } },
      IUpdateContactOrder
    >({
      query: (data) => {
        return {
          url: `/sales-contact/order`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Contact', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Sales contact updated successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Sales contact updating error',
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useCreateSalesContactMutation,
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useArchiveContactsMutation,
  useUnarchiveContactsMutation,
  useUpdateContactMutation,
  useGetContactQuery,
  useUpdateContactOrderMutation,
} = salesContactApi;
