export interface PermissionItem {
  update: boolean;
  delete: boolean;
  create: boolean;
  read: boolean;
}

export interface ExtendedPermissionItem extends PermissionItem {
  area: PERMISSION_AREA;
}

export interface GetPermissionsRequestParams {
  roleId?: string;
  page?: boolean;
  area?: string[];
  singleArea?: PERMISSION_AREA;
}

export type GetPermissionsResponse = Record<PERMISSION_AREA, PermissionItem>;

export interface IUpdatePermissionsRequest {
  id: string;
  value: {
    permissions?: ExtendedPermissionItem[];
    userIds?: string[];
  };
}

export enum PERMISSION_AREA {
  DashboardPage = 'DASHBOARD_PAGE',
  DashboardComment = 'DASHBOARD_COMMENT',
  DashboardPost = 'DASHBOARD_POST',
  DashboardVote = 'DASHBOARD_VOTE',
  DashboardCategory = 'DASHBOARD_CATEGORY',
  CalendarPage = 'CALENDAR_PAGE',
  CalendarEvent = 'CALENDAR_EVENT',
  CalendarVacation = 'CALENDAR_VACATION',
  KnowledgeBasePage = 'KNOWLEDGE_BASE_PAGE',
  KnowledgeBaseFrontend = 'KNOWLEDGE_BASE_FRONTEND',
  KnowledgeBaseBackend = 'KNOWLEDGE_BASE_BACKEND',
  KnowledgeBaseQA = 'KNOWLEDGE_BASE_QA',
  KnowledgeBaseManagement = 'KNOWLEDGE_BASE_MANAGEMENT',
  KnowledgeBaseHR = 'KNOWLEDGE_BASE_HR',
  KnowledgeBaseDevOps = 'KNOWLEDGE_BASE_DEV_OPS',
  KnowledgeBaseSales = 'KNOWLEDGE_BASE_SALES',
  KnowledgeBaseDesign = 'KNOWLEDGE_BASE_DESIGN',
  WishListPage = 'WISHLIST_PAGE',
  WishList = 'WISHLIST',
  WishListStatus = 'WISHLIST_STATUS',
  EmployeePage = 'EMPLOYEE_PAGE',
  Employee = 'EMPLOYEE',
  EmployeeTechnologies = 'EMPLOYEE_TECHNOLOGIES',
  EmployeePosition = 'EMPLOYEE_POSITION',
  ProjectSpacePage = 'PROJECT_SPACE_PAGE',
  ProjectSpaceProject = 'PROJECT_SPACE_PROJECT',
  AccountingPage = 'ACCOUNTING_PAGE',
  Accounting = 'ACCOUNTING',
  SalesPage = 'SALES_PAGE',
  SalesContactsSubpage = 'SALES_CONTACTS_SUBPAGE',
  SalesContact = 'SALES_CONTACT',
  SalesRosterSubpage = 'SALES_ROSTER_SUBPAGE',
  SalesRosterEmployee = 'SALES_ROSTER_EMPLOYEE',
  SalesRosterProject = 'SALES_ROSTER_PROJECT',
  SalesContactRecordSubpage = 'SALES_CONTACT_RECORD_SUBPAGE',
  SalesContactRecord = 'SALES_CONTACT_RECORD',
  RolePage = 'ROLE_PAGE',
  Role = 'ROLE',
  RolePermissions = 'ROLE_PERMISSIONS',
  FAQPage = 'FAQ_PAGE',
  FAQ = 'FAQ',
  EquipmentPage = 'EQUIPMENT_PAGE',
  EquipmentItem = 'EQUIPMENT_ITEM',
}

export enum PERMISSION_AREA_DISPLAY {
  DASHBOARD_PAGE = 'Dashboard',
  DASHBOARD_COMMENT = 'Dashboard Comment',
  DASHBOARD_POST = 'Dashboard Post',
  DASHBOARD_VOTE = 'Dashboard Vote',
  DASHBOARD_CATEGORY = 'Dashboard Category',
  CALENDAR_PAGE = 'Calendar',
  CALENDAR_EVENT = 'Calendar Event',
  CALENDAR_VACATION = 'Calendar Vacation',
  KNOWLEDGE_BASE_PAGE = 'Knowledge Base',
  KNOWLEDGE_BASE_FRONTEND = 'Frontend',
  KNOWLEDGE_BASE_BACKEND = 'Backend',
  KNOWLEDGE_BASE_QA = 'QA',
  KNOWLEDGE_BASE_MANAGEMENT = 'Management',
  KNOWLEDGE_BASE_HR = 'HR',
  KNOWLEDGE_BASE_DEV_OPS = 'DevOps',
  KNOWLEDGE_BASE_SALES = 'Sales',
  KNOWLEDGE_BASE_DESIGN = 'Design',
  WISHLIST_PAGE = 'Wishlist',
  WISHLIST = 'Wishlist',
  WISHLIST_STATUS = 'Wish Status',
  EMPLOYEE_PAGE = 'Employees',
  EMPLOYEE = 'Employee',
  EMPLOYEE_TECHNOLOGIES = 'Employee Technologies',
  EMPLOYEE_POSITION = 'Employee Position',
  PROJECT_SPACE_PAGE = 'Project Space',
  PROJECT_SPACE_PROJECT = 'Project Space - Project',
  ACCOUNTING_PAGE = 'Accounting Page',
  ACCOUNTING = 'Accounting',
  SALES_PAGE = 'Sales',
  SALES_CONTACTS_SUBPAGE = 'Sales Contacts Page',
  SALES_CONTACT = 'Sales - Contact',
  SALES_ROSTER_SUBPAGE = 'Sales Roster Page',
  SALES_ROSTER_EMPLOYEE = 'Sales Roster - Employee',
  SALES_ROSTER_PROJECT = 'Sales Roster - Project',
  SALES_CONTACT_RECORD_SUBPAGE = 'Sales Contact Record Page',
  SALES_CONTACT_RECORD = 'Sales Contact Record',
  ROLE_PAGE = 'Display Roles',
  ROLE = 'Role',
  ROLE_PERMISSIONS = 'Role Permissions',
  FAQ_PAGE = 'FAQ',
  FAQ = 'FAQ',
  EQUIPMENT_PAGE = 'Equipment',
}

export const permissionUIStructure = [
  {
    page: PERMISSION_AREA.DashboardPage,
    permissions: [
      PERMISSION_AREA.DashboardComment,
      PERMISSION_AREA.DashboardPost,
      PERMISSION_AREA.DashboardVote,
      PERMISSION_AREA.DashboardCategory,
    ],
  },
  {
    page: PERMISSION_AREA.CalendarPage,
    permissions: [
      PERMISSION_AREA.CalendarEvent,
      PERMISSION_AREA.CalendarVacation,
    ],
  },
  {
    page: PERMISSION_AREA.KnowledgeBasePage,
    permissions: [
      PERMISSION_AREA.KnowledgeBaseFrontend,
      PERMISSION_AREA.KnowledgeBaseBackend,
      PERMISSION_AREA.KnowledgeBaseQA,
      PERMISSION_AREA.KnowledgeBaseManagement,
      PERMISSION_AREA.KnowledgeBaseHR,
      PERMISSION_AREA.KnowledgeBaseDevOps,
      PERMISSION_AREA.KnowledgeBaseSales,
      PERMISSION_AREA.KnowledgeBaseDesign,
    ],
  },
  {
    page: PERMISSION_AREA.WishListPage,
    permissions: [PERMISSION_AREA.WishList, PERMISSION_AREA.WishListStatus],
  },
  {
    page: PERMISSION_AREA.FAQPage,
    permissions: [PERMISSION_AREA.FAQ],
  },
  {
    page: PERMISSION_AREA.EmployeePage,
    permissions: [
      PERMISSION_AREA.Employee,
      PERMISSION_AREA.EmployeeTechnologies,
      PERMISSION_AREA.EmployeePosition,
    ],
  },
  {
    page: PERMISSION_AREA.ProjectSpacePage,
    permissions: [PERMISSION_AREA.ProjectSpaceProject],
  },
  {
    page: PERMISSION_AREA.AccountingPage,
    permissions: [PERMISSION_AREA.Accounting],
  },
  {
    page: PERMISSION_AREA.SalesPage,
    permissions: [
      PERMISSION_AREA.SalesContactsSubpage,
      PERMISSION_AREA.SalesContact,
      PERMISSION_AREA.SalesRosterSubpage,
      PERMISSION_AREA.SalesRosterEmployee,
      PERMISSION_AREA.SalesRosterProject,
      PERMISSION_AREA.SalesContactRecordSubpage,
      PERMISSION_AREA.SalesContactRecord,
    ],
  },
  {
    page: PERMISSION_AREA.RolePage,
    permissions: [PERMISSION_AREA.Role, PERMISSION_AREA.RolePermissions],
  },
  {
    page: PERMISSION_AREA.EquipmentPage,
    permissions: [PERMISSION_AREA.EquipmentPage],
  },
];

export type PermissionAreaName = keyof typeof PERMISSION_AREA_DISPLAY;
