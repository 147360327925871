import { SvgIconType } from './svg.interface';

const Svg: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#1DA1F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 5.09645C10.9743 5.21894 10.6775 5.30144 10.3663 5.33868C10.684 5.1482 10.928 4.84672 11.0428 4.48749C10.7458 4.66398 10.4161 4.79172 10.0658 4.86097C9.7851 4.56199 9.38487 4.375 8.94265 4.375C8.09296 4.375 7.40401 5.06395 7.40401 5.91365C7.40401 6.03414 7.41776 6.15163 7.444 6.26437C6.16509 6.20038 5.03141 5.58767 4.27247 4.65673C4.13997 4.88397 4.06423 5.1482 4.06423 5.43018C4.06423 5.9639 4.33546 6.43486 4.74868 6.71085C4.4962 6.70285 4.25922 6.6336 4.05148 6.51836V6.53761C4.05148 7.28331 4.58219 7.90501 5.28565 8.04675C5.15666 8.08175 5.02067 8.10075 4.88042 8.10075C4.78118 8.10075 4.68469 8.091 4.59069 8.073C4.78643 8.68421 5.35489 9.12918 6.0281 9.14168C5.50163 9.55415 4.83818 9.80039 4.11723 9.80039C3.99273 9.80039 3.87024 9.79314 3.75 9.77864C4.43095 10.2151 5.23965 10.4701 6.10859 10.4701C8.93865 10.4701 10.4863 8.1255 10.4863 6.09238C10.4863 6.02564 10.4851 5.95914 10.4818 5.8934C10.7828 5.67591 11.0438 5.40493 11.25 5.09645Z"
      fill="white"
    />
  </svg>
);

export default Svg;
