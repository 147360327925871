import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetQuery } from '../../interfaces/common.interface';
import {
  CreateRosterEmployeeDto,
  IRosterProjectEmployee,
  UpdateRosterEmployeeDto,
  IDeleteRosterEmployee,
} from '../../interfaces/roster-project.interface';
import { axiosBaseQuery } from '../../utils/axios';
import { addNotification } from '../app/reducer';
import { timelineApi } from '../timeline/api';
import { ITimelineEvent } from '../../interfaces/timeline.interface';
import { onQueryStartedExtended } from '../../utils/query';

export const salesRosterApi = createApi({
  reducerPath: 'salesRosterApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Roster'],
  endpoints: (builder) => ({
    createEmployee: builder.mutation<
      IRosterProjectEmployee,
      CreateRosterEmployeeDto
    >({
      query: (data) => {
        return {
          url: '/roster-employee',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [{ type: 'Roster', id: 'LIST' }],
    }),

    getEmployees: builder.query<IRosterProjectEmployee[], IGetQuery>({
      query: (params: IGetQuery) => ({
        url: '/roster-employee',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: { id: string }) => ({
                type: 'Roster' as const,
                id,
              })),
              { type: 'Roster', id: 'LIST' },
            ]
          : [{ type: 'Roster', id: 'LIST' }],
      keepUnusedDataFor: 0,
      transformResponse: (rawResult: { data: IRosterProjectEmployee[] }) => {
        return rawResult.data;
      },
    }),

    updateRosterEmployee: builder.mutation<
      { data: IRosterProjectEmployee; timeline: ITimelineEvent[] },
      Partial<UpdateRosterEmployeeDto>
    >({
      query: ({ id, ...rest }) => {
        return {
          url: `/roster-employee/${id}`,
          method: 'PATCH',
          data: rest,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Roster', id: arg.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await onQueryStartedExtended(queryFulfilled, dispatch, {
          successMessage: 'Employee updated successfully',
        });

        if (result && typeof result !== 'boolean') {
          dispatch(
            timelineApi.util.updateQueryData(
              'getTimelineEvents',
              { roster_employee: result.data.id },
              (draft) => {
                draft.data.unshift(...result.timeline);
                Object.assign(draft.meta, {
                  itemCount: draft.meta.itemCount + result.timeline.length,
                });
              },
            ),
          );
        }
      },
    }),

    deleteEmployee: builder.mutation<
      { message: string },
      IDeleteRosterEmployee
    >({
      query: (data) => {
        return {
          url: `/roster-employee`,
          method: 'DELETE',
          data,
        };
      },
      invalidatesTags: (_, __, data) => [{ type: 'Roster', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Employee removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Employee removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getEmployee: builder.query<IRosterProjectEmployee, { id: string }>({
      query: ({ id }) => ({
        url: `/roster-employee/${id}`,
      }),
      providesTags: (result) => [{ type: 'Roster', id: result?.id }],
      transformResponse: (rawResult: { data: IRosterProjectEmployee }) => {
        return rawResult.data;
      },
    }),
  }),
});

export const {
  useCreateEmployeeMutation,
  useGetEmployeesQuery,
  useUpdateRosterEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeeQuery,
} = salesRosterApi;
