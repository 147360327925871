import { SvgIconType } from './svg.interface';

const InfoIcon: SvgIconType = ({ width = 15, height = 15, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="7.5" cy="7.5" r="5.625" stroke="#137BC6" />
    <path
      d="M7.8125 4.6875C7.8125 4.86009 7.67259 5 7.5 5C7.32741 5 7.1875 4.86009 7.1875 4.6875C7.1875 4.51491 7.32741 4.375 7.5 4.375C7.67259 4.375 7.8125 4.51491 7.8125 4.6875Z"
      fill="#137BC6"
      stroke="#137BC6"
      strokeWidth="0.5"
    />
    <path d="M7.5 10.625V6.25" stroke="#137BC6" />
  </svg>
);

export default InfoIcon;
